import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import './ActivationsInsights.scss';
const ActivationsInsights = ({ yearData, type, eventYearData = [] }) => {
  const [barData, setBarData] = useState([]);
  const [topMonth, setTopMonth] = useState('');

  let ticks = [];
  let data = [];
  data = [...(yearData || []), ...(eventYearData || [])];
  useEffect(() => {
    const getData = async () => {
      try {
        if (yearData.length > 0) {
          const groupedData = data.reduce((acc, item) => {
            const date = parseISO(item.tenderedDate);
            const month = format(date, 'MMM'); // Short month name (e.g., Jan, Feb)
            const year = format(date, 'yyyy'); // Full year

            const key = `${month} ${year}`;

            if (!acc[key]) {
              acc[key] = {
                month,
                Postpaid: 0,
                Prepaid: 0,
                Hup: 0,
              };
            }

            // Increment counts based on conditions
            if (item.simNumber !== 'N/A' && item.connectionType === 'Postpaid') {
              acc[key].Postpaid++;
            }
            if (item.connectionType === 'Prepaid') {
              acc[key].Prepaid++;
            }
            if (item.simNumber === 'N/A' && item.imei !== 'N/A') {
              acc[key].Hup++;
            }

            return acc;
          }, {});

          // Convert the grouped data object to an array
          const result = Object.values(groupedData);

          setBarData(result);
          const topMonth = result.reduce((top, current) => {
            const currentTotal = current.Postpaid + current.Prepaid + current.Hup;
            const topTotal = top.Postpaid + top.Prepaid + top.Hup;

            return currentTotal > topTotal ? current : top;
          });
          setTopMonth(topMonth);
        }
      } catch (error) {
        return error;
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearData, type, eventYearData]);

  const formatLegendValue = (value) => {
    return value.replace('_', ' ');
  };

  const formatTooltipValue = (value, name) => {
    return `${value.replace('_', ' ')}: ${name}`;
  };

  const CustomTooltipContent = ({ payload }) => {
    if (!payload || !payload.length) return null;

    return (
      <div className="custom-recharts-tooltip">
        <p className="recharts-tooltip-label">{payload[0].payload?.month}</p>
        <ul className="recharts-tooltip-item-list">
          {payload?.map((payloadItem, index) => {
            return <li key={index}>{formatTooltipValue(payloadItem.name, payloadItem.value)}</li>;
          })}
          <li></li>
        </ul>
      </div>
    );
  };

  return (
    <div className="block">
      <div className="block-head">
        <div className="block-title">
          <span>Activations Insights</span>
        </div>
      </div>
      <div className="line-chart">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={barData}
            margin={{
              top: 10,
              right: 5,
              left: -20,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="0 3" />
            <XAxis
              dataKey="month"
              tickSize={4}
              axisLine={true}
              //padding={{ left: 20 }}
              tick={({ payload, x, y, dy }) => (
                <text x={x} y={y + 20} dy={dy} textAnchor="middle" fill="#7B91B0" fontSize={13}>
                  {payload.value}
                </text>
              )}
            />
            <YAxis
              tickSize={4}
              axisLine={true}
              ticks={ticks}
              tick={{
                fill: '#7B91B0',
                fontSize: 13,
              }}
            />
            <Tooltip content={<CustomTooltipContent />} />
            <Legend iconType="square" formatter={formatLegendValue} />
            <ReferenceLine
              isFront={true}
              x={topMonth.month}
              stroke="#F64E60"
              strokeDasharray="3 3"
            ></ReferenceLine>
            <Line
              type="monotone"
              dataKey="Postpaid"
              stroke="#A700FF"
              activeDot={{ r: 6 }}
              strokeWidth={2}
            />
            <Line type="monotone" dataKey="Prepaid" stroke="#F64E60" strokeWidth={2} />
            <Line type="monotone" dataKey="Hup" stroke="#3CD856" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ActivationsInsights;

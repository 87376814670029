import React, { useState, useEffect, useRef } from 'react'
import './AdminDashboard.scss'
import { deleteCisDataRow } from '../../helper/helper';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Commissions from '../Commissions';
import StoreCommission from '../StoreCommission';
import DateFilter from '../DateFilter';
import Modal from '../Modal/Modal';
import ReactLoading from 'react-loading';
import { getAllUsersData, getUser } from '../../helper/helper';
import ActivationsInsights from '../Charts/ActivationsInsights/ActivationsInsights'
import Revenues from '../Charts/Revenues/Revenues';
import PieCharts from '../Charts/PieCharts/PieCharts';
import Barcharts from '../Charts/Barcharts/Barcharts';
import { getFilterdByDate, getEventCisData } from '../../helper/helper'
import { format, endOfMonth, differenceInDays, startOfMonth, parseISO } from 'date-fns';
import { Button } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';


const AdminDashboard = () => {
  const username = localStorage.getItem('username');
  const [cisdata, setCisdata] = useState([]);
  const [arrIds, setArrIds] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [target, setTarget] = useState([]);
  const [targetRow, setTargetRow] = useState([])
  const [adjustedtarget, setAdjustedtarget] = useState([])
  const [storeCommission, setStoreCommission] = useState([]);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [employeeCommissions, setEmployeeCommissions] = useState([]);
  const [typeSelected, setTypeSelected] = useState('')
  const [currentMandY, setCurrentMandY] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accMSRPDataAA, setAccMSRPDataAA] = useState([]);
  const [accMSRPDataOA, setAccMSRPvDataOA] = useState([]);
  const [totalAccMSRP, setTotalAccMSRP] = useState(null);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [userdata, setUserdata] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const [accListAR, setAccListAR] = useState([])
  const [accListOA, setAccListOA] = useState([])
  const [yearData, setYearData] = useState([])
  const [eventCisData, setEventCisData] = useState([]);
  const [eventYearData, setEventYearData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [incomingDate, setIncomingDate] = useState({})
  const abortControllerRef = useRef(null);
  useEffect(() => {
    async function fetchUser() {
      let { data } = await getUser({ username: username });
      setUserDetail(data)
    }
    fetchUser();
  }, [username])

  useEffect(() => {
    try {
      let { data } = getAllUsersData();
      if (userDetail?.role === 'manager') {
        let finalArray = []
        userDetail?.stores?.split(',').forEach(store => {
          data.forEach(user => {
            let exist = user.stores.split(',').some(item => item === store)
            if (exist && user.role !== 'manager') finalArray.push(user)
          })
        })
        setUserdata(finalArray);
      } else if (userDetail?.role === 'admin') {
        setUserdata(data);
      }

    } catch (error) {
      return error
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const fetchYearData = async (year, abortController) => {
    setYearData([]);
    setEventYearData([]);
    setLoadingProgress(true);

    const currentDate = new Date();
    let currentMonth = currentDate.getMonth(); // Get current month (0-11)
    const currentYear = currentDate.getFullYear();
    const totalMonths = 12;
    let monthsToFetch = [];

    // Determine months to fetch based on the current month and selected year
    if (year === currentYear) {
      if (currentMonth === 0) {
        // January: Oct, Nov, Dec from last year + Jan of current year
        monthsToFetch = [
          { year: currentYear - 1, month: 9 }, // Oct
          { year: currentYear - 1, month: 10 }, // Nov
          { year: currentYear - 1, month: 11 }, // Dec
          { year: currentYear, month: 0 }, // Jan
        ];
      } else if (currentMonth === 1) {
        // February: Nov, Dec from last year + Jan, Feb of current year
        monthsToFetch = [
          { year: currentYear - 1, month: 10 }, // Nov
          { year: currentYear - 1, month: 11 }, // Dec
          { year: currentYear, month: 0 }, // Jan
          { year: currentYear, month: 1 }, // Feb
        ];
      } else if (currentMonth === 2) {
        // March: Dec from last year + Jan, Feb, Mar of current year
        monthsToFetch = [
          { year: currentYear - 1, month: 11 }, // Dec
          { year: currentYear, month: 0 }, // Jan
          { year: currentYear, month: 1 }, // Feb
          { year: currentYear, month: 2 }, // Mar
        ];
      } else {
        // For April and beyond, start from January of the current year
        for (let i = 0; i <= currentMonth; i++) {
          monthsToFetch.push({ year: currentYear, month: i });
        }
      }
    } else {
      // For selected years other than the current year, fetch all months
      for (let i = 0; i < totalMonths; i++) {
        monthsToFetch.push({ year, month: i });
      }
    }

    const { month: startingMonth, year: startingYear } = monthsToFetch[0];
    const { month: endingMonth, year: endingYear } = monthsToFetch[monthsToFetch.length - 1];

    const startDate = format(new Date(startingYear, startingMonth, 1), 'yyyy-MM-dd');
    const endDate = format(endOfMonth(new Date(endingYear, endingMonth, 1)), 'yyyy-MM-dd');

    let isDataFetched = false;

    try {
      // Check if the request is aborted
      if (abortController.signal.aborted) return;

      // Fetch Filtered Data
      const { data: filterData } = await getFilterdByDate({ startDate, endDate });
      if (abortController.signal.aborted) return;

      if (filterData && filterData.length > 0) {
        setYearData((prevData) => [...prevData, ...filterData]);
        isDataFetched = true;
      }

      // Fetch Event Data
      const { data: eventData } = await getEventCisData({ startDate, endDate });
      if (abortController.signal.aborted) return;

      if (eventData && eventData.length > 0) {
        setEventYearData((prevData) => [...prevData, ...eventData]);
        isDataFetched = true;
      }

      if (isDataFetched) {
        setLoadingProgress(false);
      } else {
        console.warn(`No data received for ${startDate} - ${endDate}.`);
      }
    } catch (error) {
      if (!abortController.signal.aborted) {
        console.error(`Error fetching data for ${startDate} - ${endDate}:`, error);
        console.error(`No data received for ${startDate} - ${endDate}. Retrying...`);
      }
    }
  };

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Cancel previous fetch if year changes
    }

    abortControllerRef.current = new AbortController();
    fetchYearData(selectedYear, abortControllerRef.current);
    return () => abortControllerRef.current.abort(); // Cleanup on unmount or re-run
  }, [selectedYear]);


  const storeNameList = {
    OSHWTIN: "Lake Vista Plaza",
    LAKESIN: "Lakeshore & Islington",
    VYAD2IN: "Center Mall Barton",
    TCPNFIN: "Town Center Plaza",
    VYACBIN: "Cedarbrae Mall",
    BARRIIN: "Barrie",
    VYAREIN: "Rexdale & Islington",
    DTWICIN: "Briarfield Shopping Centre",
    DAN01IN: "Danforth & Woodbine",
    VYOSHIN: "Big Bay",
    VYAMLIN: "Markham & Lawrence",
    VYAWLIN: "Weston & Lawrence",
    DUNBLIN: "Oriental Centre",
    AllStores: 'All Stores'
  }

  function findKeyByValue(obj, valueToFind) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === valueToFind) {
        return key;
      }
    }
    return null;
  }

  const setLoadingCondition = (condition) => {
    setIsLoading(condition);
  }

  const keysToSum = [
    "accessories",
    "addon",
    "applecare",
    "hardwareupgrade",
    "postpaidtarget",
    "prepaidtarget",
    "smssurvey",
  ];

  const DateForTarget = (target) => {

    setTarget(target)

    const result = target.reduce((acc, item) => {
      keysToSum.forEach((key) => {
        acc[key] = (acc[key] || 0) + parseInt(item[key], 10);
      });
      return acc;
    }, {});

    setTargetRow(result)
    setAdjustedtarget(result)
  }

  const accessoryListAR = (data) => {
    if (data.length > 0) {
      setAccListAR(data);
    }
  }

  const accessoryListOA = (data) => {
    if (data.length > 0) {
      setAccListOA(data);
    }
  }

  const AAMSRP = (data) => {
    setAccMSRPDataAA(data)
    let totalMsrp = 0;
    for (const key in data[1]) {
      if (data[1].hasOwnProperty(key)) {
        totalMsrp += data[1][key].msrp;
      }
    }
    setValue1(totalMsrp)
  }

  const OAMSRP = (data) => {
    setAccMSRPvDataOA(data)
    let totalMsrpOA = 0;
    for (const key in data[1]) {
      if (data[1].hasOwnProperty(key)) {
        totalMsrpOA += data[1][key].OAMsrp;
      }
    }
    setValue2(totalMsrpOA)
  }

  const updateTableData = (data) => {
    setCisdata(data);
    setEmployeeData(data);
    setStoreCommission(data);
    setEmployeeCommissions(data);
  };

  const onEventDataLoaded = (data) => {
    setEventCisData(data);
  };

  const currentMonthYear = (data) => {
    setCurrentMandY(data);
  }

  const cisAndTargetData = (data) => {

    setEmployeeData(data[0]);
    setStoreCommission(data[0]);
    setEmployeeCommissions(data[0]);

    const result = data[1].reduce((acc, item) => {
      keysToSum.forEach((key) => {
        acc[key] = (acc[key] || 0) + parseInt(item[key], 10);
      });
      return acc;
    }, {});

    setTargetRow(result)
    setAdjustedtarget(result)

  };

  const currentFilteritmes = (data) => {

    setCurrentFilter(data);
    const emailPrefix = data[0] + '@vyadom.com'
    if (data[0] && data[1] === undefined) {

      setTotalAccMSRP((accMSRPDataAA[1][emailPrefix]?.msrp || 0) + (accMSRPDataOA[0][emailPrefix]?.OAMsrp || 0))
    } else if (data[1] && data[0] === '') {

      setTotalAccMSRP((accMSRPDataAA[0][findKeyByValue(storeNameList, data[1])]?.msrp || 0) + (accMSRPDataOA[1][findKeyByValue(storeNameList, data[1])]?.OAMsrp || 0))
    }
    else if (data[1] && data[0]) {
      setTotalAccMSRP((accMSRPDataAA[2][emailPrefix][findKeyByValue(storeNameList, data[1])]?.msrp || 0) + (accMSRPDataOA[2][findKeyByValue(storeNameList, data[1])][emailPrefix]?.OAMsrp || 0))
    }
  }

  let filterStores = []
  let storesList = cisdata?.map(store => store.storeInvoice);
  storesList?.forEach(val => {

    let isExist = filterStores.some(store => store === val);
    if (!isExist) filterStores.push(val);
  })
  const sortedStores = filterStores.sort((a, b) => a.localeCompare(b));

  let filterEmployee = []
  let employeeList = cisdata?.map(emp => emp.user);
  employeeList?.forEach(val => {
    let isExist = filterEmployee.some(employee => employee === val);
    if (!isExist) filterEmployee.push(val);
  })

  const sortedEmployees = filterEmployee?.sort((a, b) => a.localeCompare(b));

  function sumValues(obj1, obj3) {
    let totalMsrp = 0;
    let totalOAMsrp = 0;
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        totalMsrp += obj1[key].msrp;
      }
    }
    for (const key in obj3) {
      if (obj3.hasOwnProperty(key)) {
        totalOAMsrp += obj3[key].OAMsrp;
      }
    }
    return { totalMsrp, totalOAMsrp, grandTotal: totalMsrp + totalOAMsrp };
  }

  const handleTypeChange = (event) => {

    const { value } = event.target;
    setEmployeeCommissions('');
    setStoreCommission('');
    if (value !== 'Select Type' && value !== 'All') {
      setTypeSelected(value)
      openModal();
    } else {
      setCurrentFilter('');
      setStoreCommission(cisdata);
      setEmployeeCommissions(cisdata)
      setEmployeeData(cisdata);
      setTypeSelected(value)

      const result = target.reduce((acc, item) => {
        keysToSum.forEach((key) => {
          acc[key] = (acc[key] || 0) + parseInt(item[key], 10);
        });
        return acc;
      }, {});
      setTargetRow(result)
      setAdjustedtarget(result)

      const sums = sumValues(accMSRPDataAA[1], accMSRPDataOA[1]);
      setTotalAccMSRP(sums.grandTotal)
    }
  };

  const datesSelected = (data) => {
    setIncomingDate(data)
    const selectedStartDate = parseISO(data?.startDate);
    const selectedEndDate = parseISO(data?.endDate);
    const totalDaysInMonth = differenceInDays(
      endOfMonth(selectedStartDate),
      startOfMonth(selectedStartDate)
    ) + 1;

    const selectedDays =
      differenceInDays(selectedEndDate, selectedStartDate) + 1;
    if (totalDaysInMonth !== selectedDays) {
      const resultForSelectedDays = {};
      Object.keys(targetRow).forEach((key) => {
        const valueForSelectedDays = (targetRow[key] / totalDaysInMonth) * selectedDays;
        resultForSelectedDays[key] = Math.round(valueForSelectedDays);
      });

      setAdjustedtarget(resultForSelectedDays);
    }
  }

  const type = ['Select Type', 'Stores', 'Reps', 'All']

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setTypeSelected('')
  };

  let data = { postCount: 0, preCount: 0, hupCount: 0, autopayCount: 0, withDeviceCount: 0, pppCount: 0, addonsCount: 0 }
  let dataEvent = { postCount: 0, preCount: 0, hupCount: 0, autopayCount: 0, withDeviceCount: 0, pppCount: 0, addonsCount: 0 }

  employeeData?.map((item) => {
    if (item.addons.includes("Phone Protection Plan")) {
      data.pppCount++;
    }
    if (item.simNumber !== "N/A" && item.connectionType === "Postpaid") {
      data.postCount++;
    }
    if (item.connectionType === "Prepaid") {
      data.preCount++;
    }
    if (item.simNumber === "N/A" && item.imei !== "N/A") {
      data.hupCount++;
    }
    if (item.autoPayPre === "YES" || item.autoPayPost === "YES" || item.autoPayPre === "successfully" || item.autoPayPre === "successfully for Pre - Authorized Payments" || item.autoPayPost === "successfully for Pre - Authorized Payments") {
      data.autopayCount++;
    }
    if (item.simNumber !== "N/A" && item.imei !== "N/A") {
      data.withDeviceCount++;
    }
    if (item.addons !== "" && item.addons !== "N/A") {
      data.addonsCount++;
    }
    return (data.pppCount, data.postCount, data.preCount, data.hupCount, data.autopayCount, data.withDeviceCount, data.addonsCount);
  })

  eventCisData?.map((item) => {
    if (item.addons !== "") {
      dataEvent.pppCount++;
    }
    if (item.simNumber !== "N/A" && item.connectionType === "Postpaid") {
      dataEvent.postCount++;
    }
    if (item.connectionType === "Prepaid") {
      dataEvent.preCount++;
    }
    if (item.simNumber === "N/A" && item.imei !== "N/A") {
      dataEvent.hupCount++;
    }
    if (item.autoPayPre === "YES" || item.autoPayPost === "YES" || item.autoPayPre === "successfully" || item.autoPayPre === "successfully for Pre - Authorized Payments" || item.autoPayPost === "successfully for Pre - Authorized Payments") {
      dataEvent.autopayCount++;
    }
    if (item.simNumber !== "N/A" && item.imei !== "N/A") {
      dataEvent.withDeviceCount++;
    }
    if (item.addons !== "") {
      dataEvent.addonsCount++;
    }
    return (dataEvent.pppCount, dataEvent.postCount, dataEvent.preCount, dataEvent.hupCount, dataEvent.autopayCount, dataEvent.withDeviceCount, dataEvent.addonsCount);
  })

  function getNumberOfDaysInMonth(monthName, year) {
    // Create a Date object with the first day of the month
    const firstDayOfMonth = new Date(year, new Date(Date.parse(monthName + " 1, " + year)).getMonth(), 1);

    // Move to the next month and then back one day to get the last day of the current month
    const lastDayOfMonth = new Date(year, firstDayOfMonth.getMonth() + 1, 0);

    // Return the day of the month, which represents the number of days in the month
    return lastDayOfMonth.getDate();
  }

  function mtdCalculator(target1, achieved) {
    let totatMTD = 0
    const monthName = currentMandY[0];
    const year = currentMandY[1];
    const dateObject = new Date();
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const monthFromCurrentDate = monthNames[dateObject.getMonth()];

    if (target1 !== 0 && achieved !== 0) {
      if (monthName === monthFromCurrentDate) {

        const start = new Date(incomingDate.startDate);
        const end = new Date(incomingDate.endDate);
        const year = start.getFullYear();
        const month = start.getMonth() + 1; // getMonth() is 0-based, so add 1
        const maxDaysInMonth = new Date(year, month + 1, 0).getDate();
        const differenceInMilliseconds = end - start;
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

        if (differenceInDays + 1 !== maxDaysInMonth) {
          const numberOfDays = getNumberOfDaysInMonth(monthName, year);
          const mtdTarget = (target1 / numberOfDays) * numberOfDays;
          totatMTD = parseInt((achieved / mtdTarget * 100).toFixed(2));
          return totatMTD;

        } else {
          let currentDate = new Date();
          const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
          const remainingDaysCount = lastDayOfMonth.getDate() - currentDate.getDate() + 1;
          let daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
          const pastDaysCount = daysInMonth - remainingDaysCount + 1;
          const mtdTarget = (target1 / daysInMonth) * pastDaysCount;
          totatMTD = parseInt((achieved / mtdTarget * 100).toFixed(2));
          return totatMTD;
        }

      } else if (monthName !== monthFromCurrentDate) {
        const numberOfDays = getNumberOfDaysInMonth(monthName, year);
        const mtdTarget = (target1 / numberOfDays) * numberOfDays;
        totatMTD = parseInt((achieved / mtdTarget * 100).toFixed(2));
        return totatMTD;
      }
    }
    return totatMTD;
  }

  async function deleteCisData(id) {

    let saleInvoice = null;
    if (window.confirm(`Are you sure you want to delete ${id}'s data?`)) {

      id.forEach(id => {
        cisdata.forEach(cis => {
          if (cis._id === id) {
            saleInvoice = cis.saleInvoice
          }
        })

        let data = deleteCisDataRow({ id, saleInvoice });
        if (data) {
          alert("data deleted successfully");
        }

      })
    } else {
      console.log("not done")
    }
  }

  const columns = [
    {
      field: 'saleInvoice', headerName: 'Sales Invoice',
      width: 130, hideable: false
    },
    {
      field: 'storeId', headerName: 'Store ID',
      hideable: false
    },
    { field: 'specialistId', headerName: 'Specialist ID', hideable: false },
    { field: 'user', headerName: 'User', width: 180, hideable: false },
    { field: 'tenderedDate', headerName: 'Tendered Date', hideable: false },
    { field: 'customerName', headerName: 'Customer Name', width: 200, hideable: false },
    { field: 'email', headerName: 'Email', width: 250 },
    {
      field: 'activationDate', headerName: 'Activation Date', headerAlign: 'center',
      hideable: false
    },
    { field: 'accountNum', headerName: 'Account Number', hideable: false, width: 150 },
    { field: 'simNumber', headerName: 'SIM Number', hideable: false, width: 200 },
    { field: 'imei', headerName: 'IMEI', hideable: false, width: 200 },
    { field: 'phoneProtectionPlan', headerName: 'PPP' },
    { field: 'activatedPromotion', headerName: 'Promo', hideable: false },
    { field: 'phoneNum', headerName: 'Phone Number', hideable: false, width: 150 },
    { field: 'tempPhoneNum', headerName: 'Temporary Number', width: 150 },
    { field: 'deposit', headerName: 'Deposit' },
    { field: 'retailPrice', headerName: 'Retail Price' },
    { field: 'amountYouPaid', headerName: 'Amount Paid' },
    { field: 'myBalanceOwing', headerName: 'Balance Owing' },
    { field: 'oneTimeCharges', headerName: 'One Time Charge' },
    { field: 'connectionType', headerName: 'Connection Type' },
    { field: 'phoneMake', headerName: 'Phone Make' },
    { field: 'planName', headerName: 'Plan Name' },
    { field: 'planPrice', headerName: 'Plan Price' },
    { field: 'addons', headerName: 'Add-Ons', width: 150 },
    { field: 'autoPayPre', headerName: 'Auto Pay Pre' },
    { field: 'autoPayPost', headerName: 'Auto Pay Post' }

  ]

  const rows = employeeData?.map((row) => ({
    _id: row._id,
    saleInvoice: row.saleInvoice,
    tenderedDate: row.tenderedDate,
    customerName: row.customerName,
    email: row.email,
    accountNum: row.accountNum,
    deposit: row.deposit,
    retailPrice: row.retailPrice,
    phoneProtectionPlan: row.phoneProtectionPlan,
    activatedPromotion: row.activatedPromotion,
    phoneNum: row.phoneNum,
    tempPhoneNum: row.tempPhoneNum,
    simNumber: row.simNumber,
    imei: row.imei,
    amountYouPaid: row.amountYouPaid,
    connectionType: row.connectionType,
    activationDate: row.activationDate,
    phoneMake: row.phoneMake,
    planName: row.planName,
    planPrice: row.planPrice,
    myBalanceOwing: row.myBalanceOwing,
    oneTimeCharges: row.oneTimeCharges,
    addons: row.addons,
    autoPayPre: row.autoPayPre,
    autoPayPost: row.autoPayPost,
    specialistId: row.specialistId,
    storeId: row.storeId,
    user: row.user
  }))


  return (
    <div className='adminsdashboard'>
      <div className='filter-menu-list'>
        <div className='filter-menu'>
          <DateFilter datesSelected={datesSelected} onEventDataLoaded={onEventDataLoaded} accessoryListAR={accessoryListAR} accessoryListOA={accessoryListOA} AAMSRP={AAMSRP} OAMSRP={OAMSRP} onDataLoaded={updateTableData} targetData={DateForTarget} /* manualData={manualAcceandAppleCare} */ currentMonth={currentMonthYear} setLoadingCondition={setLoadingCondition} />
        </div>
        <div className='filter-menu'>
          <label htmlFor="storeslist">Search By:</label>
          <select name="storeslist" id="storeslist" onChange={handleTypeChange} value={typeSelected}>
            {/* <option value=''>Select Type</option> */}
            {type.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <Modal
            isOpen={modalOpen}
            onClose={closeModal}
            type={typeSelected}
            data={cisdata}
            filterStores={sortedStores}
            filterEmployees={sortedEmployees}
            target={target}
            onLoadedData={cisAndTargetData}
            currentFilteritmes={currentFilteritmes}
            userdata={userdata}
          />
          {currentFilter[1] && (
            <div className='labelFilter'>Store: {currentFilter[1]}</div>
          )}
          {currentFilter[0] && (
            <div className='labelFilter'>Rep: {currentFilter[0]}</div>
          )}


        </div>
      </div>

      <>
        <div className='widgetcontainer-top'>
          <div className='widgetcontainer-top-left'>
            <div className='widgetcontainer-top-left-div'>
              <div className='top-div-p'><p>Total(MTD)</p></div>
              <div className='top-div-span'><span style={{ color: mtdCalculator(adjustedtarget?.postpaidtarget + adjustedtarget?.prepaidtarget, data?.postCount + data.preCount + (currentFilter?.length === 2 ? 0 : (dataEvent.postCount + dataEvent.preCount))) < 100 ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{mtdCalculator(parseInt(adjustedtarget?.postpaidtarget) + parseInt(adjustedtarget?.prepaidtarget), data?.postCount + data.preCount + (currentFilter?.length === 2 ? 0 : (dataEvent.postCount + dataEvent.preCount))) || 0}%</span></div>
              <div className='top-div-bot'>
                <div className='target'>
                  <p>Target</p>
                  <span>{adjustedtarget?.postpaidtarget + adjustedtarget?.prepaidtarget || 0}</span>
                </div>
                <div className='target'>
                  <p>Achieved</p>
                  <span>{data.postCount + data.preCount + (currentFilter?.length === 2 ? 0 : (dataEvent.postCount + dataEvent.preCount))}</span>
                </div>
              </div>
            </div>
            <div className='widgetcontainer-top-left-div'>
              <div className='top-div-p'><p>Postpaid(MTD)</p></div>
              <div className='top-div-span'><span style={{ color: mtdCalculator(adjustedtarget?.postpaidtarget, data?.postCount + (currentFilter?.length === 2 ? 0 : dataEvent.postCount)) < 100 ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{mtdCalculator(adjustedtarget?.postpaidtarget, data?.postCount + (currentFilter?.length === 2 ? 0 : dataEvent.postCount)) || 0}%</span></div>
              <div className='top-div-bot'>
                <div className='target'>
                  <p>Target</p>
                  <span>{adjustedtarget?.postpaidtarget || 0}</span>
                </div>
                <div className='target'>
                  <p>Achieved</p>
                  <span>{data.postCount + (currentFilter?.length === 2 ? 0 : dataEvent.postCount)}</span>
                </div>
              </div>
            </div>
            <div className='widgetcontainer-top-left-div'>
              <div className='top-div-p'><p>Prepaid(MTD)</p></div>
              <div className='top-div-span'><span style={{ color: mtdCalculator(adjustedtarget?.prepaidtarget, data.preCount + (currentFilter?.length === 2 ? 0 : dataEvent.preCount)) < 100 ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{mtdCalculator(adjustedtarget?.prepaidtarget, data.preCount + (currentFilter?.length === 2 ? 0 : dataEvent.preCount)) || 0}%</span></div>
              <div className='top-div-bot'>
                <div className='target'>
                  <p>Target</p>
                  <span>{adjustedtarget?.prepaidtarget || 0}</span>
                </div>
                <div className='target'>
                  <p>Achieved</p>
                  <span>{data.preCount + (currentFilter?.length === 2 ? 0 : dataEvent.preCount)}</span>
                </div>
              </div>
            </div>
            <div className='widgetcontainer-top-left-div'>
              <div className='top-div-p'><p>Hup(MTD)</p></div>
              <div className='top-div-span'><span style={{ color: mtdCalculator(adjustedtarget?.hardwareupgrade, data.hupCount + (currentFilter?.length === 2 ? 0 : dataEvent.hupCount)) < 100 ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}> {mtdCalculator(adjustedtarget?.hardwareupgrade, data.hupCount + (currentFilter?.length === 2 ? 0 : dataEvent.hupCount)) || 0}%</span></div>
              <div className='top-div-bot'>
                <div className='target'>
                  <p>Target</p>
                  <span>{adjustedtarget?.hardwareupgrade || 0}</span>
                </div>
                <div className='target'>
                  <p>Achieved</p>
                  <span>{data.hupCount + (currentFilter?.length === 2 ? 0 : dataEvent.hupCount)}</span>
                </div>
              </div>
            </div>
            <div className='widgetcontainer-top-left-div'>
              <div className='top-div-p'><p>Addons(MTD)</p></div>
              <div className='top-div-span'><span style={{ color: mtdCalculator(adjustedtarget?.addon, data.addonsCount + (currentFilter?.length === 2 ? 0 : dataEvent.addonsCount)) < 100 ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{mtdCalculator(adjustedtarget?.addon, data.addonsCount + (currentFilter?.length === 2 ? 0 : dataEvent.addonsCount)) || 0}%</span></div>
              <div className='top-div-bot'>
                <div className='target'>
                  <p>Target</p>
                  <span>{adjustedtarget?.addon || 0}</span>
                </div>
                <div className='target'>
                  <p>Achieved</p>
                  <span>{data.addonsCount + (currentFilter?.length === 2 ? 0 : dataEvent.addonsCount)}</span>
                </div>
              </div>
            </div>
            <div className='widgetcontainer-top-left-div'>
              <div className='top-div-p'><p>Accessories(MTD)</p></div>
              <div className='top-div-span'><span style={{ color: mtdCalculator(adjustedtarget?.accessories, (totalAccMSRP === 0 ? 0 : totalAccMSRP ? totalAccMSRP : (value1 + value2))) < 100 ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{mtdCalculator(adjustedtarget?.accessories, (totalAccMSRP === 0 ? 0 : totalAccMSRP ? totalAccMSRP : (value1 + value2))) || 0}%</span></div>
              <div className='top-div-bot'>
                <div className='target'>
                  <p>Target</p>
                  <span>${adjustedtarget?.accessories?.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) || 0}</span>
                </div>
                <div className='target'>
                  <p>Achieved</p>
                  <span>${(totalAccMSRP === 0 ? 0 : totalAccMSRP ? totalAccMSRP : (value1 + value2))?.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) || 0}</span>
                </div>
              </div>
            </div>


          </div>
          <div className='widgetcontainer-top-right'>
            <div className='top-div-bot'>
              <div className='top-div-bot1'>
                <div className='top-div-title'><p>Target</p></div>
                <div className='top-div-body'>
                  <div className='target'>
                    <p>Apple/Care</p>
                    <span>{adjustedtarget?.applecare || 0}</span>
                  </div>
                  <div className='target'>
                    <p>SMS/Survey</p>
                    <span>{adjustedtarget?.smssurvey || 0}</span>
                  </div>
                </div>

              </div>
              <div className='top-div-bot2'>
                <div className='top-div-title'><p>Achieved</p></div>
                <div className='top-div-body'>
                  <div className='target'>
                    <p>Apple/Care</p>
                    <span style={{ color: (adjustedtarget?.applecare !== 0/* manualDataList?.applecare */) ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{/* manualDataList?.applecare */0 || 0}</span>
                  </div>
                  <div className='target'>
                    <p>AP</p>
                    <span>{data.autopayCount + (currentFilter?.length === 2 ? 0 : dataEvent.autopayCount)}</span>
                  </div>
                  <div className='target'>
                    <p>W/Device</p>
                    <span>{data.withDeviceCount + (currentFilter?.length === 2 ? 0 : dataEvent.withDeviceCount)}</span>
                  </div>
                  <div className='target'>
                    <p>PPP</p>
                    <span>{data.pppCount + (currentFilter?.length === 2 ? 0 : dataEvent.pppCount)}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className='section'>
          <div className='yearDD'>
              <InputLabel htmlFor="year-select-id" id="year-select-label">Select Year</InputLabel>
            <FormControl fullWidth >
              <Select
                labelId="year-select-label"
                id="year-select"
                value={selectedYear}
                className="year-select"
                onChange={(e) => setSelectedYear(Number(e.target.value))}
                aria-labelledby="year-select-label"
                label="Select Year"
                inputProps={{
                  id: 'year-select-id',
                }}
              >
                {[...Array(5)].map((_, index) => {
                  const year = new Date().getFullYear() - index;
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div className="charts">
            {loadingProgress ? (
              <div className="spinner-container">
                <ReactLoading type="bars" color="#09f" height={100} width={100} />
              </div>
            ) : (
              <ActivationsInsights yearData={yearData} type={currentFilter} eventYearData={eventYearData} />
            )
            }
            {loadingProgress ? (
              <div className="spinner-container">
                <ReactLoading type="bars" color="#09f" height={100} width={100} />
              </div>
            ) : (
              <Revenues yearData={yearData} type={currentFilter} eventYearData={eventYearData} />
            )
            }
          </div>
        </div>
        <div className="charts">
          {isLoading ? (
            <div className="spinner-container">
              <ReactLoading type="bars" color="#09f" height={100} width={100} />
            </div>
          ) : (
            <PieCharts cisdata={employeeData} accListAR={accListAR} accListOA={accListOA} type={currentFilter} eventCisData={eventCisData} />
          )}
          {isLoading ? (
            <div className="spinner-container">
              <ReactLoading type="bars" color="#09f" height={100} width={100} />
            </div>
          ) : (
            <Barcharts target={adjustedtarget} achieved={data} dataEvent={dataEvent} currentFilter={currentFilter} />
          )}

        </div>


        <div className='bot-widgets'>
          <div className='widgetcontainer'>
            <h3>Employee Commission</h3>
            <Commissions cisdata={employeeCommissions} targetRow={adjustedtarget} data={data} totalTarget={target} currentFilter={typeSelected} />
          </div>
          <div className='widgetcontainer'>
            <h3>Store Commission</h3>
            <StoreCommission cisdata={storeCommission} targetRow={adjustedtarget} data={data} />
          </div>
        </div>

        <div className="tablecontainer">
          <div className="bottomtable" style={{ height: 480, width: '100%' }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={rows ? rows : []}
              slotProps={{
                toolbar: {
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    allColumns: true,
                    fileName: 'ActivationSheet'
                  }
                },
                pagination: {
                  slotProps: {
                    select: {
                      name: 'pageSize',
                    }
                  }
                },
                baseCheckbox: {
                  name: 'cis-table-checkbox',
                },
              }}
              sx={{
                border: 0,

              }}
              slots={{
                toolbar: () => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                    <GridToolbar />
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => deleteCisData(arrIds)}
                      disabled={arrIds.length === 0} // Disable if no rows are selected
                    >
                      Delete
                    </Button>
                  </div>
                ),
              }}

              columns={columns}
              disableColumnMenu={true}
              checkboxSelection
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },

              }}
              pageSizeOptions={[5, 10]}
              onRowSelectionModelChange={(ids) => {
                setArrIds(ids)
              }}
            />
          </div>
        </div>
      </>

    </div>
  )
}

export default AdminDashboard
import React from 'react';
import './Employee.scss';
import EmpDashboard from '../../components/EmployeeDashboard/EmpDashboard';

const Employee = () => {


  return (
    <div className="employee">
          <EmpDashboard />
      </div>
  );
};

export default Employee;

import React, { useState, useEffect } from 'react';
import './BusinessReport.scss';
import PandLFilter from '../../components/PandLFilter';
import BRModal from '../../components/BRModal/BRModal';
import { getEmployeeCommissionReport, getManualInputData } from '../../helper/helper';
import toast, { Toaster } from 'react-hot-toast';
import { cisCommisson } from '../../components/ReusableFunctions';
import CustomButton from '../../components/ui/Button/Button';

const BusinessReport = () => {
  const [selectedStore, setSelectedStore] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [apiDataECR, setApiDataECR] = useState([]);
  const [manualData, setManualData] = useState([]);
  const [storePLData, setStorePLData] = useState([]);
  const [storeData, setStoreData] = useState(null);
  const [netProfileLoss, setNetProfileLoss] = useState(null);
  const [dateforModalInput, setDateForModalInput] = useState(null);
  const [cisdata, setCisdata] = useState([]);
  const [eventCisData, setEventCisData] = useState([]);



  useEffect(() => {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    const prevMonth = today.toLocaleString('default', { month: 'long' });
    const prevYear = today.getFullYear();
    const currentDate = prevMonth + ' ' + prevYear;
    const data1 = getEmployeeCommissionReport(currentDate);

    data1.then((data2) => {
      setApiDataECR(data2?.data[0]);
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const endDate = lastDayOfMonth.toISOString().split('T')[0];

    const data = getManualInputData({ endDate });
    data.then((res) => {
      setManualData(res.data);
    });
  }, []);

  const storeNameList = {
    OSHWTIN: 'Lake Vista Plaza',
    LAKESIN: 'Lakeshore & Islington',
    VYAD2IN: 'Center Mall Barton',
    TCPNFIN: 'Town Center Plaza',
    VYACBIN: 'Cedarbrae Mall',
    BARRIIN: 'Barrie',
    VYAREIN: 'Rexdale & Islington',
    DTWICIN: 'Briarfield Shopping Centre',
    DAN01IN: 'Danforth & Woodbine',
    VYOSHIN: 'Big Bay',
    VYAMLIN: 'Markham & Lawrence',
    VYAWLIN: 'Weston & Lawrence',
    DUNBLIN: 'Oriental Centre',
  };

  const handleAddPandLChange = (e) => {
    e.preventDefault();

    openModal();
  };
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onDataLoaded = (data) => {
    setCisdata(data);
  };

  const onEventDataLoaded = (data) => {
    setEventCisData(data);
  };

  function storeCommissionFromCis(computedCustomerList) {
    let prepaidCommission = 0;
    let postpaidCommission = 0;
    let hupCommission = 0;
    let addonCommission = 0;
    let hupMargin = 0;
    let deposit = 0;
    let tabHandset = 0;
    let spiffs = 0;

    for (let array = 0; array < computedCustomerList.length; array++) {
      if (computedCustomerList[array].type === 'Prepaid') {
        prepaidCommission += computedCustomerList[array].planCommission;
      } else if (
        computedCustomerList[array].type === 'Postpaid with device' ||
        computedCustomerList[array].type === 'Postpaid without device'
      ) {
        postpaidCommission += computedCustomerList[array].planCommission;
      } else if (computedCustomerList[array].type === 'Hup') {
        hupCommission += computedCustomerList[array].planCommission;
      }

      if (
        computedCustomerList[array].retailHandset * 0.05 < 20 &&
        computedCustomerList[array].retailHandset !== undefined
      ) {
        hupMargin += 20;
      } else if (
        computedCustomerList[array].retailHandset * 0.05 > 100 &&
        computedCustomerList[array].retailHandset !== undefined
      ) {
        hupMargin += 100;
      } else if (computedCustomerList[array].retailHandset !== undefined) {
        hupMargin += computedCustomerList[array].retailHandset * 0.05;
      }

      if (computedCustomerList[array].deposit !== 'Not Required') {
        deposit += parseFloat(computedCustomerList[array].deposit);
      }
      if (computedCustomerList[array].addonCommission !== undefined) {
        addonCommission += computedCustomerList[array].addonCommission;
      }
      /* if((computedCustomerList[array].addonCommission === 0 || computedCustomerList[array].addonCommission === "") && computedCustomerList[array].ppp === 'Accepted' ) {
                addonCommission += 27
            } */
      if (computedCustomerList[array].spiff !== undefined) {
        spiffs += computedCustomerList[array].spiff;
      }
      if (computedCustomerList[array].retailHandset !== undefined) {
        tabHandset += computedCustomerList[array].retailHandset;
      }
    }

    return {
      prepaidCommission,
      postpaidCommission,
      hupCommission,
      addonCommission,
      hupMargin,
      deposit,
      tabHandset,
      spiffs,
    };
  }

  useEffect(() => {
    const propertiesToCheck = [
      'storeInvoice',
      'saleInvoice',
      'tenderedDate',
      'time',
      'customerName',
      'email',
      'accountNum',
      'deposit',
      'retailPrice',
      'phoneProtectionPlan',
      'activatedPromotion',
      'phoneNum',
      'tempPhoneNum',
      'simNumber',
      'imei',
      'amountYouPaid',
      'connectionType',
      'activationDate',
      'phoneMake',
      'planName',
      'planPrice',
      'myBalanceOwing',
      'oneTimeCharges',
      'addons',
      'autoPayPre',
      'autoPayPost',
      'specialistId',
      'storeId',
      'user',
    ];

    const removeDuplicates = (arr) => {
      const uniqueObjects = [];
      arr.forEach((item) => {
        // Create a string representation of the object, including only the specified properties
        const stringifiedItem = JSON.stringify(
          propertiesToCheck.reduce((obj, key) => {
            obj[key] = item[key];
            return obj;
          }, {}),
        );

        // Add to uniqueObjects if not already included
        if (
          !uniqueObjects.some(
            (unique) =>
              JSON.stringify(
                propertiesToCheck.reduce((obj, key) => {
                  obj[key] = unique[key];
                  return obj;
                }, {}),
              ) === stringifiedItem,
          )
        ) {
          uniqueObjects.push(item);
        }
      });
      return uniqueObjects;
    };

    if (cisdata.length > 0 && eventCisData.length > 0 && storePLData.length === 0) {
      const combinedCisData = [...cisdata, ...eventCisData];
      const uniqueData = removeDuplicates(combinedCisData);
      const storeList = [];
      uniqueData.forEach((store) => {
        if (!storeList.includes(store.storeInvoice)) {
          storeList.push(store.storeInvoice);
        }
      });
      const storeData = {};
      storeList.forEach((store) => {
        let cisPerStore = uniqueData.filter((item) => item.storeInvoice === store);

        let computedCustomerList = cisCommisson(cisPerStore);
        storeData[store] = storeCommissionFromCis(computedCustomerList);
      });

      let totalStoresCom = 0;
      let totalHupMargin = 0;

      for (let key in storeData) {
        if (storeData.hasOwnProperty(key)) {
          let postpaidCommission = storeData[key].postpaidCommission;
          let prepaidCommission = storeData[key].prepaidCommission;
          let hupCommission = storeData[key].hupCommission;
          let addonCommission = storeData[key].addonCommission;
          let hupMargin = storeData[key].hupMargin;

          let sum = postpaidCommission + prepaidCommission + hupCommission + addonCommission;

          totalStoresCom += sum;
          totalHupMargin += hupMargin;
        }
      }

      let autoPopulateData = { totalStoresCom, totalHupMargin };

      let summedRevenue = {
        'Act Com': autoPopulateData.totalStoresCom,
        'HW Margin': autoPopulateData.totalHupMargin,
        'Acc Sales': 0,
        'Top up Margin': 0,
        'Bill Pay Margin': 0,
        'Total Sales': autoPopulateData.totalStoresCom + autoPopulateData.totalHupMargin,
      };
      let summedExpense = {
        Payroll: 0,
        'Acc Sales Cost': 0,
        'Mngt Pay': 0,
        'Rental Cost': 0,
        Telephone: 0,
        Internet: 0,
        Gas: 0,
        Hydro: 0,
        Alarm: 0,
        Ensurity: 0,
        Shiftlab: 0,
        'RQ Fee': 0,
        Statflo: 0,
        Moneris: 0,
        'Bank Fee': 0,
        Marketing: 0,
        'Petty Cash': 0,
        'Office Supplies': 0,
        'Store Insurance': 0,
        'QB Fee': 0,
        'Ceridian Fee': 0,
        'Cyber Security': 0,
        Misc: 0,
        'Total Expense': 0,
      };

      let result = {
        expense: summedExpense,
        revenue: summedRevenue,
      };

      const totalRev = result.revenue['Total Sales'];
      const totalExp = result.expense['Total Expense'];
      const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);

      setNetProfileLoss(netProfitLoss);
      setStoreData(result);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cisdata, eventCisData]);

  const setData = (data) => {
    setStorePLData(data);

    if (data.length === 0) {
      toast.success('No P&L data available');
    } else {
      let summedExpense = {};
      let summedRevenue = {};
      // Iterate through the array of objects
      data.forEach((obj1) => {
        for (let obj in obj1.plData) {
          // Iterate through each expense in the object and sum them up
          for (let key in obj1.plData[obj].expense) {
            let value = parseFloat(obj1.plData[obj].expense[key]);
            summedExpense[key] = (summedExpense[key] || 0) + value;
          }

          // Iterate through each revenue in the object and sum them up
          for (let key in obj1.plData[obj].revenue) {
            // Parse the value as an integer before summing
            let value = parseFloat(obj1.plData[obj].revenue[key]);
            summedRevenue[key] = (summedRevenue[key] || 0) + value;
          }
        }
      });

      // Convert summedExpense and summedRevenue into the desired format
      let result = {
        expense: summedExpense,
        revenue: summedRevenue,
      };
      const totalRev = result.revenue['Total Sales'];
      const totalExp = result.expense['Total Expense'];
      const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);

      setNetProfileLoss(netProfitLoss);
      setStoreData(result);
    }
  };

  const apiDataECRfunction = (data) => {
    setApiDataECR(data);
  };
  const apiAccessoryData = (data) => {
    setManualData(data);
  };
  const dateforModal = (data) => {
    setDateForModalInput(data);
  };

  const handleStoreChange = (e) => {
    e.preventDefault();
    setSelectedStore(e.target.value);
    const value = e.target.value;
    let storeData = {};

    if (value !== '') {
      for (let key1 in storePLData) {
        for (let key in storePLData[key1].plData) {
          if (key === storeNameList[value]) {
            storeData['expense'] = storePLData[key1].plData[key].expense;
            storeData['revenue'] = storePLData[key1].plData[key].revenue;
            storeData['store'] = key;
            const totalRev = storePLData[key1].plData[key].revenue['Total Sales'];
            const totalExp = storePLData[key1].plData[key].expense['Total Expense'];
            const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);
            setNetProfileLoss(netProfitLoss);
          }
        }
      }
      if (!storeData.store) {
        storeData = {
          store: storeNameList[value],
          expense: '',
          revenue: '',
        };
        setNetProfileLoss(null); // Reset the net profit/loss if no data is found
      }
      setStoreData(storeData);
    } else {
      let summedExpense = {};
      let summedRevenue = {};

      // Iterate through the array of objects
      storePLData.forEach((obj1) => {
        for (let obj in obj1.plData) {
          // Iterate through each expense in the object and sum them up
          for (let key in obj1.plData[obj].expense) {
            let value = parseFloat(obj1.plData[obj].expense[key]);
            summedExpense[key] = (summedExpense[key] || 0) + value;
          }

          // Iterate through each revenue in the object and sum them up
          for (let key in obj1.plData[obj].revenue) {
            // Parse the value as an integer before summing
            let value = parseFloat(obj1.plData[obj].revenue[key]);
            summedRevenue[key] = (summedRevenue[key] || 0) + value;
          }
        }
      });

      // Convert summedExpense and summedRevenue into the desired format
      let result = {
        expense: summedExpense,
        revenue: summedRevenue,
      };
      const totalRev = result.revenue['Total Sales'];
      const totalExp = result.expense['Total Expense'];
      const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);

      setNetProfileLoss(netProfitLoss);
      setStoreData(result);
    }
  };
  function formatNegativeNumber(number) {
    // Remove any non-digit characters and convert to a number

    const numericValue = parseFloat(number?.replace(/[^0-9.-]/g, ''));

    // Check if the number is negative
    if (numericValue < 0) {
      // Format the absolute value with commas for thousands separator
      const formattedNumber = Math.abs(numericValue).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      // Return the formatted number with parentheses
      return `(${formattedNumber})`;
    }
    // If the number is not negative, return it as is
    return number;
  }
  return (
    <div className="br-main">
      <Toaster position="top-center" reverseOrder={false}></Toaster>
     
      <div className="br-container">
       
        <div className="br-block-container">
          <div className="filter-menu-list">
            <div className="filter-menu">
              <PandLFilter
                onDataLoaded={onDataLoaded}
                onEventDataLoaded={onEventDataLoaded}
                setData={setData}
                apiDataECRfunction={apiDataECRfunction}
                apiAccessoryData={apiAccessoryData}
                dateforModal={dateforModal}
              />
            </div>

            <div className="filter-menu">
              <select
                name="selectedStpre"
                id="selectedStpre"
                value={selectedStore}
                onChange={handleStoreChange}
              >
                <option value="">All Stores</option>
                {Object.keys(storeNameList).map((key) => (
                  <option key={key} value={key}>
                    {storeNameList[key]}
                  </option>
                ))}
              </select>
              <BRModal
                isOpen={modalOpen}
                onClose={closeModal}
                apiDataECR={apiDataECR}
                manualData={manualData}
                dateforModalInput={dateforModalInput}
              />
            </div>

            <CustomButton
              onClick={handleAddPandLChange}
              buttonType="upload"
              className="add-button"
            />
          </div>
          <table className="accountant-table">
            <thead>
              <tr>
                <th>Revenue</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {storeData &&
                Object.entries(storeData?.revenue).map(([category, amount]) => (
                  <tr key={category}>
                    <td>{category}</td>
                    <td>
                      <span>$</span>
                      <span>
                        {parseFloat(amount)
                          .toFixed(2)
                          .toLocaleString('en-US', { maximumFractionDigits: 2 })}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <table className="accountant-table">
            <thead>
              <tr>
                <th>Expense</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {storeData &&
                Object.entries(storeData?.expense).map(([category, amount]) => (
                  <tr key={category}>
                    <td>{category}</td>
                    <td>
                      <span>$</span>
                      <span>
                        {parseFloat(amount)
                          .toFixed(2)
                          .toLocaleString('en-US', { maximumFractionDigits: 2 })}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className={`net-profit-loss ${netProfileLoss < 0 ? 'negative' : 'positive'}`}>
            <p>Net Profit/(Loss):</p>
            <div className="total-value">
              <span className="left-span">$</span>
              <span>
                {netProfileLoss > 0
                  ? parseFloat(netProfileLoss).toLocaleString('en-US', { maximumFractionDigits: 2 })
                  : formatNegativeNumber(netProfileLoss)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessReport;

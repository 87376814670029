import React, { useState, useEffect, useRef, Fragment } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {
  getTargetbyMonth,
  setTargets,
  deleteTargetDataRow,
  editTargetRow,
  getAllUsersData,
} from '../../helper/helper';
import ReadOnlyRows from '../../components/ReadOnlyRows';
import EditableRows from '../../components/EditableRows';
import './Targets.scss';

const Targets = () => {
  const [target, setTarget] = useState([]);
  const [userdata, setUserdata] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [singleStore, setSingleStore] = useState([]);
  const [storeInvoice, setStoreInvoice] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState([]);
  const [addFormData, setAddFormData] = useState({
    username: '',
    storename: '',
    storeinvoice: '',
    employeename: '',
    prepaidtarget: '',
    postpaidtarget: '',
    hardwareupgrade: '',
    addon: '',
    accessories: '',
    applecare: '',
    smssurvey: '',
    month: '',
    year: '',
  });

  const [editFormData, setEditFormData] = useState({
    username: '',
    storename: '',
    storeinvoice: '',
    employeename: '',
    prepaidtarget: '',
    postpaidtarget: '',
    hardwareupgrade: '',
    addon: '',
    accessories: '',
    applecare: '',
    smssurvey: '',
  });

  const [editEmployeeId, setEditEmployeeId] = useState(null);
  /*   const [currentMonth, setCurrentMonth] = useState(''); */
  const [currentYear, setCurrentYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterStore, setFilterStore] = useState('');
  const [currentEmpName, setCurrentEmpName] = useState('');
  const [currentStoreName, setCurrentStoreName] = useState('');
  const [filteredData, setFilteredData] = useState([]);

 

  const formRef = useRef();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  /* useEffect(() => {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    setCurrentMonth(month);
  }, []); */

  const storeNameList = {
    OSHWTIN: 'Lake Vista Plaza',
    LAKESIN: 'Lakeshore & Islington',
    VYAD2IN: 'Center Mall Barton',
    TCPNFIN: 'Town Center Plaza',
    VYACBIN: 'Cedarbrae Mall',
    BARRIIN: 'Barrie',
    VYAREIN: 'Rexdale & Islington',
    DTWICIN: 'Briarfield Shopping Centre',
    DAN01IN: 'Danforth & Woodbine',
    VYOSHIN: 'Big Bay',
    VYAMLIN: 'Markham & Lawrence',
    VYAWLIN: 'Weston & Lawrence',
    DUNBLIN: 'Oriental Centre',
  };

  useEffect(() => {
    getRawUserData();
  }, []);

  const storesList = [];
  for (let stores = 0; stores < target.length; stores++) {
    const newData = {
      storeinvoice: target[stores].storeinvoice,
      storename: target[stores].storename,
      postpaidtarget: parseInt(target[stores].postpaidtarget),
      prepaidtarget: parseInt(target[stores].prepaidtarget),
      hardwareupgrade: parseInt(target[stores].hardwareupgrade),
      addon: parseInt(target[stores].addon),
      accessories: parseInt(target[stores].accessories),
      applecare: parseInt(target[stores].applecare),
      smssurvey: parseInt(target[stores].smssurvey),
    };
    storesList.push(newData);
  }

  const resultObject = {};
  storesList.forEach((item) => {
    const {
      storeinvoice,
      storename,
      postpaidtarget,
      prepaidtarget,
      hardwareupgrade,
      addon,
      accessories,
      applecare,
      smssurvey,
    } = item;
    const combinedKey = `${storeinvoice}-${storename}`;

    if (resultObject.hasOwnProperty(combinedKey)) {
      resultObject[combinedKey].postpaidtarget += postpaidtarget;
      resultObject[combinedKey].prepaidtarget += prepaidtarget;
      resultObject[combinedKey].hardwareupgrade += hardwareupgrade;
      resultObject[combinedKey].addon += addon;
      resultObject[combinedKey].accessories += accessories;
      resultObject[combinedKey].applecare += applecare;
      resultObject[combinedKey].smssurvey += smssurvey;
    } else {
      resultObject[combinedKey] = {
        storeinvoice,
        storename,
        postpaidtarget,
        prepaidtarget,
        hardwareupgrade,
        addon,
        accessories,
        applecare,
        smssurvey,
      };
    }
  });
  const resultArray = Object.values(resultObject);

  async function getRawUserData() {
    try {
      let { data } = await getAllUsersData();

      let filteredUserData = [];
      data.forEach((user) => {
        if (user.employmentStatus === 'Active' && user.role !== 'admin') {
          filteredUserData.push(user);
        }
      });
      setUserdata(filteredUserData);
    } catch (error) {
      return error;
    }
  }

  const capitalizeFirstLetter = (string) => {
    let user = [];
    for (let key in userdata) {
      if (userdata[key].username === string) {
        user.push(userdata[key].fullname);
      }
    }
    return user.length > 0
      ? user[0]
      : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().slice(0, -11);
  };

  const handleUserChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    const selectedEmployee = [];
    const selectedStore = [];
    const selectedUsername = [];

    setSingleStore([]);
    setStoreInvoice([]);
    userdata.forEach((user) => {
      if (user.username === value)
        return (
          selectedEmployee.push(user.fullname),
          selectedStore.push(user.stores),
          selectedUsername.push(user.username)
        );
    });
    setSelectedName(selectedEmployee);
    setSelectedStore(selectedStore.toString().split(','));
    setSelectedUsername(selectedUsername);
  };

  const handleStoreChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setSingleStore(value);
    const keyForValue = getObjectKeyByValue(storeNameList, value);
    setStoreInvoice(keyForValue);
  };

  const getObjectKeyByValue = (obj, value) => {
    for (const [key, val] of Object.entries(obj)) {
      if (val === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found in the object
  };

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    /*  newFormData.month = currentMonth; */
    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    const newTarget = {
      /* id: nanoid(), */
      username: selectedUsername.toString(),
      storename: singleStore,
      storeinvoice: storeInvoice,
      employeename: selectedName.toString(),
      prepaidtarget: addFormData.prepaidtarget,
      postpaidtarget: addFormData.postpaidtarget,
      hardwareupgrade: addFormData.hardwareupgrade,
      addon: addFormData.addon,
      accessories: addFormData.accessories,
      applecare: addFormData.applecare,
      smssurvey: addFormData.smssurvey,
      month: addFormData.month,
      year: addFormData.year,
    };

    let targetPromise = setTargets(newTarget);
    toast.promise(targetPromise, {
      loading: 'Creating...',
      success: <b>Register Successfully...!</b>,
      error: <b>Could not Register.</b>,
    });
  };
  const handleClear = (event) => {
    event.preventDefault();
    formRef.current.reset();
    setSelectedName('');
    setSingleStore('');
    setStoreInvoice('');
    setSelectedUsername('');
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    const editTarget = {
      _id: editEmployeeId,
      username: editFormData.username,
      storename: editFormData.storename,
      storeinvoice: editFormData.storeinvoice,
      employeename: editFormData.employeename,
      prepaidtarget: editFormData.prepaidtarget,
      postpaidtarget: editFormData.postpaidtarget,
      hardwareupgrade: editFormData.hardwareupgrade,
      addon: editFormData.addon,
      accessories: editFormData.accessories,
      applecare: editFormData.applecare,
      smssurvey: editFormData.smssurvey,
    };

    const newTarget = [...target];

    const index = target.findIndex((target) => target._id === editEmployeeId);
    const editRow = (newTarget[index] = editTarget);

    const editRowPromise = editTargetRow({
      _id: editRow._id,
      username: editRow.username,
      storename: editRow.storename,
      storeinvoice: editRow.storeinvoice,
      employeename: editRow.employeename,
      prepaidtarget: editRow.prepaidtarget,
      postpaidtarget: editRow.postpaidtarget,
      hardwareupgrade: editRow.hardwareupgrade,
      addon: editRow.addon,
      accessories: editRow.accessories,
      applecare: editRow.applecare,
      smssurvey: editRow.smssurvey,
    });
    toast
      .promise(editRowPromise, {
        loading: 'Editing...',
        success: <b>Editied Successfully...!</b>,
        error: <b>Could not Edit.</b>,
      })
      .then(() => {
        /* getRawTargetData(); */
      });
    setTarget(newTarget);
    setEditEmployeeId(null);
  };

  const handleEditEmployeeTarget = (event, Target) => {
    event.preventDefault();
    setEditEmployeeId(Target._id);
    const formValues = {
      username: Target.username,
      storename: Target.storename,
      storeinvoice: Target.storeinvoice,
      employeename: Target.employeename,
      prepaidtarget: Target.prepaidtarget,
      postpaidtarget: Target.postpaidtarget,
      hardwareupgrade: Target.hardwareupgrade,
      addon: Target.addon,
      accessories: Target.accessories,
      applecare: Target.applecare,
      smssurvey: Target.smssurvey,
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditEmployeeId(null);
  };

  const handleDeleteClick = (TargetId) => {
    const newTarget = [...target];
    const index = newTarget.findIndex((target) => target._id === TargetId);

    newTarget.splice(index, 1);

    const deleteTargetPromise = deleteTargetDataRow({ id: TargetId });
    toast
      .promise(deleteTargetPromise, {
        loading: 'Deleting...',
        success: <b>Deleted Successfully...!</b>,
        error: <b>Could not Delete.</b>,
      })
      .then(() => {
        /* getRawTargetData(); */
      });
    setTarget(newTarget);
  };

  useEffect(() => {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    setSelectedMonth(month);
    setCurrentYear(year);
  }, []);

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setCurrentYear(selectedYear);
  };

  function EmployeeNameExtractor(target) {
    const targetNames = [];
    target.forEach((item) => {
      if (!targetNames.includes(item.employeename)) {
        targetNames.push(item.employeename);
      }
    });

    setFilterName(targetNames);
  }

  function storeNameExtractor(target) {
    const storeNames = [];
    target.forEach((item) => {
      if (!storeNames.includes(item.storename)) {
        storeNames.push(item.storename);
      }
    });
    setFilterStore(storeNames);
  }

  useEffect(() => {
    const data = getTargetbyMonth({ selectedMonth, currentYear });
    data.then((res) => {
      setTarget(res.data);
      setFilteredData(res.data);
      EmployeeNameExtractor(res.data);
      storeNameExtractor(res.data);
    });
  }, [selectedMonth, currentYear]);

  const startYear = 2024;
  const endYear = 2030;
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

  const handleEmployeeNameChange = (event) => {
    const filteredName = event.target.value;

    if (filteredName !== '') {
      let filteredNameResult = [];
      target.forEach((item) => {
        if (item.employeename === filteredName) {
          filteredNameResult.push(item);
        }
      });
      setFilteredData(filteredNameResult);
    } else {
      setFilteredData(target);
    }
    setCurrentEmpName(filteredName);
  };

  const handleStoreNameChange = (event) => {
    const filteredStore = event.target.value;
    if (filteredStore !== '') {
      let filteredStoreResult = [];
      target.forEach((item) => {
        if (item.storename === filteredStore) {
          filteredStoreResult.push(item);
        }
      });
      setFilteredData(filteredStoreResult);
    } else {
      setFilteredData(target);
    }
    setCurrentStoreName(filteredStore);
  };
  return (
    <div className="settargets">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{ duration: 1000 }}
        gutter={4}
      ></Toaster>

   
      <div className="targetcontainer">
     

        <div className="bottom">
          <div className="bottom-div">
            <div className="month-selector">
              {/* <MonthSelector onDataLoaded={updateTableData}/> */}
              <div className="month-div">
                <label htmlFor="monthSelector">Month:</label>
                <select id="monthSelector" onChange={handleMonthChange} value={selectedMonth}>
                  <option value="">-- Select Month --</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="yearSelector">Year:</label>
                <select id="yearSelector" onChange={handleYearChange} value={currentYear}>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="employeeFilter">Employee:</label>
                <select
                  id="employeeFilter"
                  onChange={handleEmployeeNameChange}
                  value={currentEmpName}
                >
                  <option value="">-- Select Employee --</option>
                  {filterName &&
                    filterName.map((name, index) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <label htmlFor="storeFilter">Store:</label>
                <select id="storeFilter" onChange={handleStoreNameChange} value={currentStoreName}>
                  <option value="">-- Select Store --</option>
                  {filterStore &&
                    filterStore.map((name, index) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="target_Table">
              <div className="left">
                <table>
                  <thead>
                    <tr>
                      <th>Store</th>
                      <th>Prepaid</th>
                      <th>Postpaid</th>
                      <th>HUP</th>
                      <th>Add on</th>
                      <th>Accessories</th>
                      <th>Apple Care</th>
                      <th>SMS Survey</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultArray.map((result, index) => (
                      <tr key={result.storename}>
                        <td>{result.storename}</td>
                        <td>{result.prepaidtarget}</td>
                        <td>{result.postpaidtarget}</td>
                        <td>{result.hardwareupgrade}</td>
                        <td>{result.addon}</td>
                        <td>
                          $
                          {result.accessories.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td>{result.applecare}</td>
                        <td>{result.smssurvey}</td>
                      </tr>
                    ))}
                  </tbody>
                  </table>
                    {(!resultArray || resultArray?.length === 0) && 
                      <p className='message'>The requested data for {selectedMonth} {currentYear} is currently unavailable.</p>
                    }
              </div>

              <div className="right">
                <form onSubmit={handleEditFormSubmit}>
                  <table>
                    <thead>
                      <tr>
                        {/* <th>Username</th>
                    <th>store Invoice</th> */}
                        <th>Name</th>
                        <th> Store</th>
                        <th>Pre</th>
                        <th>Post</th>
                        <th>HUP</th>
                        <th>Addon</th>
                        <th>Accessories</th>
                        <th>Apple Care</th>
                        <th>SMS</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((target, index) => (
                        <Fragment key={index}>
                          {editEmployeeId === target._id ? (
                            <EditableRows
                              editFormData={editFormData}
                              handleEditFormChange={handleEditFormChange}
                              handleCancelClick={handleCancelClick}
                            />
                          ) : (
                            <ReadOnlyRows
                              target={target}
                              handleEditEmployeeTarget={handleEditEmployeeTarget}
                              handleDeleteClick={handleDeleteClick}
                            />
                          )}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                  {(!filteredData || filteredData?.length === 0) && 
                      <p className='message'>The requested data for {selectedMonth} {currentYear} is currently unavailable.</p>
                    }
                </form>
              </div>
            </div>

            <div className="target_add">
              <form onSubmit={handleAddFormSubmit} ref={formRef}>
                <Toaster position="top-center" reverseOrder={false}></Toaster>
                <div className="dropDownList">
                  <h2>Add Target</h2>
                  <div>
                    <label className="title" htmlFor="userList">
                      Username:
                    </label>
                    <select name="userList" id="userList" onChange={handleUserChange}>
                      <option>Select</option>
                      {userdata.map((user) => (
                        <option key={user.username} value={user.username}>
                          {capitalizeFirstLetter(user.username)}
                          {/* {user.username} */}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <p>Employee Name: {selectedName}</p>
                  </div>
                  <div>
                    <label className="title" htmlFor="storeList">
                      Store:
                    </label>
                    <select
                      name="storeList"
                      id="storeList"
                      value={selectedStore[0]}
                      onChange={handleStoreChange}
                    >
                      <option>Select</option>
                      {selectedStore.map((store) => (
                        <option key={store}>{storeNameList[store.trim()]}</option>
                      ))}
                    </select>
                  </div>
                  <div className="last-div">
                    <p>
                      Store Name: {singleStore} {/* Invoice Code: {storeInvoice} */}
                    </p>
                  </div>
                </div>

                <div>
                  <input
                    type="number"
                    name="prepaidtarget"
                    required="required"
                    placeholder="Enter Prepaid Target here..."
                    onChange={handleAddFormChange}
                  />
                  <input
                    type="number"
                    name="postpaidtarget"
                    required="required"
                    placeholder="Enter Postpaid Target here..."
                    onChange={handleAddFormChange}
                  />
                  <input
                    type="number"
                    name="hardwareupgrade"
                    required="required"
                    placeholder="Enter Hardware Upgrade Target here..."
                    onChange={handleAddFormChange}
                  />
                  <input
                    type="number"
                    name="addon"
                    required="required"
                    placeholder="Enter Addon Target here..."
                    onChange={handleAddFormChange}
                  />
                  <input
                    type="number"
                    name="accessories"
                    required="required"
                    placeholder="Enter Accessories Target here..."
                    onChange={handleAddFormChange}
                  />
                  <input
                    type="number"
                    name="applecare"
                    required="required"
                    placeholder="Enter Apple Care Target here..."
                    onChange={handleAddFormChange}
                  />
                  <input
                    type="number"
                    name="smssurvey"
                    required="required"
                    placeholder="Enter SMS Survey Target here..."
                    onChange={handleAddFormChange}
                  />
                  <input
                    type="text"
                    name="month"
                    placeholder="Enter Month here..."
                    onChange={handleAddFormChange}
                  />
                  <input
                    type="text"
                    name="year"
                    placeholder="Enter Year Target here..."
                    onChange={handleAddFormChange}
                  />
                </div>
                <div className="add-btn">
                  <button type="submit">Add</button>
                  <button type="button" onClick={handleClear}>
                    Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Targets;

import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavigationGuard from './NavigationGuard'; // Import the NavigationGuard
import { SessionTimeoutProvider } from './SessionTimeoutContext';
import AdminSidebar from './components/AdminSidebar/AdminSidebar';
import Sidebar from './components/Sidebar/Sidebar';
import Navbar from './components/Navbar/Navbar';
import './Layout.scss'

const Layout = () => {
  const userDetail = JSON.parse(localStorage.getItem('user_data') || '{}')
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const location = useLocation()
  const toggleSidebar = () => {
    setSidebarVisible(prevState => !prevState); // Toggle the sidebar visibility
  };
  const closeSidebarOnClickOutside = (e) => {
      setSidebarVisible(false);
  };

  useEffect(() => {
    window.scroll(0, 0)
    closeSidebarOnClickOutside()
  }, [location]);

  return (
    <>
      <NavigationGuard /> {/* Guard applied globally */}
      <SessionTimeoutProvider>
        {location.pathname !== '/' ?
          <div className="main-layout">
            <div className={`layout-sidebar ${isSidebarVisible ? 'display': ''}`}>
              <div className="close-btn" onClick={toggleSidebar}>
                ✖
              </div>
              {userDetail?.role?.length > 0 && userDetail?.role === "admin" ? <AdminSidebar /> : <Sidebar />}
            </div>

            <div className='page-container'>
              <div className='nav'>
                <button className="hamburger" onClick={toggleSidebar}>
                  ☰
                </button>
                <Navbar />
              </div>
              <div className='page-wrapper'>
                <Outlet />
              </div>
            </div>

          </div>
          :
          <Outlet />
        }
      </SessionTimeoutProvider>
    </>
  );
};

export default Layout;

import React, { createContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SessionTimeoutContext.scss';
export const SessionTimeoutContext = createContext();

export const SessionTimeoutProvider = ({ children }) => {
  const location = useLocation();
  const [isIdle, setIsIdle] = useState(false);
  const navigate = useNavigate();
  const inactivityTimeout = useRef(null);
  const warningTimeout = useRef(null);
  const debounceTimer = useRef(null);

  const clearTimers = () => {
    if (inactivityTimeout.current) {
      clearTimeout(inactivityTimeout.current);
    }
    if (warningTimeout.current) {
      clearTimeout(warningTimeout.current);
    }
  };

  const startInactivityTimer = () => {
    if (location.pathname === '/') return;
    clearTimers();
    inactivityTimeout.current = setTimeout(() => {
      setIsIdle(true);
    }, 15 * 60 * 1000);
  };

  const handleActivity = () => {
    if (location.pathname === '/') return;
    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      clearTimers();
      setIsIdle(false);
      startInactivityTimer();
    }, 900);
  };

  const handleExtendSession = () => {
    setIsIdle(false);
    handleActivity();
  };

  const handleLogout = () => {
    setIsIdle(false);
    clearTimers();
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('user_data');

    navigate('/');
    document.removeEventListener('mousemove', handleActivity);
    document.removeEventListener('click', handleActivity);
    document.removeEventListener('keypress', handleActivity);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('click', handleActivity);
    document.addEventListener('keypress', handleActivity);

    return () => {
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('click', handleActivity);
      document.removeEventListener('keypress', handleActivity);
      clearTimers();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isIdle) {
      warningTimeout.current = setTimeout(() => {
        handleLogout();
      }, 1 * 60 * 1000);
    }
    return () => clearTimeout(warningTimeout.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle]);

  return (
    <SessionTimeoutContext.Provider value={{ isIdle, handleExtendSession }}>
      {isIdle && (
        <div className="overlay-context">
          <div className="modal-context">
            <p>Your session is about to expire. Do you want to extend it?</p>
            <button onClick={handleExtendSession}>Extend Session</button>
          </div>
        </div>
      )}
      {children}
    </SessionTimeoutContext.Provider>
  );
};

import React, { useState } from 'react';
import './AdminSidebar.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import logo from '../../assets/logomedium.png';
import { Link } from 'react-router-dom';

const AdminSidebar = () => {
  const [openSection, setOpenSection] = useState(null);
  const [clickedButton, setClickedButton] = useState(null);

  const handleSectionClick = (index) => {
    setOpenSection(index === openSection ? null : index);
    setClickedButton(index);
  };

  const accordionSections = [
    {
      title: 'Home',
      links: [
        { label: 'Dashboard', path: '/admin' },
        { label: 'Add User', path: '/adduser' },
        { label: 'View Users', path: '/userlist' },
        { label: 'View Attach Rate', path: '/viewar' },
      ],
    },
    {
      title: 'Upload',
      links: [
        { label: 'CIS', path: '/cisupload' },
        { label: 'Hours', path: '/emphours' },
        { label: 'Refunds', path: '/refund' },
        { label: 'Event CIS', path: '/eventcis' },
        { label: 'ScoreCard', path: '/scorecard' },
        { label: 'Home Internet', path: '/homeinternet' },
        { label: 'AccessorySKU', path: '/manualinputdata' },
      ],
    },
    {
      title: 'Targets',
      links: [{ label: 'Set Targets', path: '/targets' }],
    },
    {
      title: 'Performance',
      links: [
        { label: 'Ranking', path: '/rankingPage' },
        { label: 'Attach Rate', path: '/attachrate' },
        { label: 'Business P/L', path: '/businessreport' },
      ],
    },
  ];

  return (
    <div className="adminsidebar">
      <div className="top">
        <span className="logo">
          <img src={logo} alt="logo" />
        </span>
      </div>

      <div className="center">
        {accordionSections.map((section, index) => (
          <div key={index} className={`accordion-section ${openSection === index ? 'open' : ''} `}>
            <div
              className={`accordion-title ${clickedButton === index ? 'clicked' : ''}`}
              onClick={() => handleSectionClick(index)}
            >
              <div className="left ">
                {section.title === 'Home' && <DashboardIcon />}
                {section.title === 'Upload' && <FileUploadIcon />}
                {section.title === 'Targets' && <AdsClickIcon />}
                {/* {section.title === 'Reports' && (<AssessmentIcon />)} */}
                {section.title === 'Performance' && <MilitaryTechIcon />}
                {section.title}
              </div>

              <div className="arrow-icon ">
                {openSection === index ? (
                  <KeyboardDoubleArrowUpOutlinedIcon />
                ) : (
                  <KeyboardDoubleArrowDownOutlinedIcon />
                )}
              </div>
            </div>
            {openSection === index && (
              <div className="accordion-links">
                {section.links.map((link, linkIndex) => (
                  <div key={linkIndex} className={`accordion-link`}>
                    <Link to={link.path}>{link.label}</Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminSidebar;

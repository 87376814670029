import toast from 'react-hot-toast';
import { authenticate } from './helper';

/* User validate */

export async function userValidate(values) {
  const usernameerrors = usernameVerify({}, values);
  const passworderrors = passwordVerify({}, values);

  if (values.username && values.password) {
    // check user exist or not
    const { status } = await authenticate(values.username, values.password);

    if (status !== 200) {
      usernameerrors.exist = toast.error('User does not exist...!');
    }
    if (status !== 200) {
      passworderrors.exist = toast.error('password does not exist...!');
    }
  }

  return { usernameerrors, passworderrors };
}

/** validate login page username */
export async function usernameValidate(values) {
  const errors = usernameVerify({}, values);

  if (values.username) {
    // check user exist or not
    const { status } = await authenticate(values.username);

    if (status !== 200) {
      errors.exist = toast.error('User does not exist...!');
    }
  }

  return errors;
}

/** validate password */
export async function passwordValidate(values) {
  const errors = passwordVerify({}, values);

  return errors;
}

/** validate reset password */
export async function resetPasswordValidation(values) {
  const errors = passwordVerify({}, values);

  if (values.password !== values.confirm_pwd) {
    errors.exist = toast.error('Password not match...!');
  }

  return errors;
}

/** validate register form */
export async function registerValidation(values) {
  const errors = usernameVerify({}, values);
  passwordVerify(errors, values);
  emailVerify(errors, values);
  employeeIDVerify(errors, values);
  DOBVerify(errors, values);
  PhoneNumberVerify(errors, values);
  return errors;
}

/** validate profile page */
export async function profileValidation(values) {
  const errors = emailVerify({}, values);
  return errors;
}

/** ************************************************* */

/** validate password */
function passwordVerify(errors = {}, values) {
  /* eslint-disable no-useless-escape */
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  if (!values.password) {
    errors.password = toast.error('Password Required...!');
  } else if (values.password.includes(' ')) {
    errors.password = toast.error('Wrong Password...!');
  } else if (values.password.length < 4) {
    errors.password = toast.error('Password must be more than 4 characters long');
  } else if (!specialChars.test(values.password)) {
    errors.password = toast.error('Password must have special character');
  }

  return errors;
}

/** validate username */
function usernameVerify(error = {}, values) {
  if (!values.username) {
    error.username = toast.error('Username Required...!');
  } else if (values.username.includes(' ')) {
    error.username = toast.error('Invalid Username...!');
  }

  return error;
}

/** validate email */
function emailVerify(error = {}, values) {
  if (!values.email) {
    error.email = toast.error('Email Required...!');
  } else if (values.email.includes(' ')) {
    error.email = toast.error('Wrong Email...!');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    error.email = toast.error('Invalid email address...!');
  }

  return error;
}

/** validate employeeID */
function employeeIDVerify(error = {}, values) {
  if (!values.employeeid) {
    error.employeeid = toast.error('Employee ID is required');
  } else if (!/^[0-9]+$/.test(values.employeeid)) {
    error.employeeid = toast.error('Employee ID must only contain numbers');
  }
  return error;
}

/** validate DOB */
function DOBVerify(error = {}, values) {
  if (!values.dob) {
    error.dob = toast.error('Date of Birth is required');
  } else if (!/^\d{2}-\d{2}-\d{4}$/.test(values.dob)) {
    error.dob = toast.error('Date of Birth must be in DD-MM-YYYY format');
  }
  return error;
}

/** validate PhoneNumber */
function PhoneNumberVerify(error = {}, values) {
  if (!values.phonenumber) {
    error.phonenumber = toast.error('Phone number is required');
  } else if (!/^[0-9]{10}$/.test(values.phonenumber.trim())) {
    error.phonenumber = toast.error('Phone number must be 10 digits, like 6477030040');
  }
  return error;
}

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
/* import { usernameValidate } from '../../helper/validate' */
import { useAuthStore } from "../../store/store";
import { verifyPassword, getUser } from "../../helper/helper";
import logo from "../../assets/logo.png";
import "./Login.scss";

const Login = () => {
  const setUsername = useAuthStore((state) => state.setUsername);
  const navigate = useNavigate();
  const [focusedInput, setFocusedInput] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    /* validate: usernameValidate, */
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setDisabled(true)
      setUsername(values.username);
      if (!values.username || !values.password) {
        toast.error("Please enter your credentials.");
      } else {
        let data = getUser({ username: values.username });
        data.then(async (res) => {
          let apiData = res.data;
          if (apiData?.firstLogin === "true") {
            navigate("/recovery");
          } else {
            if (apiData?.employmentStatus === "Active") {
              try {
                // Await the API call so errors can be caught properly
                let res = await verifyPassword({
                  username: values.username,
                  password: values.password,
                });

                let { token, } = res.data;

                localStorage.setItem("token", token);
                localStorage.setItem("username", values.username);
                localStorage.setItem('user_data', JSON.stringify(apiData));

                toast.success(<b>Login Successfully...!</b>);

                // Navigate based on role
                setTimeout(() => {
                  if (apiData.role === "employee") {
                    window.location.pathname = "/employee"
                  } else if (apiData.role === "admin") {
                    window.location.pathname = "/admin";
                  } else if (apiData.role === "manager") {
                    window.location.pathname = "/manager";
                  }
                }, 100);
              } catch (error) {
                console.error("Login Error:", error);

                // Show a user-friendly error message
                toast.error(<b>Username or Password does not Match!</b>);
              }

            } else {
              toast.error("User not Found")
            }
          }
        })
      }
      setTimeout(() => {
        setDisabled(false)
      }, 3000)
    },
  });
  return (
    <div className="container">
      <Toaster position='top-center' reverseOrder={false} /* toastOptions={{ duration: 1000 }} */ gutter={4}></Toaster>
      <div className="left">
        <div className="overlay">
          <div className="bot">
            <div className="logo">
              <img src={logo} alt="logo" />
              <div className="footer">
                <p>Copyright © {new Date().getFullYear()} - Vyadom Group. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="right" onSubmit={formik.handleSubmit}>
        <div className="main">
          <h3 className="heading">Login</h3>
          <div className="inputs">
            <label htmlFor="email">Email</label>
            <input
              {...formik.getFieldProps("username")}
              onFocus={() => handleFocus("username")}
              style={{
                borderColor:
                  focusedInput === "username" ? "green" : "rgb(99 102 241)",
              }}
              type="text"
              id="email"
              autoComplete="on"
              onBlur={handleBlur}
              placeholder="Username"
            />
            <label htmlFor="pass">Password</label>
            <input
              id="pass"
              {...formik.getFieldProps("password")}
              onFocus={() => handleFocus("password")}
              style={{
                borderColor:
                  focusedInput === "password" ? "green" : "rgb(99 102 241)",
              }}
              autoComplete="on"
              type="password"
              onBlur={handleBlur}
              placeholder="Password"
            />
          </div>
          <div className="remember-me--forget-password">
            <p>
              {" "}
              <Link className="recover" to="/recovery">
                Forgot Password?
              </Link>
            </p>
          </div>
          <div className="submitbutton">
            <button disabled={disabled} type="submit">Login</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;

import React, { useState, useEffect } from 'react';
import './EditProfile.scss';
import { getUser, updateUserProfilePicture } from '../../helper/helper';
import toast, { Toaster } from 'react-hot-toast';
import convertToBase64 from '../../helper/convert';
import avatar from '../../assets/profile.png';
import { getValues } from '../ReusableFunctions';

const EditProfile = () => {
  const _username = localStorage.getItem('username')
  const [username, setUserName] = useState(_username)
  const [apiData, setApiData] = useState('');
  const [file, setFile] = useState();
  const [Image, setImage] = useState('');

  const values = getValues(apiData?.stores);



  useEffect(() => {
    let userdata = getUser({ username: username });
    userdata.then((res) => {
      let apiData = res.data;
      setApiData(apiData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_username]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const maxSizeBytes = 100 * 1024;
    if (username) {
      if (Image.size > maxSizeBytes) {
        alert('Image size exceeds the maximum allowed size (100 KB).');
        setFile(null);
        setImage(null);
      } else {
        let profilePicture = file || '';
        let updatePromise = updateUserProfilePicture({
          profilePicture: profilePicture,
          username: username,
        });
        updatePromise.then(() => {
          localStorage.setItem('username', username)
        })

        toast.promise(updatePromise, {
          loading: 'Updating...',
          success: <b>Update Successfully...!</b>,
          error: <b>Could not Update!</b>,
        });
      }
    }
  };
  /** formik doensn't support file upload so we need to create this handler */
  const onUpload = async (e) => {
    setImage(e.target.files[0]);
    const base64 = await convertToBase64(e.target.files[0]);
    setFile(base64);
  };

  return (
    <div className="edit-profile">

      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <div className="edit-password-main">
        <div className="edit-password-container">


          <form onSubmit={handleSubmit}>
            <div className="form-top">
              <label htmlFor="profile">
                <img
                  src={apiData?.profilePicture || file || avatar}
                  className="form-top-img"
                  alt="avatar"
                />
              </label>
              <input hidden onChange={onUpload} type="file" id="profile" name="profile" />
            </div>

            <div className="form-bot">
              <h2>Basic Information</h2>
              <div className="forminput">
                <label htmlFor="fullname">Full Name:</label>
                <input
                  id="fullname"
                  value={apiData?.fullname || 'Not Available'}
                  type="text"
                  placeholder="FullName"
                  readOnly
                />
              </div>
              <div className="forminput">
                <label htmlFor="email">Email:</label>
                <input
                  id="email"
                  value={apiData?.email || 'Not Available'}
                  type="text"
                  placeholder="Email"
                  autoComplete='off'
                  readOnly
                />
              </div>
              <div className="forminput">
                <label htmlFor="phonenumber">Phone:</label>
                <input
                  id="phonenumber"
                  value={apiData?.phonenumber || 'Not Available'}
                  type="text"
                  placeholder="PhoneNumber"
                  readOnly
                />
              </div>
              <div className="forminput">
                <label htmlFor="dob">Birthday:</label>
                <input
                  id="dob"
                  value={apiData?.dob || 'Not Available'}
                  type="text"
                  placeholder="DOB"
                  readOnly
                />
              </div>
              <h2 style={{marginTop: '15px'}}>Professional Information</h2>
              <div className="forminput">
                <label htmlFor="username">UserName:</label>
                <input
                  id="username"
                  onChange={(e) => setUserName(e.target.value)}
                  value={username || 'Not Available'}
                  type="text"
                  placeholder="UserName"
                  autoComplete='off'
                  readOnly
                />
              </div>

              <div className="forminput">
                <label htmlFor="role">Job Title:</label>
                <input
                  id="role"
                  value={apiData?.role || 'Not Available'}
                  type="text"
                  placeholder="Role"
                  readOnly
                />
              </div>

              <div className="forminput">
                <label htmlFor="employeeid">Employee ID:</label>
                <input
                  id="employeeid"
                  value={apiData?.employeeid || 'Not Available'}
                  type="text"
                  placeholder="EmployeeId"
                  readOnly
                />
              </div>
              <div className="forminput">
                <label htmlFor="stores">Location(s):</label>
                <textarea
                  id="stores"
                  value={values || 'Not Available'}
                  type="text"
                  placeholder="Stores"
                  readOnly
                />
              </div>

             {(apiData && apiData?.role !== 'admin') && <p className="message">
                {apiData?.fullname}, you can change your profile photo here. For other updates, please contact the administrator.
              </p>}
              <div className="button">
                <button >Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;

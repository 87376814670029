import React, { useState, useEffect } from 'react';
import './EmpHours.scss';
import UploadModal from './UploadModal';
import { getHoursWorkedData } from '../../helper/helper';
import { MenuItem, Select } from '@mui/material';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import toast, { Toaster } from 'react-hot-toast';
import CustomButton from '../../components/ui/Button/Button';

const EmpHours = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const generateMonths = () => {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  };


  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 4; i >= 0; i--) {
      // Generate the last 5 years, including the current year
      years.push(currentYear - i);
    }
    return years;
  };

  const years = generateYears();
  const months = generateMonths();
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Update startDate and endDate when selectedMonth or selectedYear changes
    const selectedMonthIndex = months.findIndex((month) => month === selectedMonth);
    const selectedDate = new Date(selectedYear, selectedMonthIndex);
    setStartDate(format(startOfMonth(selectedDate), 'yyyy-MM-dd'));
    setEndDate(format(endOfMonth(selectedDate), 'yyyy-MM-dd'));
    /*  setStartDate(format(startOfMonth(selectedDate), 'yyyy-MM-dd'));
         if (selectedMonth === format(new Date(), 'MMMM') && selectedYear === new Date().getFullYear()) {
             // If current month and year, set endDate to today
             setEndDate(format(new Date(), 'yyyy-MM-dd'));
         } else {
             // Otherwise, set endDate to the last day of the selected month
             setEndDate(format(endOfMonth(selectedDate), 'yyyy-MM-dd'));
         } */
  }, [selectedMonth, selectedYear, months]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSearch = async () => {
    setIsLoading(true);
    const { data } = await getHoursWorkedData({ startDate, endDate });
    if (data.length > 0) {
      setApiData(data);
    } else {
      toast.error('No Employee Hours Data is Present to Show.');
    }
  };

  if (isLoading && apiData?.length > 0) {
    setIsLoading(false); // Stop loading if data is fetched
  }

  const StoreTable = ({ storeHours, empHours }) => {
    const sortedDataStore = storeHours?.sort((a, b) => b[1] - a[1]);
    const sortedDataEmp = empHours?.sort((a, b) => b[1] - a[1]);
    return (
      <>
        <div className="storeTable">
          <table>
            <thead>
              <tr>
                <th>Store</th>
                <th>Hours</th>
              </tr>
            </thead>
            <tbody>
              {sortedDataStore?.map((store, index) => (
                <tr key={index}>
                  <td>{store[0]}</td>
                  <td>{store[1]?.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="empTable">
          <table>
            <thead>
              <tr>
                <th>Employee</th>
                <th>Hours</th>
              </tr>
            </thead>
            <tbody>
              {sortedDataEmp?.map((emp, index) => (
                <tr key={index}>
                  <td>{emp[0]}</td>
                  <td>{emp[1]?.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };
  return (
    <div className="eh-main">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{ duration: 1000 }}
        gutter={4}
      ></Toaster>
     
      <div className="eh-container">
       
        {/* <div className="eh-block"> */}

        <div className="eh-block-container">
          <div className="filter-menu-list">
            <div className="filter-menu">
              <div className="filter-datemenu">
                <p>Select Month:</p>
                <Select
                  id="monthSelect"
                  name="monthSelect"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  displayEmpty
                  className="filter-datemenu-select"
                >
                  {months.map((month, index) => (
                    <MenuItem key={index} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
                <p style={{ marginLeft: '10px' }}>Select Year:</p>
                <Select
                  id="yearSelect"
                  name="yearSelect"
                  value={selectedYear}
                  onChange={handleYearChange}
                  displayEmpty
                  className="filter-datemenu-select"
                >
                  {years.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
                {/* <IconButton onClick={handleSearch} sx={{ ml: 2 }}>
                  <SearchIcon />
                </IconButton> */}
              </div>
              <CustomButton className="search-button" onClick={handleSearch} buttonType="search" />
            </div>
            {/* <div className="upload-button-container"> */}
              <UploadModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
              {/* <Tooltip title="Upload Hours File" arrow placement="top">
                                    <IconButton sx={{ ml: 2 }} onClick={openModal}>
                                        <FileUploadIcon />
                                    </IconButton>
                                </Tooltip> */}
              <CustomButton buttonType='upload' btnLabel='Upload Hours File' className='upload-btn' onClick={openModal}/>
            {/* </div> */}
          </div>
          {apiData.length > 0 && (
            <div className="datatable">
              <StoreTable
                storeHours={apiData[0]?.storeHours.filter(
                  (item) => item[0] !== 'undefined' && item[1] !== null,
                )}
                empHours={apiData[0]?.empHours.filter(
                  (item) => item[0] !== 'undefined' && item[1] !== null,
                )}
              />
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default EmpHours;

import React, { useState, useEffect } from 'react';
import './ManualInputData.scss';
import { Toaster } from 'react-hot-toast';
import { getManualSkuList } from '../../helper/helper';
import ReactLoading from 'react-loading';
import Tooltip from '@mui/material/Tooltip';
import AccessorySkuModal from './AccessorySkuModal';
import IconButton from '@mui/material/IconButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const ManualInputData = () => {
  const [skuApi, setSkuApi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);


  useEffect(() => {
    async function getRawUserData() {
      setIsLoading(true);
      try {
        let { data } = await getManualSkuList();
        setSkuApi(data[0].manualFile);
        if (data[0].manualFile.length > 0) {
          setIsLoading(false);
        }
      } catch (error) {
        return error;
      }
    }
    getRawUserData();
  }, []);

  const headers = [
    { key: 'sku', label: 'SKU' },
    { key: 'name', label: 'Accessory Name' },
    { key: 'cost', label: 'Cost' },
    { key: 'msrp', label: 'MSRP' },
  ];

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = skuApi.filter(
    (item) =>
      item[0].toLowerCase().includes(searchTerm.toLowerCase()) ||
      item[1].toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const clearSearch = () => {
    setSearchTerm('');
  };

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="manual">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{ duration: 1000 }}
        gutter={4}
      ></Toaster>
     
      <div className="manual-container">
        
        <div className="manual-block">
          <div className="manual-block-container">
            <div className="bot">
              <div className="top-bar">
                <div className="upload-button-container">
                  <p>Upload Accessory SKU Sheet</p>
                  <AccessorySkuModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
                  <Tooltip title="Upload File" arrow placement="top">
                    <IconButton sx={{ ml: 2 }} onClick={openModal}>
                      <FileUploadIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="search">
                  <input
                    type="text"
                    placeholder="Search by SKU or Accessory Name"
                    value={searchTerm}
                    name='search'
                    onChange={handleSearch}
                    className="search-input"
                  />
                  {searchTerm && (
                    <button onClick={clearSearch} className="clear-button">
                      &times;
                    </button>
                  )}
                </div>
              </div>

              {isLoading ? (
                <div className="loading-overlay">
                  <ReactLoading type="spinningBubbles" color="white" height={'10%'} width={'10%'} />
                </div>
              ) : (
                <>
                  <div className="table">
                    <table className="professional-table">
                      <thead>
                        <tr>
                          {headers.map((header) => (
                            <Tooltip
                              key={header.key}
                              title={`${header.label}`}
                              arrow
                              placement="top"
                            >
                              <th>{header.label}</th>
                            </Tooltip>
                          ))}
                        </tr>
                      </thead>
                      {filteredData?.map((sku, index) => (
                        <tbody key={index}>
                          <tr key={index}>
                            <td>{sku[0]}</td>
                            <td>{sku[1]}</td>
                            <td>{sku[2]}</td>
                            <td>{sku[3]}</td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualInputData;

import React, { useState, useEffect } from 'react';
import './FinalCommission.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { getUser } from '../../helper/helper';

const FinalCommission = () => {
  // eslint-disable-next-line no-unused-vars
  const [apiData, setApiData] = useState('');
  const username = localStorage.getItem('username');
  useEffect(() => {
    let data = getUser({ username: username });
    data.then((res) => {
      let apiData = res.data;
      setApiData(apiData);
    });
  }, [username]);
  return (
    <div className="final-commission">
      <Sidebar />
      <div className="final-commission-container">
        <div className="final-nav_conatiner">
          <Navbar />
        </div>

        <div className="final-commission-dashboard">
          {/* <div className="final-dash-container">
            <h2 className='final-dash-title'>Final Commission</h2>
            <div className='dash-block'>
              <div className='commission-sections'>
                <div className='commission-sections-title'>
                  <h3>Uploaded Commission</h3>
                </div>

                <div className='commission-sections-bot'>
                  <div className='dash-blocks'>
                    <span className="title">Postpaid</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Prepaid</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Hup</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Autopay</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Addons</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Total</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                </div>
              </div>
              <div className='commission-sections'>
                <div className='commission-sections-title'>
                  <h3>Same Month Deactivation Commission</h3>
                </div>

                <div className='commission-sections-bot'>
                  <div className='dash-blocks'>
                    <span className="title">Postpaid</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Prepaid</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Hup</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Autopay</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Addons</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Total</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                </div>
              </div>
              <div className='commission-sections'>
                <div className='commission-sections-title'>
                  <h3>Clawback Commission</h3>
                </div>

                <div className='commission-sections-bot'>
                  <div className='dash-blocks'>
                    <span className="title">Postpaid</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Prepaid</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Hup</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Autopay</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Addons</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Total</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                </div>
              </div>
              <div className='commission-sections'>
                <div className='commission-sections-title'>
                  <h3>Final Commission</h3>
                </div>

                <div className='commission-sections-bot'>
                  <div className='dash-blocks'>
                    <span className="title">Postpaid</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Prepaid</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Hup</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Autopay</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Addons</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                  <div className='dash-blocks'>
                    <span className="title">Total</span>
                    <span className="counter">2</span>
                    <span className="commission">$20</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FinalCommission;

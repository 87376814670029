import React, { useState } from 'react';
import {
  getPandLData,
  getEmployeeCommissionReport,
  getManualInputData,
  getFilterdByDate,
  getEventCisData,
} from '../helper/helper';
import CustomButton from './ui/Button/Button';
const PandLFilter = ({
  setData,
  apiDataECRfunction,
  apiAccessoryData,
  dateforModal,
  onDataLoaded,
  onEventDataLoaded,
}) => {
  const currentDate = new Date();
  // Calculate the first day of the month
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  // Calculate the last day of the month
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  // Format the first and last day as strings (YYYY-MM-DD)
  const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
  const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(firstDayOfMonthString);
  const [endDate, setEndDate] = useState(lastDayOfMonthString);

  const handleSearch = () => {
    async function getRawCisData() {
      const today = new Date(endDate);
      const prevMonth = today.toLocaleString('default', { month: 'long' });
      const prevYear = today.getFullYear();
      const endDate1 = prevMonth + ' ' + prevYear;
      try {
        let { data } = await getPandLData(endDate1);
        setData(data);
      } catch (error) {
        return error;
      }
      const data1 = getEmployeeCommissionReport(endDate1);
      data1.then((data2) => {
        apiDataECRfunction(data2?.data[0]);
      });
      const data = getManualInputData({ endDate });
      data.then((res) => {
        apiAccessoryData(res.data);
      });
      dateforModal(endDate1);

      try {
        let { data } = await getFilterdByDate({ startDate, endDate });
        onDataLoaded(data);
      } catch (error) {
        return error;
      }

      try {
        let { data } = await getEventCisData({ startDate, endDate });
        onEventDataLoaded(data);
      } catch (error) {}
    }
    getRawCisData();
  };

  return (
    <>
      <div className="filter-datemenu">
        <label htmlFor="startDate">From:</label>
        <input
          type="date"
          id="startDate"
          className="filter-datemenu-input"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <label htmlFor="endDate">To:</label>
        <input
          type="date"
          id="endDate"
          className="filter-datemenu-input"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      <CustomButton buttonType="search" className="filter-datemenu-button" onClick={handleSearch} />
    </>
  );
};

export default PandLFilter;

import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import { registerValidation } from '../../helper/validate';
import { registerUser } from '../../helper/helper';
import './AddUser.scss';
import Select from 'react-select';

const AddUser = () => {
  const roleOptions = ['employee', 'manager', 'admin'];
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionEmp, setSelectedOptionEmp] = useState(null);



  const storesList = [
    'Lake Vista Plaza',
    'Lakeshore & Islington',
    'Center Mall Barton',
    'Town Center Plaza',
    'Cedarbrae Mall',
    'Barrie',
    'Rexdale & Islington',
    'Briarfield Shopping Centre',
    'Danforth & Woodbine',
    'Big Bay',
    'Markham & Lawrence',
    'Weston & Lawrence',
    'Oriental Centre',
  ];

  const storeNameList = {
    OSHWTIN: 'Lake Vista Plaza',
    LAKESIN: 'Lakeshore & Islington',
    VYAD2IN: 'Center Mall Barton',
    TCPNFIN: 'Town Center Plaza',
    VYACBIN: 'Cedarbrae Mall',
    BARRIIN: 'Barrie',
    VYAREIN: 'Rexdale & Islington',
    DTWICIN: 'Briarfield Shopping Centre',
    DAN01IN: 'Danforth & Woodbine',
    VYOSHIN: 'Big Bay',
    VYAMLIN: 'Markham & Lawrence',
    VYAWLIN: 'Weston & Lawrence',
    DUNBLIN: 'Oriental Centre',
  };

  // Generate a random password
  function generateRandomPassword(length) {
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$&*()-_=+[]{}|;:,.<>?';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  }

  // Usage
  const randomPassword = generateRandomPassword(8); // Generates a 12-character random password

  const formik = useFormik({
    initialValues: {
      fullname: '',
      username: '',
      password: randomPassword,
      email: '',
      role: '',
      phonenumber: '',
      employeeid: '',
      stores: '',
      dob: '',
      firstLogin: 'true',
      profilePicture: '',
      employmentStatus: 'Active',
    },
    validate: registerValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      const demo = selectedOption.map((value, index) => {
        return Object.values(value)[0];
      });
      const selectedKeys = Object.keys(storeNameList).filter((key) =>
        demo.includes(storeNameList[key]),
      );
      values.stores = selectedKeys.join(',');
      values.role = Object.values(...selectedOptionEmp)[0];
      let registerPromise = registerUser(values);
      toast.promise(registerPromise, {
        loading: 'Creating...',
        success: <b>Register Successfully...!</b>,
        error: <b>Could not Register.</b>,
      });
      registerPromise.then(() => {
        formik.resetForm();
      });
    },
    handleReset: () => {
      formik.resetForm();
      setSelectedOptionEmp(null);
      setSelectedOption(null);
    },
  });

  return (
    <div className="adduser">
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <div className="addusercontainer">
        <div className="container">
          <div className="bottom">
            <form onSubmit={formik.handleSubmit}>
              <div className="forminput">
                <label htmlFor='fullname' >Full Name:</label>
                <input
                  {...formik.getFieldProps('fullname')}
                  type="text"
                  id='fullname'
                  autoComplete='off'
                  placeholder="Enter Full Name"
                />
              </div>

              <div className="forminput">
                <label htmlFor="username">Username:</label>
                <input
                  {...formik.getFieldProps('username')}
                  type="text"
                  id="username"
                  autoComplete='off'
                  placeholder="Enter Username"
                />
              </div>

              <div className="forminput">
                <label htmlFor="password">Password:</label>
                <input
                  {...formik.getFieldProps('password')}
                  type="text"
                   id="password"
                  autoComplete='off'
                  placeholder="Enter Password"
                />
              </div>

              <div className="forminput">
                <label htmlFor="email">Email:</label>
                <input {...formik.getFieldProps('email')}  id="email" autoComplete='off' type="text" placeholder="Enter Email" />
              </div>
              <div className="forminput">
                <label htmlFor="phonenumber">Phone Number:</label>
                <input
                  {...formik.getFieldProps('phonenumber')}
                  type="text"
                  autoComplete='off'
                  id="phonenumber"
                  placeholder="Enter Phone Number"
                />
              </div>

              <div className="forminput">
                <label htmlFor="employeeid">Employee ID:</label>
                <input
                  {...formik.getFieldProps('employeeid')}
                  type="text"
                  autoComplete='off'
                   id="employeeid"
                  placeholder="Enter Employee ID"
                />
              </div>
              <div className="forminput">
                <label htmlFor="dob">Date of Birth:</label>
                <input {...formik.getFieldProps('dob')}  id="dob" type="text" placeholder="DD-MM-YYYY" />
              </div>
              <div className="forminput">
                <p>Role:</p>
                <Select
                  className="select"
                  isSearchable
                  name='Role'
                  id='role'
                  isMulti
                  defaultValue={selectedOptionEmp}
                  onChange={setSelectedOptionEmp}
                  options={roleOptions.map((role) => {
                    return { value: role, label: role };
                  })}
                />
              </div>
              <div className="forminput">
                <p>Stores:</p>
                <Select
                  className="select"
                  name='stores'
                    id='stores'
                  isMulti
                  isSearchable
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={storesList.map((store) => {
                    return { value: store, label: store };
                  })}
                />
              </div>
              <div className="button">
                <button onSubmit={formik.handleSubmit} type="submit">
                  Add User
                </button>
                <button onClick={formik.handleReset}>Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;

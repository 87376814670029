import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './Layout';
import Recovery from './components/Recovery/Recovery';
import ResetPassword from './components/ResetPassword/ResetPassword';
import PageNotFound from './components/Pages/PageNotFound';
import Login from '../src/Container/Login/Login';
import Employee from '../src/Container/Employee/Employee';
import Admin from '../src/Container/Admin/AdminHome';
import Manager from '../src/Container/Manager/Manager';
import UserList from '../src/Container/UserList/UserList';
import AddUser from '../src/Container/AddUser/AddUser';
import ScoreCard from '../src/Container/ScoreCard/ScoreCard';
import Targets from '../src/Container/Targets/Targets';
import ChangePassword from '../src/components/ChangePassword/ChangePassword';
import EditProfile from '../src/components/EditProfile/EditProfile';
import CommissionStructure from '../src/Container/CommissionStructure/CommissionStructure';
import Refund from '../src/Container/Refund/Refund';
import BusinessReport from '../src/Container/BusinessReport/BusinessReport';
import FinalCommission from '../src/Container/FinalCommission/FinalCommission';
import CisUpload from '../src/Container/CisUpload/CisUpload';
import ManualInputData from '../src/Container/AccessorySKU/ManualInputData';
import EmployeeCommissionReport from '../src/Container/EmployeeCommissionReport/EmployeeCommissionReport';
import RankingPage from '../src/Container/RankingPage/RankingPage';
import AttachRate from '../src/Container/AttachRate/AttachRate';
import EmpHours from '../src/Container/EmpHours/EmpHours';
import HomeInternet from '../src/Container/HomeInternet/HomeInternet';
import ViewAR from '../src/Container/ViewAR/ViewAR';
import EventCis from '../src/Container/EventCis/EventCis';

/** Auth middleware */
import { AuthorizeUser } from './middleware/auth';

/** App version */
localStorage.setItem("app_version", "1.0.0.1");

/** Root Routes */
const allRoutes = [
  { path: '/', element: <Login />, },
  { path: '/employee', element: <Employee />, roles: ['employee', 'manager', 'admin'] },
  { path: '/admin', element: <Admin />, roles: ['employee', 'manager', 'admin'] },
  { path: '/userlist', element: <UserList />, roles: ['admin'] },
  { path: '/adduser', element: <AddUser />, roles: ['admin'] },
  { path: '/recovery', element: <Recovery />, roles: ['employee', 'manager', 'admin'] },
  { path: '/reset', element: <ResetPassword />, roles: ['employee', 'manager', 'admin'] },
  { path: '*', element: <PageNotFound />, roles: ['employee', 'manager', 'admin'] },
  { path: '/manager', element: <Manager />, roles: ['employee', 'manager', 'admin'] },
  { path: '/scorecard', element: <ScoreCard />, roles: ['employee', 'manager', 'admin'] },
  { path: '/targets', element: <Targets />, roles: ['employee', 'manager', 'admin'] },
  { path: '/changepassword', element: <ChangePassword />, roles: ['employee', 'manager', 'admin'] },
  { path: '/editprofile', element: <EditProfile />, roles: ['employee', 'manager', 'admin'] },
  { path: '/commissionstructure', element: <CommissionStructure />, roles: ['employee', 'manager', 'admin'] },
  { path: '/refund', element: <Refund />, roles: ['employee', 'manager', 'admin'] },
  { path: '/businessreport', element: <BusinessReport />, roles: ['admin'] },
  { path: '/finalcommission', element: <FinalCommission />, roles: ['employee', 'manager', 'admin'] },
  { path: '/cisupload', element: <CisUpload />, roles: ['employee', 'manager', 'admin'] },
  { path: '/manualinputdata', element: <ManualInputData />, roles: ['employee', 'manager', 'admin'] },
  { path: '/employeeCommissionReport', element: <EmployeeCommissionReport />, roles: ['employee', 'manager', 'admin'] },
  { path: '/rankingPage', element: <RankingPage />, roles: [ 'manager','admin'] },
  { path: '/attachrate', element: <AttachRate />, roles: ['employee', 'manager', 'admin'] },
  { path: '/emphours', element: <EmpHours />, roles: ['admin'] },
  { path: '/homeinternet', element: <HomeInternet />, roles: ['employee', 'manager', 'admin'] },
  { path: '/viewar', element: <ViewAR />, roles: ['employee', 'manager', 'admin'] },
  { path: '/eventcis', element: <EventCis />, roles: ['employee', 'manager', 'admin'] },
]

/** Function to filter routes based on user role */
function getFilteredRoutes(userRole) {
  return allRoutes
    .filter(route => !route.roles || route.roles.includes(userRole))
    .map(route => ({
      ...route,
      element: route.roles ? <AuthorizeUser>{route.element}</AuthorizeUser> : route.element,
    }));
}

export default function App() {
  const userData = JSON.parse(localStorage.getItem('user_data') || '{}');
  const userRole = userData.role || 'guest';

  const filteredRoutes = getFilteredRoutes(userRole);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: filteredRoutes,
    },
  ]);

  return (
    <main className="main_wrapper">
      <RouterProvider router={router} />
    </main>
  );
}

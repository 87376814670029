import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import toast, { Toaster } from 'react-hot-toast';
import './CisModal.scss';

import { submitCisData, getManualSkuList, submitAR } from '../../helper/helper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const CisModal = ({ modalIsOpen, closeModal, rqCISFormresult }) => {
  const username = localStorage.getItem('username');
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    storeInvoice: '',
    saleInvoice: '',
    tenderedDate: '',
    time: '',
    customerName: '',
    email: '',
    accountNum: '',
    deposit: '',
    retailPrice: '',
    phoneProtectionPlan: '',
    activatedPromotion: '',
    phoneNum: '',
    tempPhoneNum: '',
    simNumber: '',
    imei: '',
    amountYouPaid: '',
    connectionType: '',
    activationDate: '',
    phoneMake: '',
    planName: '',
    planPrice: '',
    myBalanceOwing: '',
    oneTimeCharges: '',
    addons: '',
    autoPayPre: '',
    autoPayPost: '',
    specialistId: '',
    storeId: '',
    user: username,
    sign: true,
  });

  const [errors, setErrors] = useState({});

  const fieldTitles = {
    saleInvoice: 'Sale Invoice',
    storeInvoice: 'Store Invoice',
    tenderedDate: 'Tendered Date',
    time: 'Time',
    customerName: 'Customer Name',
    email: 'Email Address',
    accountNum: 'Account Number',
    deposit: 'Deposit Amount',
    retailPrice: 'Retail Price',
    phoneProtectionPlan: 'Phone Protection Plan',
    activatedPromotion: 'Activated Promotion',
    phoneNum: 'Phone Number',
    tempPhoneNum: 'Temporary Phone Number',
    simNumber: 'SIM Number',
    imei: 'IMEI',
    amountYouPaid: 'Amount Paid',
    connectionType: 'Connection Type',
    activationDate: 'Activation Date',
    phoneMake: 'Phone Make',
    planName: 'Plan Name',
    planPrice: 'Plan Price',
    myBalanceOwing: 'Balance Owing',
    oneTimeCharges: 'One-Time Charges',
    addons: 'Add-Ons',
    autoPayPre: 'Auto Pay Prepaid',
    autoPayPost: 'Auto Pay Postpaid',
    specialistId: 'Specialist ID',
    storeId: 'Store ID',
    user: 'User',
  };

  function dateConversion(date) {
    // Parse the input date string using the Date constructor
    const parsedDate = new Date(date);
    // Format the parsed date in the "YYYY-MM-DD" format
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(parsedDate.getDate()).padStart(2, '0');

    // Create the formatted date string
    const formattedDateStr = `${year}-${month}-${day}`;
    return formattedDateStr;
  }

  function extractDiscountInfo(data, skuList) {
    const discountInfo = {};

    skuList.forEach((sku) => {
      // Regex for the first format
      const regex1 = new RegExp(
        `${sku}.*?\\$(\\d+\\.\\d+)\\s\\d+\\.\\d+\\s\\$(\\d+\\.\\d+)\\s\\$(\\d+\\.\\d+)`,
        's',
      );
      // Regex for the second format
      const regex2 = new RegExp(
        `${sku}.*?\\$([\\d\\.]+)\\sList Price\\s\\$([\\d\\.]+)\\sSave\\s\\$([\\d\\.]+)`,
        's',
      );

      let match = data.match(regex1);
      if (match) {
        const discount = parseFloat(match[2]);
        discountInfo[sku] = {
          discount,
        };
      } else {
        match = data.match(regex2);
        if (match) {
          const discount = parseFloat(match[3]);
          discountInfo[sku] = {
            discount,
          };
        }
      }
    });

    return discountInfo;
  }

  useEffect(() => {
    let values = {};
    if (rqCISFormresult) {
      const numberPattern = /\d+/g;
      let DFF = '';
      if (rqCISFormresult.includes('ACCANS003120')) {
        DFF = 'DFF';
      }

      const matches = rqCISFormresult.match(numberPattern);

      let rqImei = '';

      if (matches) {
        for (const match of matches) {
          if (match.length === 15) {
            // Check if the matched number has 15 digits
            rqImei = match; // Output: 351024743679099
          }
        }
      } 

      function saleInvoiceExtraction(rqresult) {
        const pattern = /Sale Invoice : ([A-Z0-9]+)/;

        // Use the RegExp.exec() method to find the match
        const match = pattern.exec(rqresult);

        // Extract the desired text (if a match is found)
        let extractedText = '';
        if (match && match[1]) {
          extractedText = match[1];
          // Output: VYAD2IN134752
        }
        return extractedText;
      }

      function accountNumberExtraction(input) {
        const pattern = /(DBA|DBC|DBB)\d{11}/;

        const match = pattern.exec(input);

        return match ? match[0] : '';
      }

      function containsNumber(target, search) {
        return target.toString().includes(search.toString());
      }

      function simExtraction(input) {
        const pattern = /Contract\s#(.*?)DB/;

        const match = pattern.exec(input);
        const searchNumber = 89149000;

        return match && containsNumber(match[1], searchNumber) /* === "true" */
          ? match[1].trim().slice(-19).trim()
          : '';
      }

      const timePattern = /\b(0?[1-9]|1[0-2]):[0-5][0-9]\s?[APap][Mm]\b/g;

      // Use the match method to find all matches
      const times = rqCISFormresult.match(timePattern);
      const formattedDateStr = dateConversion(/\d{2}-[A-Za-z]{3}-\d{4}/.exec(rqCISFormresult)[0]);
      values = {
        storeInvoice: /Sale\sInvoice(.*?)Tendered\sOn/i
          .exec(rqCISFormresult)[1]
          .trim()
          .slice(2, 9)
          .trim(),
        saleInvoice: saleInvoiceExtraction(rqCISFormresult),
        tenderedDate: formattedDateStr,
        time: times[0],
        imei: rqImei ? rqImei : DFF ? 'DFF' : '',
        accountNum: DFF ? '' : accountNumberExtraction(rqCISFormresult),
        simNumber: simExtraction(rqCISFormresult),
      };
    }
    if (values) {
      setFormData((prevData) => ({ ...prevData, ...values }));
    }
  }, [rqCISFormresult]);

  if (!modalIsOpen) {
    return null;
  }

  const validateField = (name, value) => {
    let error = '';

    // Validation for phone number and temp phone number
    if (['phoneNum', 'tempPhoneNum'].includes(name)) {
      if (value !== 'N/A')
        if (!/^\d{10}$/.test(value)) {
          error = 'Phone number must be exactly 10 digits.';
        }
    }
    return error;
  };

  const handleSetNA = () => {
    setFormData((prevData) => {
      const updatedData = {};
      Object.keys(prevData).forEach((key) => {
        updatedData[key] = prevData[key] === '' ? 'N/A' : prevData[key];
      });
      return updatedData;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      // Automatically update storeInvoice based on saleInvoice
      if (name === 'saleInvoice') {
        const nonNumericPart = value.replace(/\d+/g, ''); // Extract non-numeric part
        updatedData.storeInvoice = nonNumericPart; // Update storeInvoice fiel
      }

      if (name === 'autoPayPre') {
        if (value !== 'N/A') {
          updatedData.autoPayPost = 'N/A'; // Set autoPayPost to "N/A" if autoPayPre is not "N/A"
        }
      } else if (name === 'autoPayPost') {
        if (value !== 'N/A') {
          updatedData.autoPayPre = 'N/A'; // Set autoPayPre to "N/A" if autoPayPost is not "N/A"
        }
      }
      /* if (name === "activationDate") {
                updatedData.activationDate = dateConversion(value)
            } */

      return updatedData;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};

    // Check for empty fields

    Object.keys(formData).forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${fieldTitles[field]} is required`;
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      if (rqCISFormresult.length > 0) {
        const { data } = await getManualSkuList();
        const newData = data[0].manualFile;
        const skuList = newData.map((item) => item[0]);
        const foundSKUs = skuList.filter((sku) => rqCISFormresult.includes(sku));
        const attachedAcc = foundSKUs?.map((sku) => newData.find((item) => item[0] === sku));
        const discountInfo = extractDiscountInfo(rqCISFormresult, foundSKUs);

        let totalCost = 0;
        let totalMsrp = 0;
        let totalDiscount = 0;
        attachedAcc.forEach((item) => {
          const [sku /* productName */, , cost, msrp] = item;
          totalCost += cost;
          totalMsrp += msrp;
          if (discountInfo[sku] && discountInfo[sku].discount) {
            totalDiscount += discountInfo[sku].discount;
          }
        });
        let aaData = {
          cost: totalCost,
          msrp: totalMsrp,
          discount: totalDiscount,
        };

        const {
          storeInvoice,
          saleInvoice,
          tenderedDate,
          time,
          customerName,
          email,
          accountNum,
          deposit,
          retailPrice,
          phoneProtectionPlan,
          activatedPromotion,
          phoneNum,
          tempPhoneNum,
          simNumber,
          imei,
          amountYouPaid,
          connectionType,
          activationDate,
          phoneMake,
          planName,
          planPrice,
          myBalanceOwing,
          oneTimeCharges,
          addons,
          autoPayPre,
          autoPayPost,
          specialistId,
          storeId,
          user,
        } = formData;
        if (isChecked) {
          let homePromise = submitCisData({
            storeInvoice,
            saleInvoice,
            tenderedDate,
            time,
            customerName,
            email,
            accountNum,
            deposit,
            retailPrice,
            phoneProtectionPlan,
            activatedPromotion,
            phoneNum,
            tempPhoneNum,
            simNumber,
            imei,
            amountYouPaid,
            connectionType,
            activationDate,
            phoneMake,
            planName,
            planPrice,
            myBalanceOwing,
            oneTimeCharges,
            addons,
            autoPayPre,
            autoPayPost,
            specialistId,
            storeId,
            user,
          });

          homePromise
            .then((res) => {})
            .catch((err) => {
              toast.error(err.response.data.error.error);
            });

          toast.promise(homePromise, {
            loading: 'Uploading...',
            success: <b>Uploaded Successfully...!</b>,
            error: <b>Not uploaded</b>,
          });
          homePromise.then(() => {
            submitAR({
              specialistId,
              tenderedDate,
              saleInvoice,
              discountInfo,
              attachedAcc,
              connectionType,
              simNumber,
              user,
              aaData,
              storeInvoice,
              phoneProtectionPlan,
              imei,
            });
          });
        }
      } else {
        toast.error('Please Upload atleast a CIS file and RQ file before clicking Upload Button.');
      }
      closeModal();
    }
  };

  return (
    <div className="cisForm-Modal">
      <div className="modal-container">
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{ duration: 1000 }}
          gutter={4}
        ></Toaster>
        <h2>CIS Form</h2>
        <form onSubmit={handleSubmit}>
          {Object.keys(formData).map((field) => (
            <div className="form-field" key={field}>
              {field === 'connectionType' ? (
                <FormControl fullWidth>
                  <InputLabel id={`${field}-label`}>{fieldTitles[field]}</InputLabel>
                  <Select
                    labelId={`${field}-label`}
                    name={field}
                    value={formData[field]}
                    onChange={handleInputChange}
                    label={fieldTitles[field]}
                  >
                    <MenuItem value="Postpaid">Postpaid</MenuItem>
                    <MenuItem value="Prepaid">Prepaid</MenuItem>
                  </Select>
                </FormControl>
              ) : field === 'deposit' ? (
                <FormControl fullWidth>
                  <InputLabel id={`${field}-label`}>{fieldTitles[field]}</InputLabel>
                  <Select
                    labelId={`${field}-label`}
                    name={field}
                    value={formData[field]}
                    onChange={handleInputChange}
                    label={fieldTitles[field]}
                  >
                    <MenuItem value="N/A">N/A</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="150">150</MenuItem>
                    <MenuItem value="300">300</MenuItem>
                  </Select>
                </FormControl>
              ) : field === 'phoneProtectionPlan' ? (
                <FormControl fullWidth>
                  <InputLabel id={`${field}-label`}>{fieldTitles[field]}</InputLabel>
                  <Select
                    labelId={`${field}-label`}
                    name={field}
                    value={formData[field]}
                    onChange={handleInputChange}
                    label={fieldTitles[field]}
                  >
                    <MenuItem value="N/A">N/A</MenuItem>
                    <MenuItem value="Accepted">Accepted</MenuItem>
                  </Select>
                </FormControl>
              ) : field === 'autoPayPre' || field === 'autoPayPost' ? (
                <FormControl fullWidth>
                  <InputLabel id={`${field}-label`}>{fieldTitles[field]}</InputLabel>
                  <Select
                    labelId={`${field}-label`}
                    name={field}
                    value={formData[field]}
                    onChange={handleInputChange}
                    label={fieldTitles[field]}
                  >
                    <MenuItem value="N/A">N/A</MenuItem>
                    <MenuItem value="successfully for Pre - Authorized Payments">
                      successfully for Pre - Authorized Payments
                    </MenuItem>
                  </Select>
                </FormControl>
              ) : field === 'sign' ? (
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                        /* inputProps={{ 'aria-label': 'controlled' }} */
                        color="primary"
                        name="checkbox"
                        id='name'
                      />
                    }
                    label="By selecting, you confirm the CIS is signed by both you and the Customer."
                  />
                </FormControl>
              ) : (
                <TextField
                  name={field}
                  label={`${fieldTitles[field]} *`}
                  value={formData[field]}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors[field]}
                  autoComplete='off'
                  InputProps={{
                    readOnly: [
                      'storeInvoice',
                      'saleInvoice',
                      'user',
                      'tenderedDate',
                      'time',
                      'accountNum',
                    ].includes(field),
                    style: { textTransform: 'uppercase' }, // Transform input text to uppercase
                  }}
                  helperText={
                    errors[field]
                      ? errors[field]
                      : field !== 'user' && 'Add "N/A" if no data is available.'
                  }
                />
              )}
            </div>
          ))}
          <div className="form-actions">
            <Button className="submit-btn" type="submit" disabled={!isChecked}>
              Submit
            </Button>
            <Button className="cancel-btn" onClick={handleSetNA}>
              Set Empty Fields to N/A
            </Button>
            <Button className="cancel-btn" onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CisModal;

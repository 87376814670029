import React, { useState, useEffect } from 'react';
import {
  getEventCisData,
  getFilterdByDate,
  getAllUsersData,
  getUser,
  getTargetbyMonth,
  getAttachRate,
  getOnlyAccessory,
} from '../helper/helper';

function DateFilter({
  combinedTargets,
  onDataLoaded,
  targetData,
  manualData,
  currentMonth,
  setLoadingCondition,
  AAMSRP,
  OAMSRP,
  accessoryListAR,
  accessoryListOA,
  onEventDataLoaded,
  datesSelected,
}) {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
  const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(firstDayOfMonthString);
  const [endDate, setEndDate] = useState(lastDayOfMonthString);

  /* const [userDetail, setUserDetail] = useState(null); */
  const [userList, setuserList] = useState([]);

  const username = localStorage.getItem('username');

  /* useEffect(() => {
        async function fetchUser() {
            let { data } = await getUser({ username: username });
            setUserDetail(data)
        }
        fetchUser();
    }, [username]) */

  useEffect(() => {
    async function fetchUser() {
      let { data } = await getAllUsersData();
      setuserList(data);
    }
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  useEffect(() => {
    /* function formatMonthYear(startDate, endDate) {
            // Parse the dates as UTC to avoid time zone issues
            const start = new Date(`${startDate}T00:00:00Z`);
            const end = new Date(`${endDate}T00:00:00Z`);

            // Get the start and end years and months
            const startYear = start.getUTCFullYear();
            const endYear = end.getUTCFullYear();
            const startMonth = start.getUTCMonth(); // 0-based index (0 = January, 11 = December)
            const endMonth = end.getUTCMonth();

            // If both dates are in the same month and year, return the formatted month-year
            if (startYear === endYear && startMonth === endMonth) {
                const month = start.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
                return [`${month} ${startYear}`];
            }

            // Otherwise, return months spanning between the start and end months
            let result = [];
            let currentMonth = startMonth;
            let currentYear = startYear;

            // Loop through each month until the end date
            while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
                const month = new Date(Date.UTC(currentYear, currentMonth)).toLocaleString('default', { month: 'long', timeZone: 'UTC' });
                result.push(`${month} ${currentYear}`);

                // Move to the next month
                currentMonth++;
                if (currentMonth === 12) { // Reset to January if December
                    currentMonth = 0;
                    currentYear++;
                }
            }

            return result;
        } */

    async function getRawCisData() {
      setLoadingCondition(true);
      try {
        let { data } = await getFilterdByDate({ startDate, endDate });

        if (data?.length > 0) {
          setLoadingCondition(false);
        }
        onDataLoaded(data);

        /* const months = formatMonthYear(startDate, endDate)
                const monthData = {};
                const monthList = {};
                const dateList = {};
                const cisData = {};
                months.forEach(date => {
                    const [monthName, yearName] = date.split(' ');
                    const key = `${monthName} ${yearName}`; // Use month-year as the key
                    const data1 = getTargetbyMonth({ selectedMonth: monthName, currentYear: yearName });
                    data1?.then(res => {
                        monthData[key] = res.data;
                        monthList[key] = [monthName, yearName];
                        dateList[key] = { startDate, endDate }
                        cisData[key] = data;
                        const combinedData = {
                            monthData,
                            monthList,
                            dateList,
                            cisData
                        };
                        combinedTargets(combinedData)
                    })
                }) */

        const dateObj = new Date(endDate);
        const options = { month: 'long' };
        const yearOptions = { year: 'numeric' };
        const monthName = new Intl.DateTimeFormat('en-US', options).format(dateObj);
        const yearName = new Intl.DateTimeFormat('en-US', yearOptions).format(dateObj);

        if (monthName !== '' && yearName !== '') {
          const data1 = getTargetbyMonth({ selectedMonth: monthName, currentYear: yearName });
          data1?.then((res) => {
            targetData(res.data);
            currentMonth([monthName, yearName]);
            const datesSelector = { startDate, endDate };
            datesSelected(datesSelector);
          });
        }

        // Example usage:
      } catch (error) {
        return error;
      }

      try {
        let { data } = await getEventCisData({ startDate, endDate });
        onEventDataLoaded(data);
      } catch (error) {}
      const { data } = await getAttachRate({ startDate, endDate });

      const removeDuplicatesByInvoice = (array) => {
        const seenInvoices = new Set();
        return array.filter((item) => {
          if (!item.saleInvoice) {
            return true;
          }
          const duplicate = seenInvoices.has(item.saleInvoice);
          seenInvoices.add(item.saleInvoice);
          return !duplicate;
        });
      };
      const uniqueArray = removeDuplicatesByInvoice(data);
      if (uniqueArray?.length > 0) {
        let { data } = await getUser({ username: username });
        if (data?.role === 'manager') {
          let finalArray = [];
          data?.stores?.split(',').forEach((store) => {
            userList.forEach((user) => {
              let exist = user.stores.split(',').some((item) => item === store);
              if (exist) {
                if (!finalArray.includes(user)) {
                  finalArray.push(user);
                }
              }
            });
          });
          let storesList = data?.stores?.split(',');
          let filterAR = [];
          storesList.forEach((store) => {
            uniqueArray.forEach((obj) => {
              const { storeInvoice } = obj;
              if (storeInvoice === store) {
                filterAR.push(obj);
              }
            });
          });
          dataProcess(filterAR);
        } else if (data?.role === 'admin') {
          dataProcess(uniqueArray);
        } else if (data?.role === 'employee') {
          let filterAR = [];
          uniqueArray.forEach((obj) => {
            if (obj.user === data?.username) {
              filterAR.push(obj);
            }
          });
          dataProcess(filterAR);
        }
      }

      function dataProcess(uniqueArray) {
        accessoryListAR(uniqueArray);
        const groupedBySpecialist = {};
        const groupedByStore = {};
        uniqueArray.forEach((obj) => {
          const { user, ...rest } = obj;
          if (!groupedBySpecialist[user]) {
            groupedBySpecialist[user] = [rest];
          } else {
            groupedBySpecialist[user].push(rest);
          }
          const { storeInvoice, ...rest1 } = obj;
          if (!groupedByStore[storeInvoice]) {
            groupedByStore[storeInvoice] = [rest1];
          } else {
            groupedByStore[storeInvoice].push(rest1);
          }
        });
        function getMSRP(data) {
          const groupedBySpecialistmerged = {};
          for (let key in data) {
            let hupSum = { sf: 0 };
            let actSum = { sf: 0 };

            data[key].forEach((obj) => {
              const { aaData, connectionType, simNumber } = obj;
              const { msrp, discount } = aaData;
              const sf = msrp - discount;
              if (
                (connectionType === 'Postpaid' || connectionType === 'Prepaid') &&
                simNumber === 'N/A'
              ) {
                hupSum.sf += sf;
              } else if (
                (connectionType === 'Postpaid' || connectionType === 'Prepaid') &&
                simNumber !== 'N/A'
              ) {
                actSum.sf += sf;
              }
            });
            groupedBySpecialistmerged[key] = {
              msrp: actSum.sf + hupSum.sf,
            };
          }
          return groupedBySpecialistmerged;
        }

        let groupedBySpecialistwithstore = {};
        for (let key in groupedBySpecialist) {
          const empbystore = groupedBySpecialist[key].reduce((result, item) => {
            // Get the storeInvoice value
            const { storeInvoice } = item;

            // Check if the group already exists, if not create an empty array for the group
            if (!result[storeInvoice]) {
              result[storeInvoice] = [];
            }

            // Push the current item to the group
            result[storeInvoice].push(item);

            return result;
          }, {});
          groupedBySpecialistwithstore[key] = getMSRP(empbystore);
        }

        const empData = getMSRP(groupedBySpecialist);
        const storeData = getMSRP(groupedByStore);
        AAMSRP([storeData, empData, groupedBySpecialistwithstore]);
      }
    }
    getRawCisData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    function getMSRP(data) {
      const groupedByAccmerged = {};
      for (let key in data) {
        let accSum = { sf: 0 };
        data[key].forEach((obj) => {
          const { aaDataOnlyAccessory } = obj;
          const { msrp: msrpOA, discount: discountOA } = aaDataOnlyAccessory;
          const sf = msrpOA - discountOA;
          accSum.sf += sf;
        });
        groupedByAccmerged[key] = {
          OAMsrp: accSum.sf,
        };
      }
      return groupedByAccmerged;
    }
    async function fetchUser() {
      let { data } = await getOnlyAccessory({ startDate, endDate });
      let filtered = [];
      data.forEach((obj) => {
        filtered.push(obj.attachmentRateOnlyAccessory);
      });
      const removeDuplicatesByInvoice = (array) => {
        const seenInvoices = new Set();
        return array.filter((item) => {
          if (!item.saleInvoiceOA) {
            return true; // Keep objects without saleInvoice
          }
          const duplicate = seenInvoices.has(item.saleInvoiceOA);
          seenInvoices.add(item.saleInvoiceOA);
          return !duplicate;
        });
      };
      const uniqueArray = removeDuplicatesByInvoice(filtered);
      if (uniqueArray.length > 0) {
        let { data } = await getUser({ username: username });

        if (data?.role === 'manager') {
          let storesList = data?.stores?.split(',');
          let matchingInvoices = uniqueArray
            .filter((item) => {
              let prefix = '';
              if (item.saleInvoiceOA !== '') {
                prefix = item?.saleInvoiceOA.match(/^[a-zA-Z]+/)[0];
              }
              // Extract the prefix part of the saleInvoiceOA
              /*  let prefix = item?.saleInvoiceOA.match(/^[a-zA-Z]+/)[0]; */
              // Check if the extracted prefix matches any prefix in array2
              return storesList.some((prefixInArray) => prefix === prefixInArray);
            })
            .map((item) => item);

          dataProcess(matchingInvoices);
        } else if (data?.role === 'admin') {
          dataProcess(uniqueArray);
        } else if (data?.role === 'employee') {
          let filterAR = [];
          uniqueArray.forEach((obj) => {
            if (obj.username === data?.username) {
              filterAR.push(obj);
            }
          });
          dataProcess(filterAR);
        }
      }

      function dataProcess(filtered) {
        accessoryListOA(filtered);
        let oAEmpList = {};
        let oAStoreList = {};
        filtered.forEach((obj) => {
          const { username, ...rest } = obj;

          if (!oAEmpList[username]) {
            oAEmpList[username] = [rest];
          } else {
            oAEmpList[username].push(rest);
          }
        });
        filtered.forEach((obj) => {
          const { saleInvoiceOA, ...rest } = obj;
          const storeInvoice = saleInvoiceOA.slice(0, 7);
          if (!oAStoreList[storeInvoice]) {
            oAStoreList[storeInvoice] = [rest];
          } else {
            oAStoreList[storeInvoice].push(rest);
          }
        });

        let groupedBySpecialistwithstore = {};
        for (let key in oAStoreList) {
          const empbystore = oAStoreList[key].reduce((result, item) => {
            // Get the storeInvoice value
            const { username } = item;

            // Check if the group already exists, if not create an empty array for the group
            if (!result[username]) {
              result[username] = [];
            }

            // Push the current item to the group
            result[username].push(item);

            return result;
          }, {});
          groupedBySpecialistwithstore[key] = getMSRP(empbystore);
        }
        const empMSRP = getMSRP(oAEmpList);
        const storeMSRP = getMSRP(oAStoreList);
        OAMSRP([empMSRP, storeMSRP, groupedBySpecialistwithstore]);
      }
    }

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div className="filter-datemenu">
      <label htmlFor="startDate">From:</label>
      <input
        type="date"
        id="startDate"
        className="filter-datemenu-input"
        name='startDate'
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />

      <label htmlFor="endDate">To:</label>
      <input
        type="date"
        id="endDate"
         name='endDate'
        className="filter-datemenu-input"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
    </div>
  );
}

export default DateFilter;

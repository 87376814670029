import React, { useEffect, useState } from 'react';
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { format, parseISO } from 'date-fns';
import { storeNameList } from '../../ReusableFunctions';
import {
  cisCommisson,
  calculateStoreCommissionAfterCisCommission,
} from '../../../components/ReusableFunctions';

const Revenues = ({ yearData, type, eventYearData = [] }) => {
  const [lineChartData, setLineChartData] = useState([]);

  function findKeyByValue(obj, valueToFind) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === valueToFind) {
        return key;
      }
    }
    return null;
  }
  const emailPrefix = type[0] + '@vyadom.com';

  useEffect(() => {
    const getData = async () => {
      try {
        if (yearData.length > 0) {
          let data = [];
          let combinedArray = [];
          [...yearData, ...eventYearData].forEach(item => {
            if (item.planPrice !== "N/A") {
              combinedArray.push(item)
            }
          })

          data = combinedArray
          const data1 = combinedArray
          if (type[0] && type[1] === undefined) {
            const filteredData = data1.filter((item) => item.user === emailPrefix);
            data = filteredData;
          } else if (type[1] && type[0] === '') {
           const filteredData = data1.filter(item => item.storeInvoice === findKeyByValue(storeNameList, type[1]));
            data = filteredData
          } else if (type[1] && type[0]) {
            const filteredData = data1.filter(item => item.storeInvoice === findKeyByValue(storeNameList, type[1]) && item.user === emailPrefix);
            data = filteredData
          }
          const groupedData = data.reduce((acc, item) => {
            const date = parseISO(item.tenderedDate);
            const month = format(date, 'MMM'); // Short month name (e.g., Jan, Feb)
            const year = format(date, 'yyyy'); // Full year

            const key = `${month} ${year}`;

            if (!acc[key]) {
              acc[key] = [];
            }

            acc[key].push(item);

            return acc;
          }, {});
          let monthlyCommission = [];

          for (let key in groupedData) {
            let computedCustomerList = cisCommisson(groupedData[key]);
            let finalComm = calculateStoreCommissionAfterCisCommission(computedCustomerList);
            let {
              addonCommission,
              hupCommission,
              hupMargin,
              postpaidCommission,
              prepaidCommission,
              deposit,
            } = finalComm;
            monthlyCommission[key.slice(0, 3)] = parseFloat(
              (
                addonCommission +
                hupCommission +
                hupMargin +
                postpaidCommission +
                prepaidCommission -
                deposit
              ).toFixed(2),
            );
          }

          const formattedData = Object.entries(monthlyCommission).map(([month, revenue]) => {
            return { month, Revenue: revenue };
          });

          setLineChartData(formattedData);
        }
      } catch (error) {
        return error;
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearData, type, eventYearData]);

  const formatCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD', // not adding CAD for readability reasons
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatTooltipValue = (value, name) => {
    name = formatCurrency.format(name);
    return `${value.replace('_', ' ')}: ${name}`;
  };

  const formatLegendValue = (value, name) => {
    const initialVal = 0;
    const totalVal = lineChartData.reduce((accumulator, currentValue) => {
      if (Object.keys(currentValue).includes(name.dataKey)) {
        return accumulator + currentValue[name.dataKey];
      }
      return accumulator;
    }, initialVal);

    return (
      <span className="custom-legend-item-text-group">
        <span className="custom-legend-item-text">{value.replace('_', ' ')}</span>-
        {/*<span className="custom-legend-item-text">${totalVal.toFixed(2)}</span>*/}
        <span className="custom-legend-item-text">{formatCurrency.format(totalVal)}</span>
      </span>
    );
  };

  const CustomTooltipContent = ({ payload }) => {
    if (!payload || !payload.length) return null;

    return (
      <div className="custom-recharts-tooltip">
        <p className="recharts-tooltip-label">{payload[0].payload?.month}</p>
        <ul className="recharts-tooltip-item-list">
          {payload?.map((payloadItem, index) => {
            return <li key={index}>{formatTooltipValue(payloadItem.name, payloadItem.value)}</li>;
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="block">
      <div className="block-head">
        <div className="block-title">
          <span>Revenue</span>
        </div>
      </div>
      <div className="line-chart">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={730}
            height={250}
            data={lineChartData}
            margin={{ top: 10, right: 5, left: 5, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0095FF" stopOpacity={0.2} />
                <stop offset="95%" stopColor="#0095FF" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#07E098" stopOpacity={0.2} />
                <stop offset="95%" stopColor="#07E098" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Tooltip content={<CustomTooltipContent />} />
            <Area
              type="monotone"
              dataKey="Revenue"
              stroke="#0095FF"
              fillOpacity={1}
              fill="url(#colorUv)"
              strokeWidth={2}
              dot={{
                stroke: '#0095FF',
                fill: '#0095FF',
              }}
            />
            <Legend formatter={formatLegendValue} />
            {/* <Area
                            type="monotone"
                            dataKey="Accessories"
                            stroke="#07E098"
                            fillOpacity={1}
                            fill="url(#colorPv)"
                            strokeWidth={2}
                            dot={{
                                stroke: "#07E098",
                                fill: "#07E098",
                            }}
                        /> */}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Revenues;

import React from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import './Button.scss';

const CustomButton = ({
  buttonType,
  onClick = () => {},
  btnLabel = '',
  className = '',
  pill = false,
  ...rest
}) => {
  let buttonClass = '';
  switch (buttonType) {
    case 'download':
      buttonClass = 'Download-btn btn';
      break;
    case 'search':
      buttonClass = 'Search-btn btn';
      break;
    case 'upload':
      buttonClass = 'Upload-btn btn';
      break;
    case 'delete':
      buttonClass = 'Delete-btn btn';
      break;
    default:
      buttonClass = 'default-btn btn';
  }

  return (
    <button onClick={onClick} className={`${buttonClass} ${className} ${pill ? 'pill-btn' : ''} `} {...rest}>
      {buttonType === 'upload' || buttonType === 'download' ? (
        <>
          {buttonType === 'upload' ? (
            <>
              {btnLabel || 'UPLOAD FILE'} <FileUploadIcon fontSize="small" />
            </>
          ) : (
            <>
              DOWNLOAD <DownloadIcon fontSize="small" />
            </>
          )}
        </>
      ) : (
        btnLabel || buttonType?.toUpperCase()
      )}
    </button>
  );
};

export default CustomButton;

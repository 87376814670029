import React, { useState, useRef } from 'react';
import './UploadHIModal.scss';
import toast, { Toaster } from 'react-hot-toast';
import { submitHomeInternet } from '../../helper/helper';

const UploadHIModal = ({ modalIsOpen, closeModal }) => {
  const [result, setResult] = useState('');
  const [rqresult, setRqresult] = useState('');
  const username = localStorage.getItem('username');
  const cisRef = useRef(null);
  const rqRef = useRef(null);

  if (!modalIsOpen) {
    return null;
  }

  async function readHomeInternetFile(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      const data = await reader.result;
      await loadPDF(data);
    };
    async function loadPDF(data) {
      const [{ getDocument }] = await Promise.all([
        import('pdfjs-dist'),
        import('pdfjs-dist/build/pdf.worker.entry'),
      ]);
      const pdf = await getDocument(data).promise;
      await pdfToText(pdf);
    }
    async function pdfToText(pdf) {
      let result = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        result += await getPageText(pdf, i);
      }
      setResult(result);
    }
    async function getPageText(pdf, pageNum = 1) {
      const page = await pdf.getPage(pageNum);

      const textContent = await page.getTextContent();

      const items = textContent.items.filter((item) => item.str.trim());
      const finalData = items.map(({ str }) => str).join(' ');
      return finalData;
    }
  }

  async function readHomeInternetRQFile(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      const data = await reader.result;
      await loadPDF(data);
    };
    async function loadPDF(data) {
      const [{ getDocument }] = await Promise.all([
        import('pdfjs-dist'),
        import('pdfjs-dist/build/pdf.worker.entry'),
      ]);
      const pdf = await getDocument(data).promise;
      await pdfToText(pdf);
    }
    async function pdfToText(pdf) {
      let result = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        result += await getPageText(pdf, i);
      }
      setRqresult(result);
    }
    async function getPageText(pdf, pageNum = 1) {
      const page = await pdf.getPage(pageNum);
      const textContent = await page.getTextContent();
      const items = textContent.items.filter((item) => item.str.trim());
      const finalData = items.map(({ str }) => str).join(' ');
      return finalData;
    }
  }

  function saleInvoiceExtraction(rqresult) {
    const pattern = /Invoice : ([A-Z0-9]+)/;

    // Use the RegExp.exec() method to find the match
    const match = pattern.exec(rqresult);

    // Extract the desired text (if a match is found)
    let extractedText = '';
    if (match && match[1]) {
      extractedText = match[1];
      // Output: VYAD2IN134752
    } 
    return extractedText;
  }

  function dateConversion(date) {
    // Parse the input date string using the Date constructor
    const parsedDate = new Date(date);

    // Format the parsed date in the "YYYY-MM-DD" format
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(parsedDate.getDate()).padStart(2, '0');

    // Create the formatted date string
    const formattedDateStr = `${year}-${month}-${day}`;
    return formattedDateStr;
  }

  function extractDetails(text) {
    // Regex to extract the plan name without including "Unlimited"
    const planNameRegex = /(Internet\s+\d+(\s+\w+)?)(?=\s+Unlimited)/;
    const planNameMatch = text.match(planNameRegex);
    const planName = planNameMatch ? planNameMatch[0] : 'No match found for plan name';

    // Regex to extract dollar values
    const dollarValuesRegex = /\$\d+\.\d+/g;
    const dollarValues = text.match(dollarValuesRegex);

    let regularPlan = 'No match found for regular plan';
    let discount = 'No match found for discount';
    let actualCost = 'No match found for actual cost';

    if (dollarValues && dollarValues.length >= 3) {
      regularPlan = dollarValues[0];
      discount = dollarValues[1];
      actualCost = dollarValues[2];
    }

    return {
      planName,
      regularPlan,
      discount,
      actualCost,
    };
  }
  function extractDetailsTV(text) {

    // Regex to extract dollar values
    const dollarValuesRegex = /\$\d+\.\d+/g;
    const dollarValues = text.match(dollarValuesRegex);

    let regularPlan = 'No match found for regular plan';
    let discount = 'No match found for discount';
    let actualCost = 'No match found for actual cost';

    if (dollarValues && dollarValues.length >= 3) {
      regularPlan = dollarValues[0];
      discount = dollarValues[1];
      actualCost = dollarValues[2];
    }

    return {
      regularPlan,
      discount,
      actualCost,
    };
  }

  function extractNames(fullName) {
    const names = fullName.split(' ');
    return {
      firstName: names[0],
      lastName: names[names.length - 1],
    };
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const patterns = {
      customerName: /Between:\s*(.*?)\s*\d/,
      phoneNumber: /\+1-?(\d{3})-?(\d{3})-?(\d{4})/,
      clientNumber: /Client #:\s(\d+)/,
    };
    const regex = /\bInternet\b(?:\s+\S+){0,10}/g;
    const regexTV = /\bTV\b(?:\s+\S+){0,3}/g;
    const result1 = extractDetails(result?.match(regex)[11]);
    const resultTV = extractDetailsTV(result?.match(regexTV)[0]);

    let customerDetails = {
      customerName: result.match(patterns.customerName)
        ? result.match(patterns.customerName)[1].trim()
        : null,
      phoneNumber: result.match(patterns.phoneNumber)
        ? result.match(patterns.phoneNumber)[1] +
          result.match(patterns.phoneNumber)[2] +
          result.match(patterns.phoneNumber)[3]
        : null,
      clientNumber: result.match(patterns.clientNumber)
        ? result.match(patterns.clientNumber)[1]
        : null,
      internetPlanName: result1.planName,
      monthlyCost: result1.actualCost,
      regularMonthlyPayment: result1.regularPlan,
      discount: result1.discount,
      monthlyCostTV: resultTV.actualCost || '$00.00',
      regularMonthlyPaymentTV: resultTV.regularPlan || '$00.00',
      discountTV: resultTV.discount || '$00.00',
    };
    const timePattern = /\b(0?[1-9]|1[0-2]):[0-5][0-9]\s?[APap][Mm]\b/g;
    const times = rqresult.match(timePattern);
    let values = {};
    values = {
      storeInvoice: saleInvoiceExtraction(rqresult).slice(0, 7),
      saleInvoice: saleInvoiceExtraction(rqresult),
      tenderedDate: dateConversion(/\d{2}-[A-Za-z]{3}-\d{4}/.exec(rqresult)[0]),
      time: times[0],
      customerName: /(?:Customer Information|Bill To:) (\w+\s+\w+)/
        .exec(rqresult)[1]
        .toLocaleLowerCase(),
    };
    const { firstName: firstName1, lastName: lastName1 } = extractNames(
      customerDetails.customerName,
    );
    const firstNameExists = values.customerName.includes(firstName1.toLocaleLowerCase());
    const lastNameExists = values.customerName.includes(lastName1.toLocaleLowerCase());
    customerDetails = {
      ...customerDetails,
      storeInvoice: values.storeInvoice,
      saleInvoice: values.saleInvoice,
      tenderedDate: values.tenderedDate,
      time: values.time,
      user: username,
    };
    const {
      customerName,
      phoneNumber,
      clientNumber,
      internetPlanName,
      monthlyCost,
      regularMonthlyPayment,
      discount,
      monthlyCostTV,
      regularMonthlyPaymentTV,
      discountTV,
      storeInvoice,
      saleInvoice,
      tenderedDate,
      time,
      user,
    } = customerDetails;
    if (firstNameExists || lastNameExists) {
      let promise = submitHomeInternet({
        customerName,
        phoneNumber,
        clientNumber,
        internetPlanName,
        monthlyCost,
        regularMonthlyPayment,
        discount,
        monthlyCostTV,
        regularMonthlyPaymentTV,
        discountTV,
        storeInvoice,
        saleInvoice,
        tenderedDate,
        time,
        user,
      });
      promise
        .then((res) => {})
        .catch((err) => {
          toast.error(err.response.data.error.error);
          cisRef.current.value = null;
          rqRef.current.value = null;
        });

      toast.promise(promise, {
        loading: 'Uploading...',
        success: <b>Uploaded Successfully...!</b>,
        error: <b>Not uploaded</b>,
      });
      promise.then(() => {
        cisRef.current.value = null;
        rqRef.current.value = null;
        closeModal();
      });
    } else {
      toast.error(`Please upload the files of same customer.`);
    }
  };
  return (
    <div className="hi-modal">
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <div className="modal-content">
        <div className="close-button">
          <button onClick={closeModal}>✖</button>
        </div>

        <div className="top">
          {/* for Home internet upload */}
          <div className="block">
            <div className="top-block">
              <h4>CIS</h4>
              <span>Select one file at a time.</span>
            </div>

            <div className="bottom-block">
              <input
                onChange={readHomeInternetFile}
                ref={cisRef}
                accept=".pdf"
                multiple={false}
                type="file"
                id="cis"
                name="cis"
              />
            </div>
          </div>

          {/* for RQ4 upload */}
          <div className="block">
            <div className="top-block">
              <h4 className="">RQ4</h4>
              <span className="">Select one file at a time.</span>
            </div>

            <div className="bottom-block">
              <input
                onChange={readHomeInternetRQFile}
                ref={rqRef}
                accept=".pdf"
                multiple={false}
                type="file"
                id="rq4"
                name="rq4"
              />
            </div>
          </div>
        </div>
        <div className="bottom">
          <button onClick={onSubmit} className="sub-btn" type="submit">
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadHIModal;

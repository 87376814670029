import React, { useState } from 'react';
import './CISViewModal.scss';
import { Toaster } from 'react-hot-toast';
import { DataGrid } from '@mui/x-data-grid';
import { CSVLink } from 'react-csv';
import { deleteEventCisDataRow } from '../../helper/helper';

const CISViewModal = ({ modalIsOpen, closeModal, eventinfoforCis, cisdata }) => {
  const [arrIds, setArrIds] = useState([]);
  if (!modalIsOpen) {
    return null;
  }
  const combinedString = `${eventinfoforCis.id} ${eventinfoforCis.date}`;
  const result = cisdata[combinedString];

  const columns = [
    /* { field: '_id', headerName: 'Id', width: 50 }, */
    { field: 'saleInvoice', headerName: 'Sales Invoice', width: 130 },
    { field: 'tenderedDate', headerName: 'Tendered Date', width: 130 },
    { field: 'customerName', headerName: 'Customer Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'accountNum', headerName: 'Account Number', width: 200 },
    { field: 'deposit', headerName: 'Deposit', width: 100 },
    { field: 'retailPrice', headerName: 'Retail Price', width: 100 },
    { field: 'phoneProtectionPlan', headerName: 'PPP', width: 100 },
    { field: 'activatedPromotion', headerName: 'Promo', width: 400 },
    { field: 'phoneNum', headerName: 'Phone Number', width: 150 },
    { field: 'tempPhoneNum', headerName: 'Temporary Number', width: 150 },
    { field: 'simNumber', headerName: 'SIM Number', width: 200 },
    { field: 'imei', headerName: 'IMEI', width: 200 },
    { field: 'amountYouPaid', headerName: 'Amount Paid', width: 150 },
    { field: 'connectionType', headerName: 'Connection Type', width: 100 },
    { field: 'activationDate', headerName: 'Activation Date', width: 200 },
    { field: 'phoneMake', headerName: 'Phone Make', width: 400 },
    { field: 'planName', headerName: 'Plan Name', width: 400 },
    { field: 'planPrice', headerName: 'Plan Price', width: 100 },
    { field: 'myBalanceOwing', headerName: 'Balance Owing', width: 100 },
    { field: 'oneTimeCharges', headerName: 'One Time Charge', width: 100 },
    { field: 'addons', headerName: 'Add-Ons', width: 700 },
    { field: 'autoPayPre', headerName: 'Auto Pay Pre', width: 150 },
    { field: 'autoPayPost', headerName: 'Auto Pay Post', width: 150 },
    { field: 'specialistId', headerName: 'Specialist ID', width: 100 },
    { field: 'storeId', headerName: 'Store ID', width: 100 },
    { field: 'user', headerName: 'User', width: 300 },
    { field: 'eventName', headerName: 'Event Name', width: 300 },
    { field: 'eventDate', headerName: 'Event Date', width: 300 },
  ];

  const rows = result?.map((row) => ({
    _id: row._id,
    saleInvoice: row.saleInvoice,
    tenderedDate: row.tenderedDate,
    customerName: row.customerName,
    email: row.email,
    accountNum: row.accountNum,
    deposit: row.deposit,
    retailPrice: row.retailPrice,
    phoneProtectionPlan: row.phoneProtectionPlan,
    activatedPromotion: row.activatedPromotion,
    phoneNum: row.phoneNum,
    tempPhoneNum: row.tempPhoneNum,
    simNumber: row.simNumber,
    imei: row.imei,
    amountYouPaid: row.amountYouPaid,
    connectionType: row.connectionType,
    activationDate: row.activationDate,
    phoneMake: row.phoneMake,
    planName: row.planName,
    planPrice: row.planPrice,
    myBalanceOwing: row.myBalanceOwing,
    oneTimeCharges: row.oneTimeCharges,
    addons: row.addons,
    autoPayPre: row.autoPayPre,
    autoPayPost: row.autoPayPost,
    specialistId: row.specialistId,
    storeId: row.storeId,
    user: row.user,
    eventName: row.eventName,
    eventDate: row.eventDate,
  }));

  async function deleteCisData(id) {
    let saleInvoice = null;
    result.forEach((cis) => {
      if (cis._id === id[0]) {
        saleInvoice = cis.saleInvoice;
      }
    });
    if (window.confirm(`Are you sure you want to delete ${id}'s data?`)) {
      let data = await deleteEventCisDataRow({ id, saleInvoice });
      if (data) {
        alert('data deleted successfully');
      }
    }
  }

  return (
    <div className="cvm-modal">
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <div className="modal-content">
        <div className="close-button">
          <button onClick={closeModal}>✖</button>
        </div>
        <div className="tablecontainer">
          <div className="bottomtable" style={{ height: 430, width: '100%' }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={rows ? rows : []}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(ids) => {
                setArrIds(ids);
              }}
            />
          </div>
          <div className="topbuttons">
            <button onClick={() => deleteCisData(arrIds)} className="edit-btn" type="submit">
              Delete
            </button>
            <CSVLink data={result ? result : ''} className="edit-btn">
              Download
            </CSVLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CISViewModal;

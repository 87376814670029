import React from 'react';
import './ResetPassword.scss';
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import { resetPasswordValidation } from '../../helper/validate';
import { resetPassword, updateFirstLoginStatus } from '../../helper/helper';
import { useAuthStore } from '../../store/store';
import { useNavigate, Navigate } from 'react-router-dom';
import useFetch from '../../hooks/fetch.hook';

const ResetPassword = () => {
  const { username } = useAuthStore((state) => state.auth);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [{ isLoading, apiData, status, serverError }] = useFetch('createResetSession');

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_pwd: '',
    },
    validate: resetPasswordValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let resetPromise = resetPassword({ username, password: values.password });

      toast.promise(resetPromise, {
        loading: 'Updating...',
        success: <b>Reset Successfully...!</b>,
        error: <b>Could not Reset!</b>,
      });

      resetPromise.then(() => {
        let firstLoginPromise = updateFirstLoginStatus({ username });
        toast.promise(firstLoginPromise, {
          loading: 'Updating...',
          success: <b>Updated Successfully...!</b>,
          error: <b>Could not Updated!</b>,
        });

        firstLoginPromise.then(function () {
          navigate('/');
        });
      });
    },
  });

  if (isLoading) return <h1 className="text-2xl font-bold">isLoading</h1>;
  if (serverError) return <h1 className="text-xl text-red-500">{serverError.message}</h1>;
  if (status && status !== 201) return <Navigate to={'/password'} replace={true}></Navigate>;
  return (
    <div className="reset-container">
      <Toaster position="top-center" reverseOrder={false}></Toaster>

      <div className="reset-block">
        <div className="main">
          <div className="top">
            <h4 className="top-h">Reset Password</h4>
          </div>

          <form className="bottom" onSubmit={formik.handleSubmit}>
            <div className="bottom-div">
              <input
                {...formik.getFieldProps('password')}
                className="bottom-input"
                type="text"
                placeholder="New Password"
              />
              <input
                {...formik.getFieldProps('confirm_pwd')}
                className="bottom-input"
                type="text"
                placeholder="Repeat Password"
              />
              <button className="bottom-button" type="submit">
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React from 'react';
import { cisCommisson } from './ReusableFunctions';

const StoreCommission = ({ cisdata, targetRow, data }) => {
  let computedCustomerList = cisCommisson(cisdata);
  let spiff = 0;

  cisdata &&
    cisdata?.forEach((item) => {
      const phoneMakeIncludesTCL = item.phoneMake.includes('TCL');
      const tenderedDate = new Date(item.tenderedDate);
      const startDate = new Date('2024-06-17');
      const endDate = new Date('2024-07-15');

      if (phoneMakeIncludesTCL && tenderedDate >= startDate && tenderedDate <= endDate) {
        spiff += 20;
      }
    });

  let prepaidCommission = 0;
  let postpaidCommission = 0;
  let hupCommission = 0;
  let addonCommission = 0;
  let hupMargin = 0;
  let deposit = 0;
  let tabHandset = 0;
  let spiffs = 0;

  for (let array = 0; array < computedCustomerList.length; array++) {
    if (computedCustomerList[array].type === 'Prepaid') {
      prepaidCommission += computedCustomerList[array].planCommission;
    } else if (
      computedCustomerList[array].type === 'Postpaid with device' ||
      computedCustomerList[array].type === 'Postpaid without device'
    ) {
      postpaidCommission += computedCustomerList[array].planCommission;
    } else if (computedCustomerList[array].type === 'Hup') {
      hupCommission += computedCustomerList[array].planCommission;
    }

    if (
      computedCustomerList[array].retailHandset * 0.05 < 20 &&
      computedCustomerList[array].retailHandset !== undefined
    ) {
      hupMargin += 20;
    } else if (
      computedCustomerList[array].retailHandset * 0.05 > 100 &&
      computedCustomerList[array].retailHandset !== undefined
    ) {
      hupMargin += 100;
    } else if (computedCustomerList[array].retailHandset !== undefined) {
      hupMargin += computedCustomerList[array].retailHandset * 0.05;
    }

    if (computedCustomerList[array].deposit !== 'Not Required') {
      deposit += parseFloat(computedCustomerList[array].deposit);
    }
    if (computedCustomerList[array].addonCommission !== undefined) {
      addonCommission += computedCustomerList[array].addonCommission;
    }
    /* if((computedCustomerList[array].addonCommission === 0 || computedCustomerList[array].addonCommission === "") && computedCustomerList[array].ppp === 'Accepted' ) {
            addonCommission += 27
        } */
    if (computedCustomerList[array].spiff !== undefined) {
      spiffs += computedCustomerList[array].spiff;
    }
    if (computedCustomerList[array].retailHandset !== undefined) {
      tabHandset += computedCustomerList[array].retailHandset;
    }
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">Postpaid</span>
        <span className="counter">
          $
          {postpaidCommission?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || 0}
        </span>
      </div>

      <div className="left">
        <span className="title">Prepaid</span>
        <span className="counter">
          $
          {prepaidCommission?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || 0}
        </span>
      </div>

      <div className="left">
        <span className="title">HUP</span>
        <span className="counter">
          $
          {hupCommission?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || 0}
        </span>
      </div>

      <div className="left">
        <span className="title">HWM</span>
        <span className="counter">
          $
          {hupMargin?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || 0}
        </span>
      </div>

      <div className="left">
        <span className="title">Deposit</span>
        <span className="counter">
          ($
          {deposit?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || 0}
          )
        </span>
      </div>

      <div className="left">
        <span className="title">Addons</span>
        <span className="counter">
          $
          {addonCommission?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || 0}
        </span>
      </div>
      <div className="left">
        <span className="title">Spiff</span>
        <span className="counter">
          $
          {spiff?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ||
            0}
        </span>
      </div>

      <div className="left">
        <span className="title">Total</span>
        <span className="counter">
          $
          {(
            postpaidCommission +
            prepaidCommission +
            hupCommission +
            addonCommission +
            spiffs +
            hupMargin -
            deposit
          )?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </span>
      </div>
      <br />
      <div className="left">
        <span className="title">Tab Subsidy</span>
        <span className="counter">
          $
          {tabHandset?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || 0}
        </span>
      </div>
    </div>
  );
};

export default StoreCommission;

import React from 'react';
import './Manager.scss';
import Dashboard from '../../components/Dashboard/Dashboard';

const Manager = () => {
  return (
    <div className="manager">
          <Dashboard />
    </div>
  );
};

export default Manager;

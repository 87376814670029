/* import { format } from 'date-fns'; */

export const removeDuplicatesByInvoice = (array, invoiceKey = 'saleInvoice') => {
  const seenInvoices = new Set();
  return array.filter((item) => {
    if (!item[invoiceKey]) {
      return true; // Keep objects without the invoice key
    }
    const duplicate = seenInvoices.has(item[invoiceKey]);
    seenInvoices.add(item[invoiceKey]);
    return !duplicate;
  });
};

export function addCount(array, cisdata) {
  let demo = [...cisdata[array].addons.split(',')];
  let addonCount = 0;

  demo.forEach((item) => {
    let exist = addonList.find((addon) => addon === item);
    if (exist) {
      addonCount++;
    }
  });
  return addonCount;
}

export function hasCommonWord(s1, s2) {
  if (s1 !== undefined && s2 !== undefined) {
    const lowerS1 = s1.toLowerCase();
    const lowerS2 = s2.toLowerCase();

    const words1 = lowerS1.split(' ');
    const words2 = lowerS2.split(' ');

    const found = words2.some((word) => words1.includes(word));
    return found;
  } else {
    return false;
  }
}

/* const newStoreList = storeList.map(store => store.slice(2,)).sort((a, b) => {
    return a - b
  }) */

export function employeeCommissionCalculate(cisdata, targetRow, percentage = 1) {
  let prepaidCommission = 0;
  let postpaidCommission = 0;
  let hupCommission = 0;
  let autopayCommission = 0;
  let addonCommission = 0;
  for (let array = 0; array < cisdata?.length; array++) {
    if (cisdata[array].connectionType === 'Prepaid') {
      if (
        cisdata[array].planName === 'By The Year 4G Nationwide Talk Text' ||
        cisdata[array].planName === 'By The Year 4G 12GB + Nationwide Talk and Text' ||
        cisdata[array].planName === 'By The Year 12GB + Nationwide Talk Text' ||
        cisdata[array].planName === 'By The Year 4G Nationwide 12GB' ||
        cisdata[array].planName === 'By The Year 4G Nationwide Talk' ||
        cisdata[array].planName === 'By The Year 4G Nationwide 20GB' ||
        cisdata[array].planName === 'By The Year 4G Nationwide 15GB' ||
        cisdata[array].planName === 'By The Year Nationwide 30GB' ||
        cisdata[array].planName === 'By The Year 4G Nationwide 30GB' ||
        cisdata[array].planName === 'By The Year 4G Talk'
      ) {
        prepaidCommission += 1;
      } else {
        prepaidCommission += 2;
      }
    }
    if (cisdata[array].connectionType === 'Postpaid' && cisdata[array].simNumber === 'N/A') {
      hupCommission += 5;
    }
    if (
      cisdata[array].autoPayPost === 'successfully for Pre - Authorized Payments' ||
      cisdata[array].autoPayPre === 'successfully for Pre - Authorized Payments'
    ) {
      autopayCommission += 0.5;
    }
    if (
      cisdata[array].phoneProtectionPlan === 'Accepted' &&
      cisdata[array].connectionType === 'Prepaid'
    ) {
      addonCommission += 3;
    } else if (addCount(array, cisdata) && cisdata[array].connectionType === 'Prepaid') {
      addonCommission += 1;
    }
    if (
      cisdata[array].phoneProtectionPlan === 'Accepted' &&
      cisdata[array].connectionType === 'Postpaid'
    ) {
      addonCommission += 3;
    } else if (addCount(array, cisdata) && cisdata[array].connectionType === 'Postpaid') {
      addonCommission += 3;
    }
  }

  if (targetRow?.hasOwnProperty('postpaidtarget')) {
    const plans = [];

    for (let array = 0; array < cisdata?.length; array++) {
      if (
        cisdata[array].connectionType === 'Postpaid' &&
        cisdata[array].retailPrice !== 'N/A' &&
        cisdata[array].simNumber !== 'N/A'
      ) {
        if (parseInt(cisdata[array].planPrice.slice(1)) <= 45) {
          postpaidCommission += 9;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)) + 'Device');
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 55) {
          postpaidCommission += 12;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)) + 'Device');
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 65) {
          postpaidCommission += 15;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)) + 'Device');
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 75) {
          postpaidCommission += 17;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)) + 'Device');
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 85) {
          postpaidCommission += 20;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)) + 'Device');
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 95) {
          postpaidCommission += 25;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)) + 'Device');
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 120) {
          postpaidCommission += 30;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)) + 'Device');
        }
      } else if (
        cisdata[array].connectionType === 'Postpaid' &&
        cisdata[array].retailPrice === 'N/A' &&
        cisdata[array].simNumber !== 'N/A'
      ) {
        if (parseInt(cisdata[array].planPrice.slice(1)) <= 45) {
          postpaidCommission += 4;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)));
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 55) {
          postpaidCommission += 7;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)));
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 65) {
          postpaidCommission += 10;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)));
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 75) {
          postpaidCommission += 12;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)));
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 85) {
          postpaidCommission += 15;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)));
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 95) {
          postpaidCommission += 20;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)));
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 120) {
          postpaidCommission += 25;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)));
        }
      }
    }
    const integer = [];

    const remainingCommission = plans.slice(targetRow?.postpaidtarget);
    remainingCommission.forEach((item) => {
      if (typeof item === 'string') {
        integer.push(parseInt(item.slice(0, 2)));
        if (parseInt(item.slice(0, 2)) <= 45) {
          postpaidCommission += 1;
        } else if (parseInt(item.slice(0, 2)) <= 55) {
          postpaidCommission += 1;
        } else if (parseInt(item.slice(0, 2)) <= 65) {
          postpaidCommission += 1;
        } else if (parseInt(item.slice(0, 2)) <= 75) {
          postpaidCommission += 1;
        } else if (parseInt(item.slice(0, 2)) <= 85) {
          postpaidCommission += 1;
        } else if (parseInt(item.slice(0, 2)) <= 95) {
          postpaidCommission += 1;
        } else if (parseInt(item.slice(0, 2)) <= 120) {
          postpaidCommission += 1;
        }
      } else {
        if (item <= 45) {
          postpaidCommission += 1;
        } else if (item <= 55) {
          postpaidCommission += 1;
        } else if (item <= 65) {
          postpaidCommission += 1;
        } else if (item <= 75) {
          postpaidCommission += 1;
        } else if (item <= 85) {
          postpaidCommission += 1;
        } else if (item <= 95) {
          postpaidCommission += 1;
        } else if (item <= 120) {
          postpaidCommission += 1;
        }
      }
    });
  } else {
    for (let array = 0; array < cisdata?.length; array++) {
      if (
        cisdata[array].connectionType === 'Postpaid' &&
        cisdata[array].retailPrice !== 'N/A' &&
        cisdata[array].simNumber !== 'N/A'
      ) {
        if (parseInt(cisdata[array].planPrice.slice(1)) <= 45) {
          postpaidCommission += 9;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 55) {
          postpaidCommission += 12;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 65) {
          postpaidCommission += 15;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 75) {
          postpaidCommission += 17;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 85) {
          postpaidCommission += 20;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 95) {
          postpaidCommission += 25;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 120) {
          postpaidCommission += 30;
        }
      } else if (
        cisdata[array].connectionType === 'Postpaid' &&
        cisdata[array].retailPrice === 'N/A' &&
        cisdata[array].simNumber !== 'N/A'
      ) {
        if (parseInt(cisdata[array].planPrice.slice(1)) <= 45) {
          postpaidCommission += 4;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 55) {
          postpaidCommission += 7;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 65) {
          postpaidCommission += 10;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 75) {
          postpaidCommission += 12;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 85) {
          postpaidCommission += 15;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 95) {
          postpaidCommission += 20;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 120) {
          postpaidCommission += 25;
        }
      }
    }
  }
  return {
    post: postpaidCommission * percentage,
    pre: prepaidCommission * percentage,
    addon: addonCommission * percentage,
    hup: hupCommission * percentage,
    ap: autopayCommission * percentage,
  };
}

export function employeeCommissionCalculate2(cisdata, targetRow, percentage = 1) {
  let prepaidCommission = 0;
  let postpaidCommission = 0;
  let hupCommission = 0;
  let autopayCommission = 0;
  let addonCommission = 0;
  for (let array = 0; array < cisdata?.length; array++) {
    if (cisdata[array].connectionType === 'Prepaid') {
      if (
        cisdata[array].planName === 'By The Year 4G Nationwide Talk Text' ||
        cisdata[array].planName === 'By The Year 4G 12GB + Nationwide Talk and Text' ||
        cisdata[array].planName === 'By The Year 12GB + Nationwide Talk Text' ||
        cisdata[array].planName === 'By The Year 4G Nationwide 12GB' ||
        cisdata[array].planName === 'By The Year 4G Nationwide Talk' ||
        cisdata[array].planName === 'By The Year 4G Nationwide 20GB' ||
        cisdata[array].planName === 'By The Year 4G Nationwide 15GB' ||
        cisdata[array].planName === 'By The Year Nationwide 30GB' ||
        cisdata[array].planName === 'By The Year 4G Nationwide 30GB' ||
        cisdata[array].planName === 'By The Year 4G Talk'
      ) {
        prepaidCommission += 1;
      } else {
        prepaidCommission += 2;
      }
    }
    if (cisdata[array].connectionType === 'Postpaid' && cisdata[array].simNumber === 'N/A') {
      hupCommission += 7;
    }
    if (
      cisdata[array].autoPayPost === 'successfully for Pre - Authorized Payments' ||
      cisdata[array].autoPayPre === 'successfully for Pre - Authorized Payments'
    ) {
      autopayCommission += 0.5;
    }
    if (
      cisdata[array].phoneProtectionPlan === 'Accepted' &&
      cisdata[array].connectionType === 'Prepaid'
    ) {
      addonCommission += 3;
    } else if (addCount(array, cisdata) && cisdata[array].connectionType === 'Prepaid') {
      addonCommission += 1;
    }
    if (
      cisdata[array].phoneProtectionPlan === 'Accepted' &&
      cisdata[array].connectionType === 'Postpaid'
    ) {
      addonCommission += 3;
    } else if (addCount(array, cisdata) && cisdata[array].connectionType === 'Postpaid') {
      addonCommission += 3;
    }
  }

  if (targetRow?.hasOwnProperty('postpaidtarget')) {
    const plans = [];

    for (let array = 0; array < cisdata?.length; array++) {
      if (
        cisdata[array].connectionType === 'Postpaid' &&
        cisdata[array].retailPrice !== 'N/A' &&
        cisdata[array].simNumber !== 'N/A'
      ) {
        if (parseInt(cisdata[array].planPrice.slice(1)) <= 45) {
          postpaidCommission += 12;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)) + 'Device');
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 55) {
          postpaidCommission += 14;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)) + 'Device');
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 65) {
          postpaidCommission += 16;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)) + 'Device');
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 75) {
          postpaidCommission += 18;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)) + 'Device');
        }
      } else if (
        cisdata[array].connectionType === 'Postpaid' &&
        cisdata[array].retailPrice === 'N/A' &&
        cisdata[array].simNumber !== 'N/A'
      ) {
        if (parseInt(cisdata[array].planPrice.slice(1)) <= 45) {
          postpaidCommission += 7;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)));
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 55) {
          postpaidCommission += 9;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)));
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 65) {
          postpaidCommission += 11;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)));
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 75) {
          postpaidCommission += 13;
          plans.push(parseInt(cisdata[array].planPrice.slice(1)));
        }
      }
    }
    const integer = [];

    const remainingCommission = plans.slice(targetRow?.postpaidtarget);

    remainingCommission.forEach((item) => {
      if (typeof item === 'string') {
        integer.push(parseInt(item.slice(0, 2)));
        if (parseInt(item.slice(0, 2)) <= 45) {
          postpaidCommission += 1;
        } else if (parseInt(item.slice(0, 2)) <= 55) {
          postpaidCommission += 1;
        } else if (parseInt(item.slice(0, 2)) <= 65) {
          postpaidCommission += 1;
        } else if (parseInt(item.slice(0, 2)) <= 75) {
          postpaidCommission += 1;
        }
      } else {
        if (item <= 45) {
          postpaidCommission += 1;
        } else if (item <= 55) {
          postpaidCommission += 1;
        } else if (item <= 65) {
          postpaidCommission += 1;
        } else if (item <= 75) {
          postpaidCommission += 1;
        }
      }
    });
  } else {
    for (let array = 0; array < cisdata?.length; array++) {
      if (
        cisdata[array].connectionType === 'Postpaid' &&
        cisdata[array].retailPrice !== 'N/A' &&
        cisdata[array].simNumber !== 'N/A'
      ) {
        if (parseInt(cisdata[array].planPrice.slice(1)) <= 45) {
          postpaidCommission += 12;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 55) {
          postpaidCommission += 14;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 65) {
          postpaidCommission += 16;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 75) {
          postpaidCommission += 18;
        }
      } else if (
        cisdata[array].connectionType === 'Postpaid' &&
        cisdata[array].retailPrice === 'N/A' &&
        cisdata[array].simNumber !== 'N/A'
      ) {
        if (parseInt(cisdata[array].planPrice.slice(1)) <= 45) {
          postpaidCommission += 7;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 55) {
          postpaidCommission += 9;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 65) {
          postpaidCommission += 11;
        } else if (parseInt(cisdata[array].planPrice.slice(1)) <= 75) {
          postpaidCommission += 13;
        }
      }
    }
  }
  return {
    post: postpaidCommission * percentage,
    pre: prepaidCommission * percentage,
    addon: addonCommission * percentage,
    hup: hupCommission * percentage,
    ap: autopayCommission * percentage,
  };
}

export const storeNameList = {
  OSHWTIN: 'Lake Vista Plaza',
  LAKESIN: 'Lakeshore & Islington',
  VYAD2IN: 'Center Mall Barton',
  TCPNFIN: 'Town Center Plaza',
  VYACBIN: 'Cedarbrae Mall',
  BARRIIN: 'Barrie',
  VYAREIN: 'Rexdale & Islington',
  DTWICIN: 'Briarfield Shopping Centre',
  DAN01IN: 'Danforth & Woodbine',
  VYOSHIN: 'Big Bay',
  VYAMLIN: 'Markham & Lawrence',
  VYAWLIN: 'Weston & Lawrence',
  DUNBLIN: 'Oriental Centre',
};
export async function readCisFile(event, setResult) {
  let file = event.target.files[0];
  let reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = async () => {
    const data = await reader.result;
    await loadPDF(data);
  };

  async function loadPDF(data) {
    const [{ getDocument }] = await Promise.all([
      import('pdfjs-dist'),
      import('pdfjs-dist/build/pdf.worker.entry'),
    ]);
    const pdf = await getDocument(data).promise;

    /* const metaData = await pdf.getMetadata();
        setIsSignaturesPresent(metaData.info.IsSignaturesPresent); */

    await pdfToText(pdf);
  }

  async function pdfToText(pdf) {
    let result = '';
    for (let i = 1; i <= pdf.numPages; i++) {
      result += await getPageText(pdf, i);
    }
    setResult(result);
  }
  async function getPageText(pdf, pageNum = 1) {
    const page = await pdf.getPage(pageNum);

    const textContent = await page.getTextContent();

    const items = textContent.items.filter((item) => item.str.trim());
    const finalData = items.map(({ str }) => str).join(' ');
    return finalData;
  }
}

export function getValues(keysString) {
  if (keysString?.length > 1 && keysString !== 'all') {
    let keys = keysString.split(',').map(function (key) {
      return key.trim(); // Remove leading/trailing whitespace if any
    });

    let values = keys
      .map(function (key) {
        return storeNameList[key] || ''; // Use an empty string if key doesn't exist
      })
      .join(',');
    return values;
  } else if (keysString === 'all') {
    return 'All Stores';
  } else {
    var values = storeNameList[keysString];
    return values;
  }
}

export function addSameNumber(input) {
  let count = input.reduce((prev, current) => {
    let count = prev.get(current.phonenumber) || 0;
    prev.set(current.phonenumber, current.commission + count);
    return prev;
  }, new Map());

  let reducedObjArray = [...count].map(([phonenumber, commission]) => {
    return { phonenumber, commission };
  });
  return reducedObjArray;
}

function promoFinder(item1) {
  const searchString = 'Additional 3rd+ Line $10 off';

  const isFound = item1.includes(searchString);
  const variable = isFound ? 10 : 0;

  return variable;
}

const addonList = [
  '1500 ILD Minutes at $5 per mo. to 6 Destinations $5.00',
  'Unlimited Long Distance - USA $5.00',
  'Big Gig World Saver - Freedom Nationwide $5.00',
  'World Traveller $8.00',
  '1GB Freedom Nationwide Add-On(FS) $15.00',
  '2GB Freedom Nationwide Add-On(FS) $30.00',
  '3GB Freedom Nationwide Add-On(FS) $45.00',
  'ILD - India(250 minutes) $10.00',
  'ILD - India(1000 Minutes) $15.00',
  'Visual Voicemail $4.00',
  'ILD - China or Hong Kong (250 minutes) $10.00',
  'ILD - China or Hong Kong (1000 minutes) $15.00',
  'Phone Protection Plan 9 $9.00',
  'Phone Protection Plan $9.00',
  'Voicemail+ $5.00',
];

/* const convertDateFormat = (originalDate) => {
    const dateObject = new Date(`${originalDate}T00:00:00`);

    // Use date-fns to format the date
    const newDateFormat = format(dateObject, 'MM/dd/yyyy');
    const dateComponents = newDateFormat.split('/');

    // Check if the day part has a leading zero
    if (dateComponents[1].charAt(0) === '0') {
        // Remove the leading zero from the day part
        dateComponents[1] = dateComponents[1].substring(1);
    }

    // Join the components back into a string with '/'
    const outputDate = dateComponents.join('/');

    return outputDate;
}; */

export function extractPhoneNumber(inputString) {
  // Define a regular expression to match phone numbers in the format XXX-XXX-XXXX
  const phoneNumberRegex = /\b(\d{3}-\d{3}-\d{4})\b/;

  // Use the match method to find the phone number in the input string
  const match = inputString.match(phoneNumberRegex);

  // Check if a match is found
  if (match) {
    // Extracted phone number is in the first capturing group (index 1)

    return match[1];
  } else {
    // Return null or handle the case where no phone number is found
    return null;
  }
}

export function cisCommisson(event) {
  const customers = [];

  for (let array = 0; array < event?.length; array++) {
    let demo = [...event[array].addons.split(',')];

    let addonPrice = 0;
    let addonPPP = 0;
    let addonNonPPP = 0;
    const regex = /\$([0-9.]+)/;
    demo.forEach((item) => {
      let exist = addonList.find((addon) => addon === item);

      if (
        exist &&
        (exist === 'Phone Protection Plan $9.00' || exist === 'Phone Protection Plan 9 $9.00')
      ) {
        addonPPP = 27;
      } else if (
        exist &&
        (exist !== 'Phone Protection Plan $9.00' || exist !== 'Phone Protection Plan 9 $9.00')
      ) {
        const match = exist.match(regex);
        if (match) {
          addonNonPPP += parseFloat(match[1]);
          addonPrice = Math.round(addonPrice);
        }
      }
    });
    addonPrice = addonPPP + addonNonPPP;

    if (event[array].connectionType === 'Prepaid' && event[array].retailPrice === 'N/A') {
      if (event[array].planName === 'By The Year 4G Nationwide Talk Text') {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: 30,
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid',
        };
        customers.push(newData);
      } else if (
        event[array].planName === 'By The Year 4G 12GB + Nationwide Talk and Text' ||
        event[array].planName === 'By The Year 12GB + Nationwide Talk Text' ||
        event[array].planName === 'By The Year 4G Nationwide 12GB'
      ) {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: 30,
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid',
        };
        customers.push(newData);
      } else if (
        event[array].planName === 'By The Year 4G Talk' ||
        event[array].planName === 'By The Year 4G Nationwide Talk'
      ) {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: 25,
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid',
        };
        customers.push(newData);
      } else if (event[array].planName === 'By The Year 4G Nationwide 15GB') {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: 30,
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid',
        };
        customers.push(newData);
      } else if (
        event[array].planName === 'By The Year 4G Nationwide 20GB' ||
        event[array].planName === 'By The Year 4G Nationwide 30GB'
      ) {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: 37,
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid',
        };
        customers.push(newData);
      } else {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: parseInt(event[array].planPrice.slice(1)) * 1.34,
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid',
        };
        customers.push(newData);
      }
    } else if (event[array].connectionType === 'Prepaid' && event[array].retailPrice !== 'N/A') {
      if (event[array].planName === 'By The Year 4G Nationwide Talk Text') {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: 10.75,
          retailHandset: parseFloat(event[array].retailPrice.replace(/[$,]/g, '')),
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid with device',
        };
        customers.push(newData);
      } else if (event[array].planName === 'By The Year 4G 12GB + Nationwide Talk and Text') {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: 14.92,
          retailHandset: parseFloat(event[array].retailPrice.replace(/[$,]/g, '')),
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid with device',
        };
        customers.push(newData);
      } else if (event[array].planName === 'By The Year 4G Talk') {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: 8.25,
          retailHandset: parseFloat(event[array].retailPrice.replace(/[$,]/g, '')),
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid with device',
        };
        customers.push(newData);
      } else if (
        event[array].planName === 'By The Year 4G Nationwide 20GB' ||
        event[array].planName === 'By The Year 4G Nationwide 30GB'
      ) {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: 8.25,
          retailHandset: parseFloat(event[array].retailPrice.replace(/[$,]/g, '')),
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid with device',
        };
        customers.push(newData);
      } else if (event[array].planName === 'By The Year 4G Nationwide 15GB') {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: 30,
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid',
        };
        customers.push(newData);
      } else {
        const newData = {
          name: event[array].customerName,
          phonenumber: extractPhoneNumber(event[array].phoneNum),
          planCommission: parseInt(event[array].planPrice.slice(1)) * 1.34,
          retailHandset: parseFloat(event[array].retailPrice.replace(/[$,]/g, '')),
          PPP: event[array].phoneProtectionPlan,
          tempNum: event[array].tempPhoneNum,
          deposit: event[array].deposit.replace(/[$,]/g, ''),
          amountYouPaid: event[array].amountYouPaid.trim(),
          promo: event[array].activatedPromotion,
          addonCommission: addonPrice || 0,
          cisstoreId: event[array].storeId,
          date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
          storeInvoice: event[array].storeInvoice,
          user: event[array].user,
          type: 'Prepaid with device',
        };
        customers.push(newData);
      }
    } else if (
      event[array].connectionType === 'Postpaid' &&
      event[array].retailPrice !== 'N/A' &&
      event[array].simNumber !== 'N/A'
    ) {
      const newData = {
        name: event[array].customerName,
        phonenumber: extractPhoneNumber(event[array].phoneNum),
        planCommission:
          (parseInt(event[array].planPrice.slice(1)) -
            promoFinder(event[array].activatedPromotion)) *
          3,
        spiff: 0,
        retailHandset: parseFloat(event[array].retailPrice.replace(/[$,]/g, '')),
        PPP: event[array].phoneProtectionPlan,
        tempNum: event[array].tempPhoneNum,
        deposit: event[array].deposit.replace(/[$,]/g, ''),
        amountYouPaid: event[array].amountYouPaid.trim(),
        promo: event[array].activatedPromotion,
        addonCommission: addonPrice || 0,
        cisstoreId: event[array].storeId,
        date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
        storeInvoice: event[array].storeInvoice,
        user: event[array].user,
        type: 'Postpaid with device',
      };
      customers.push(newData);
    } else if (
      event[array].connectionType === 'Postpaid' &&
      event[array].retailPrice === 'N/A' &&
      event[array].simNumber !== 'N/A'
    ) {
      const newData = {
        name: event[array].customerName,
        phonenumber: extractPhoneNumber(event[array].phoneNum),
        planCommission:
          (parseInt(event[array].planPrice.slice(1)) -
            promoFinder(event[array].activatedPromotion)) *
          3,
        spiff: 0,
        tempNum: event[array].tempPhoneNum,
        deposit: event[array].deposit.replace(/[$,]/g, ''),
        amountYouPaid: event[array].amountYouPaid.trim(),
        promo: event[array].activatedPromotion,
        addonCommission: addonPrice || 0,
        cisstoreId: event[array].storeId,
        date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
        storeInvoice: event[array].storeInvoice,
        user: event[array].user,
        type: 'Postpaid without device',
      };
      customers.push(newData);
    } else if (event[array].connectionType === 'Postpaid' && event[array].simNumber === 'N/A') {
      const newData = {
        name: event[array].customerName,
        phonenumber: extractPhoneNumber(event[array].phoneNum),
        planCommission: 75,
        retailHandset: parseFloat(event[array].retailPrice.replace(/[$,]/g, '')),
        PPP: event[array].phoneProtectionPlan,
        tempNum: event[array].tempPhoneNum,
        deposit: event[array].deposit,
        amountYouPaid: event[array].amountYouPaid.trim(),
        promo: event[array].activatedPromotion,
        addonCommission: addonPrice || 0,
        cisstoreId: event[array].storeId,
        date: /* convertDateFormat(event[array].tenderedDate) */ event[array].tenderedDate,
        storeInvoice: event[array].storeInvoice,
        user: event[array].user,
        type: 'Hup',
      };
      customers.push(newData);
    }
  }

  const customersList = [];
  for (let array = 0; array < customers.length; array++) {
    if (customers[array].PPP === 'Accepted' || customers[array].PPP !== 'Accepted') {
      const newData = {
        name: customers[array].name,
        phonenumber: customers[array].phonenumber?.replace(/\D/g, ''),
        planCommission: customers[array].planCommission,
        spiff: customers[array].spiff,
        retailHandset: customers[array].retailHandset,
        tempNum: customers[array].tempNum,
        deposit: customers[array].deposit,
        amountYouPaid: customers[array].amountYouPaid,
        addonCommission: customers[array].addonCommission,
        ppp: customers[array].PPP,
        promo: customers[array].promo,
        cisstoreId: customers[array].cisstoreId,
        date: customers[array].date,
        storeInvoice: customers[array].storeInvoice,
        user: customers[array].user,
        type: customers[array].type,
      };
      customersList.push(newData);
    }
  }
  const customerListFinal = [];
  for (let array = 0; array < customersList.length; array++) {
    if (customersList[array].tempNum !== 'N/A') {
      const newData = {
        name: customersList[array].name,
        phonenumber: customersList[array].phonenumber,
        planCommission: customersList[array].planCommission,
        spiff: customersList[array].spiff,
        retailHandset: customersList[array].retailHandset,
        tempNum: customersList[array].tempNum.replace(/\D/g, ''),
        deposit: customersList[array].deposit,
        amountYouPaid: customersList[array].amountYouPaid,
        addonCommission: customersList[array].addonCommission,
        promo: customersList[array].promo,
        ppp: customersList[array].ppp,
        cisstoreId: customersList[array].cisstoreId,
        date: customersList[array].date,
        storeInvoice: customersList[array].storeInvoice,
        user: customersList[array].user,
        type: customersList[array].type,
      };
      customerListFinal.push(newData);
    } else {
      const newData = {
        name: customersList[array].name,
        phonenumber: customersList[array].phonenumber,
        planCommission: customersList[array].planCommission,
        spiff: customersList[array].spiff,
        retailHandset: customersList[array].retailHandset,
        tempNum: customersList[array].tempNum,
        deposit: customersList[array].deposit,
        amountYouPaid: customersList[array].amountYouPaid,
        addonCommission: customersList[array].addonCommission,
        promo: customersList[array].promo,
        ppp: customersList[array].ppp,
        cisstoreId: customersList[array].cisstoreId,
        date: customersList[array].date,
        storeInvoice: customersList[array].storeInvoice,
        user: customersList[array].user,
        type: customersList[array].type,
      };
      customerListFinal.push(newData);
    }
  }
  const customerListFinal1 = [];
  for (let array = 0; array < customerListFinal.length; array++) {
    if (customerListFinal[array].amountYouPaid !== 'N/A') {
      const newData = {
        name: customerListFinal[array].name,
        phonenumber: customerListFinal[array].phonenumber,
        planCommission: customerListFinal[array].planCommission,
        spiff: customerListFinal[array].spiff || 0,
        retailHandset:
          customerListFinal[array].retailHandset -
            parseFloat(customerListFinal[array].amountYouPaid.replace(/[$,]/g, '')) || 0,
        tempNum: customerListFinal[array].tempNum,
        deposit: parseFloat(customerListFinal[array].deposit) || 0,
        amountYouPaid: customerListFinal[array].amountYouPaid,
        addonCommission: customerListFinal[array].addonCommission,
        promo: customerListFinal[array].promo,
        ppp: customerListFinal[array].ppp,
        cisstoreId: customerListFinal[array].cisstoreId.slice(2),
        date: customerListFinal[array].date,
        storeInvoice: customerListFinal[array].storeInvoice,
        accountNumber: event[array].accountNum,
        user: customerListFinal[array].user,
        type: customerListFinal[array].type,
      };
      customerListFinal1.push(newData);
    } else {
      const newData = {
        name: customerListFinal[array].name,
        phonenumber: customerListFinal[array].phonenumber,
        planCommission: customerListFinal[array].planCommission,
        spiff: customerListFinal[array].spiff || 0,
        retailHandset: customerListFinal[array].retailHandset || 0,
        tempNum: customerListFinal[array].tempNum,
        deposit: parseFloat(customerListFinal[array].deposit) || 0,
        amountYouPaid: customerListFinal[array].amountYouPaid,
        addonCommission: customerListFinal[array].addonCommission,
        promo: customerListFinal[array].promo,
        ppp: customerListFinal[array].ppp,
        cisstoreId: customerListFinal[array].cisstoreId.slice(2),
        date: customerListFinal[array].date,
        storeInvoice: customerListFinal[array].storeInvoice,
        accountNumber: event[array].accountNum,
        user: customerListFinal[array].user,
        type: customerListFinal[array].type,
      };
      customerListFinal1.push(newData);
    }
  }

  return customerListFinal1;
}

/* Calculate Store Commission after cisCommission Funtion  */

export function calculateStoreCommissionAfterCisCommission(computedCustomerList) {
  let prepaidCommission = 0;
  let postpaidCommission = 0;
  let hupCommission = 0;
  let addonCommission = 0;
  let hupMargin = 0;
  let deposit = 0;
  let tabHandset = 0;
  let spiffs = 0;

  for (let array = 0; array < computedCustomerList.length; array++) {
    if (computedCustomerList[array].type === 'Prepaid') {
      prepaidCommission += computedCustomerList[array].planCommission;
    } else if (
      computedCustomerList[array].type === 'Postpaid with device' ||
      computedCustomerList[array].type === 'Postpaid without device'
    ) {
      postpaidCommission += computedCustomerList[array].planCommission;
    } else if (computedCustomerList[array].type === 'Hup') {
      hupCommission += computedCustomerList[array].planCommission;
    }

    if (
      computedCustomerList[array].retailHandset * 0.05 < 20 &&
      computedCustomerList[array].retailHandset !== undefined
    ) {
      hupMargin += 20;
    } else if (
      computedCustomerList[array].retailHandset * 0.05 > 100 &&
      computedCustomerList[array].retailHandset !== undefined
    ) {
      hupMargin += 100;
    } else if (computedCustomerList[array].retailHandset !== undefined) {
      hupMargin += computedCustomerList[array].retailHandset * 0.05;
    }

    if (computedCustomerList[array].deposit !== 'Not Required') {
      deposit += parseFloat(computedCustomerList[array].deposit);
    }
    if (computedCustomerList[array].addonCommission !== undefined) {
      addonCommission += computedCustomerList[array].addonCommission;
    }
    if (computedCustomerList[array].spiff !== undefined) {
      spiffs += computedCustomerList[array].spiff;
    }
    if (computedCustomerList[array].retailHandset !== undefined) {
      tabHandset += computedCustomerList[array].retailHandset;
    }
  }

  return {
    prepaidCommission: prepaidCommission,
    postpaidCommission: postpaidCommission,
    hupCommission: hupCommission,
    addonCommission: addonCommission,
    hupMargin: hupMargin,
    deposit: deposit,
    tabHandset: tabHandset,
    spiffs: spiffs,
  };
}

import React from 'react';
import './AdminHome.scss';
import AdminDashboard from '../../components/AdminDashboard/AdminDashboard';

const AdminHome = () => {


  return (
    <>
      <AdminDashboard />
    </>
  );
};

export default AdminHome;

import React, { useState } from 'react';
import './Modal.scss';
const Modal = ({
  isOpen,
  onClose,
  type,
  data,
  filterStores,
  filterEmployees,
  target,
  onLoadedData,
  currentFilteritmes,
  manualData = [],
  userdata = [],
}) => {
  const [totalEmployee, setTotalEmployee] = useState([]);
  const [totalStores, setTotalStores] = useState([]);
  const [currentStoreSelected, setCurrentStoreSelected] = useState('');
  const [currentEmpSelected, setCurrentEmpSelected] = useState('');
  const [clickedButton, setClickedButton] = useState(null);
  const [clickedButtonEmp, setClickedButtonEmp] = useState(null);

  if (!isOpen) {
    return null;
  }

  const storeNameList = {
    OSHWTIN: 'Lake Vista Plaza',
    LAKESIN: 'Lakeshore & Islington',
    VYAD2IN: 'Center Mall Barton',
    TCPNFIN: 'Town Center Plaza',
    VYACBIN: 'Cedarbrae Mall',
    BARRIIN: 'Barrie',
    VYAREIN: 'Rexdale & Islington',
    DTWICIN: 'Briarfield Shopping Centre',
    DAN01IN: 'Danforth & Woodbine',
    VYOSHIN: 'Big Bay',
    VYAMLIN: 'Markham & Lawrence',
    VYAWLIN: 'Weston & Lawrence',
    DUNBLIN: 'Oriental Centre',
    AllStores: 'All Stores',
  };

  const handleStore = (store) => {
    setClickedButton(store);
    setCurrentStoreSelected(store);
    const employeesList = [];

    data?.forEach((emp) => {
      if (emp.storeInvoice === store) {
        employeesList.push(emp.user);
      }
    });
    let employee = [];

    employeesList.forEach((emp) => {
      let exist = employee.some((employee) => employee === emp);
      if (!exist) employee.push(emp);
    });
    const sortedEmployees = employee.sort((a, b) => a.localeCompare(b));
    setTotalEmployee(sortedEmployees);
  };

  const handleEmployee = (emp) => {
    setClickedButtonEmp(emp);
    setCurrentEmpSelected(emp);
  };

  const handleReps = (rep) => {
    setClickedButtonEmp(rep);
    setCurrentEmpSelected(rep);
    const storeList = [];
    data?.forEach((emp) => {
      if (emp.user === rep) {
        storeList.push(emp.storeInvoice);
      }
    });
    let stores = [];
    storeList.forEach((store) => {
      let exist = stores.some((s) => s === store);
      if (!exist) stores.push(store);
    });
    const sortedStores = stores.sort((a, b) => a.localeCompare(b));
    setTotalStores(sortedStores);
  };

  const handleRepsStore = (store) => {
    setClickedButton(store);
    setCurrentStoreSelected(store);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const cisEmployeesList = [];
    const targetList = [];
    const accandApple = [];
    if (currentEmpSelected !== '' && currentStoreSelected !== '') {
      data?.forEach((emp) => {
        if (emp.storeInvoice === currentStoreSelected && emp?.user === currentEmpSelected) {
          cisEmployeesList.push(emp);
        }
      });
      target?.forEach((target) => {
        if (
          target.storeinvoice === currentStoreSelected &&
          target?.username === currentEmpSelected
        ) {
          targetList.push(target);
        }
      });
      manualData?.forEach((acc) => {
        if (acc.storeid === currentStoreSelected && acc.employee === currentEmpSelected) {
          accandApple.push(acc);
        }
      });
    } else if (currentEmpSelected === '') {
      data?.forEach((emp) => {
        if (emp.storeInvoice === currentStoreSelected) {
          cisEmployeesList.push(emp);
        }
      });
      target?.forEach((target) => {
        if (target.storeinvoice === currentStoreSelected) {
          targetList.push(target);
        }
      });
      manualData?.forEach((acc) => {
        if (acc.storeid === currentStoreSelected) {
          accandApple.push(acc);
        }
      });
    } else if (currentStoreSelected === '' && currentEmpSelected !== '') {
      data?.forEach((emp) => {
        if (emp?.user === currentEmpSelected) {
          cisEmployeesList.push(emp);
        }
      });
      target?.forEach((target) => {
        if (target?.username === currentEmpSelected) {
          targetList.push(target);
        }
      });
      manualData?.forEach((acc) => {
        if (acc.employee === currentEmpSelected) {
          accandApple.push(acc);
        }
      });
    }
    onLoadedData([cisEmployeesList, targetList, accandApple]);
    setTotalEmployee([]);
    setTotalStores([]);
    setCurrentEmpSelected('');
    setCurrentStoreSelected('');
    setClickedButton('');
    setClickedButtonEmp('');
    currentFilteritmes([
      currentEmpSelected.slice(0, -11),
      storeNameList[currentStoreSelected.trim()],
    ]);
    onClose();
  };

  const capitalizeFirstLetter = (string) => {
    let user = [];
    for (let key in userdata) {
      if (userdata[key].username === string) {
        user.push(userdata[key].fullname);
      }
    }
    return user.length > 0
      ? user[0]
      : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().slice(0, -11);
  };

  return (
    <div className="main-modal-overlay" /* id='myModal' */ /* onClick={handleOutsideClick} */>
      <div className="main-modal">
        <div className="main-modal-header">
          <h2>Filter: {type}</h2>
          <div className="main-close-btn">
            <button onClick={onClose}>&times;</button>
          </div>
        </div>

        <div className="main-modal-content">
          {type === 'Stores' ? (
            <>
              <div className="store-data">
                {filterStores?.map((store) => (
                  <>
                    <div className="button">
                      <button
                        key={store}
                        onClick={() => handleStore(store)}
                        className={clickedButton === store ? 'clicked' : ''}
                      >
                        {storeNameList[store.trim()]}
                      </button>
                    </div>
                  </>
                ))}
              </div>
              <div className="rep-data">
                {totalEmployee?.map((emp) => (
                  <>
                    <div className="button">
                      <button
                        key={emp}
                        onClick={() => handleEmployee(emp)}
                        className={clickedButtonEmp === emp ? 'clicked' : ''}
                      >
                        {/* {emp.slice(0, -11).toUpperCase()} */}
                        {capitalizeFirstLetter(emp)}
                      </button>
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="rep-data">
                {filterEmployees?.map((emp) => (
                  <>
                    <div className="button" key={emp}>
                      <button
                        onClick={() => handleReps(emp)}
                        className={clickedButtonEmp === emp ? 'clicked' : ''}
                      >
                        {capitalizeFirstLetter(emp)}
                      </button>
                    </div>
                  </>
                ))}
              </div>
              <div className="store-data">
                {totalStores?.map((store) => (
                  <>
                    <div className="button">
                      <button
                        key={store}
                        onClick={() => handleRepsStore(store)}
                        className={clickedButton === store ? 'clicked' : ''}
                      >
                        {storeNameList[store.trim()]}
                      </button>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="submit-btn">
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

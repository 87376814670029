/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import './AttachRate.scss'
import { getDataForAttachRate } from '../../helper/helper'
import Tooltip from '@mui/material/Tooltip';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import Button from '@mui/material/Button';
import { Toaster } from 'react-hot-toast';
import Loader from '../../components/Loader/Loader';

const AttachRate = () => {
    const userDetail = JSON.parse(localStorage.getItem('user_data') || '{}')
    const userdata = userDetail
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [aRArray1, setARArray1] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [filterEmp, setFilterEmp] = useState([]);
    const [storesList, setStoresList] = useState([])
    const [isScrolling, setIsScrolling] = useState(false);
    const [results, setResults] = useState([]);

    const getMonthsmonths = () => {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    };

    const months = getMonthsmonths()


    const [selectedMonth, setSelectedMonth] = useState(() => {
        const currentDate = new Date();
        return `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}`; // Format YYYY-MM
    });
    const [employeName, setEmployeName] = useState("");
    const [storeName, setStoreName] = useState("");

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchUser() {
            setARArray1([]);
            // setStoresList([])
            // setFilterEmp([])
            setIsLoading(true);
            const username = localStorage.getItem("username");

            try {
                const { data } = await getDataForAttachRate(
                    { startDate, endDate, username, employeName, storeName },
                    signal // Pass the abort signal
                );
                let arr = Object.entries(data.aRArray1)
                const adminUser = "admin@vyadom.com"
                setResults(arr.filter(a => {
                    return a[0] !== adminUser
                }))

                setARArray1(data.aRArray1);
                setFilterEmp(data.filterEmp.filter(emp => emp !== adminUser))
                setStoresList(data.storesList)

            } catch (error) {
                if (error.name === "AbortError") {
                    console.log("Previous request aborted");
                } else {
                    console.error("API fetch error:", error);
                }
            } finally {
                setIsLoading(false);
            }
        }

        if (startDate !== "" && endDate !== "") {
            fetchUser();
        }

        // Cleanup function to abort previous request
        return () => controller.abort();
    }, [startDate, endDate, employeName, storeName]);



    useEffect(() => {
        const handleScroll = () => {
            setIsScrolling(true);

            // Set a timeout to reset scrolling state after the scroll ends
            setTimeout(() => {
                setIsScrolling(false);
            }, 3000); // Adjust the delay as necessary
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const storeNameList = {
        OSHWTIN: "Lake Vista Plaza",
        LAKESIN: "Lakeshore & Islington",
        VYAD2IN: "Center Mall Barton" || "Center Mall at Barton",
        TCPNFIN: "Town Center Plaza",
        VYACBIN: "Cedarbrae Mall",
        BARRIIN: "Barrie",
        VYAREIN: "Rexdale & Islington",
        DTWICIN: "Briarfield Shopping Centre",
        DAN01IN: "Danforth & Woodbine",
        VYOSHIN: "Big Bay",
        VYAMLIN: "Markham & Lawrence",
        VYAWLIN: "Weston & Lawrence",
        DUNBLIN: "Oriental Centre"
    };

    const capitalizeFirstLetter = (string) => {
        if (string.length === 7) {
            return storeNameList[string]
        } else {
            let user = []
            for (let key in userdata) {
                if (userdata[key].username === string) {
                    user.push(userdata[key].fullname)
                }
            }
            return user.length > 0 ? user[0] : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().slice(0, -11)
        }
    };




    const handleEmployeeNameChange = (e) => {
        e.preventDefault();
        setStoreName("")
        setEmployeName(e.target.value)
    }

    // jjjjjjjjjjjjjj
    const handleStoreNameChange = (e) => {
        e.preventDefault();
        setEmployeName("")
        setStoreName(e.target.value)
    }

    const roundValues = (dataArray, keys) => {
        return dataArray.map(item => {
            keys.forEach(key => {
                if (item.hasOwnProperty(key)) {
                    item[key] = item[key] ? parseFloat(item[key].toFixed(2)) : 0;
                }
            });
            return item;
        });
    };

    const headers = [
        { key: 'Act', label: 'Activation' },
        { key: 'AA', label: 'Accessories Attached' },
        { key: 'AR', label: 'Attach Rate' },
        { key: 'Disc', label: 'Discount' },
        ...(userDetail?.role === 'admin' ? [{ key: 'PM', label: 'Profit Margin' }] : []),
        { key: 'AccQty', label: 'Accessory Quantity' },
        { key: 'AAA', label: 'Accessory Quantity per Activation' },
        { key: 'PPP', label: 'Phone Protection Plan' },
        { key: 'PPPAtt', label: 'Phone Protection Plan Attach' },
        { key: 'WD', label: 'With Device' },
        { key: 'WDAtt', label: 'With Device Attach' },
        { key: 'HRS', label: 'Hours' },
        { key: 'AH', label: 'Activity Per Hour' },
        { key: 'Rev', label: 'Revenue' },
        { key: 'RH', label: 'Revenue Per Hour' }
    ];

    const headersbot = [
        { key: 'Hup', label: 'Hardware Upgrade' },
        { key: 'HA', label: 'Accessories Attached' },
        { key: 'HR', label: 'Attach Rate' },
        { key: 'Disc', label: 'Discount' },
        ...(userDetail?.role === 'admin' ? [{ key: 'PM', label: 'Profit Margin' }] : []),
        { key: 'AccQty', label: 'Accessory Quantity' },
        { key: 'AAA', label: 'Accessory Quantity per HUP' },
        { key: 'PPP', label: 'Phone Protection Plan' },
        { key: 'PPPAtt', label: 'Phone Protection Plan Attach' },
        { key: 'OA No', label: 'Only Accesorries' },
        ...(userDetail?.role === 'admin' ? [{ key: 'OA PM', label: 'Only Accesorries Profit Margin' },] : []),


    ];
    //////
    const downloadCSV = () => {
        const data = Object.keys(aRArray1).map(email => ({
            email,
            ...aRArray1[email],
        }));
        const uniqueEmails = [...new Set(data.map(item => item.email))];
        const outputs = uniqueEmails.map((email, index) => {
            const filteredData = data.filter(item => item.email === email);
            let store = (data) => {
                return [{ email: storeNameList[data] }]
            }
            let emailOutput = [{ email }];
            let data1 = emailOutput[0].email.length === 7 ? store(emailOutput[0].email) : emailOutput
            const actKeys = [{
                act: 'Act',
                actCount: 'Accessory Attached',
                actARate: 'Attach Rate(%)',
                actCost: 'Cost($)',
                actMSRP: 'MSRP($)',
                actDiscount: 'Discount($)',
                actSf: 'Sold for($)',
                actPm: 'Profit Margin($)',
                accActNumber: 'Accessories Numbers',
                accActNumRate: 'Accessories Numbers Rate(%)'
            }];
            const actOutput = filteredData.map(({ act, actCount, actARate, actSum, actSf, actPm, accActNumber, accActNumRate }) => ({
                act,
                actCount,
                actARate,
                ...actSum,
                actSf,
                actPm,
                accActNumber,
                accActNumRate
            }));
            const keysToRound = [
                'accActNumRate', 'cost', 'discount', 'actARate', 'actPm', 'actSf', 'msrp'
            ];
            const roundedDataAct = roundValues(actOutput, keysToRound);
            const hupKeys = [{ hup: 'Hup', hupCount: 'Accessory Attached', hupAr: 'Attach Rate(%)', hupCost: 'Cost($)', hupMSRP: 'MSRP($)', hupDiscount: 'Discount($)', hupSf: 'Sold for($)', hupPm: 'Profit Margin($)', accHupNumber: 'Accessories Numbers', accHupNumRate: 'Accessories Numbers Rate(%)' }];
            const hupOutput = filteredData.map(({ hup, hupCount, hupARate, hupSum, hupSf, hupPm, accHupNumber, accHupNumRate }) => ({
                hup,
                hupCount,
                hupARate,
                ...hupSum,
                hupSf,
                hupPm,
                accHupNumber,
                accHupNumRate
            }));
            const keysToRoundHup = [
                'accHupNumRate', 'cost', 'discount', 'hupARate', 'hupPm', 'hupSf', 'msrp'
            ];

            const roundedDataHup = roundValues(hupOutput, keysToRoundHup);
            const actPPPKeys = [{ act: 'Act', actPPP: 'Act with PPP', actPPPRate: 'PPP Rate(%)' }];
            const actPPPOutput = filteredData.map(({ act, actPPP, actPPPRate }) => ({ act, actPPP, actPPPRate }));
            const keysToRoundActPPP = ['actPPPRate'];
            const roundedDataActPPP = roundValues(actPPPOutput, keysToRoundActPPP);
            const hupPPPKeys = [{ hup: 'Hup', hupPPP: 'Hup with PPP', hupPPPRate: 'PPP Rate(%)' }];
            const hupPPPOutput = filteredData.map(({ hup, hupPPP, hupPPPRate }) => ({ hup, hupPPP, hupPPPRate }));
            const keysToRoundHupPPP = ['hupPPPRate'];
            const roundedDataHupPPP = roundValues(hupPPPOutput, keysToRoundHupPPP);
            const actWDKeys = [{ act: 'Act', actWD: 'Act with Device', actWDRate: 'Device Rate(%)' }]
            const actWDOutput = filteredData.map(({ act, actWD, actWDRate }) => ({ act, actWD, actWDRate }));
            const keysToRoundActWD = ['actWDRate'];
            const roundedDataActWD = roundValues(actWDOutput, keysToRoundActWD);

            return { data1, actKeys, roundedDataAct, hupKeys, roundedDataHup, actPPPKeys, roundedDataActPPP, hupPPPKeys, roundedDataHupPPP, actWDKeys, roundedDataActWD };
        });
        const combinedData = []
        Object.values(outputs).forEach(output => {
            Object.values(output).forEach(obj => {
                combinedData.push(Object.values(...obj));
            })
        })
        const csvContent = "data:text/csv;charset=utf-8," +
            combinedData.map(row => Object.values(row).join(",")).join("\n");

        const [startMonth, startYear] = startDate.split('-');
        const storeNames = [...new Set(data.map(item => item.storeName))]; // Assume store names are part of the structure
        const storeName = storeNames.length === 1 ? storeNames[0] : "Total"; // Use store name if only one store
        // Check if there's only one email
        let fileName = '';
        if (uniqueEmails.length === 1) {
            const firstEmail = uniqueEmails[0];
            const emailFirstName = firstEmail.split('@')[0]; // Get the first part of the email
            fileName = `${emailFirstName}_${startMonth}_${startYear}.csv`;
        } else if (storeName) {
            fileName = `${storeNameList[storeName]}_${startMonth}_${startYear}.csv`; // Use store name if only one store
        } else {
            fileName = `${startMonth}_${startYear}.csv`; // Default to date if multiple emails or stores
        }
        // Create a link element and trigger the download
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    }

    useEffect(() => {
        updateDates(selectedMonth)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateDates = (_selectedMonth) => {

        const [year, month] = _selectedMonth.split("-").map(Number);

        const selectedDate = new Date(year, month - 1);
        setStartDate(format(startOfMonth(selectedDate), 'yyyy-MM-dd'));
        setEndDate(format(endOfMonth(selectedDate), 'yyyy-MM-dd'));


    }

    const handleMonthChange = (event) => {
        if (event.target.value === "")
            return;
        setSelectedMonth(event.target.value);
        updateDates(event.target.value)
    };


    return (
        <div className="ar-main">
            <Toaster position='top-center' reverseOrder={false} toastOptions={{ duration: 1000 }} gutter={4}></Toaster>

            <div className="ar-container">

                {/* <div className="ar-block"> */}
                <div className="ar-block-container">
                    <div className='filter-menu-list'>
                        <div className='filter-menu date-selection'>
                            <label htmlFor="monthSelect">Select Month</label>
                            <input disabled={isLoading} value={selectedMonth} type="month" id="monthSelect" onChange={handleMonthChange} name="monthYear" min="2024-01" />
                        </div>

                        {(filterEmp?.length > 0 && storesList?.length > 0) && <>
                            {userDetail?.role !== "employee" &&
                                (
                                    <div className='filter-menu'>
                                        <div className='filter-datemenu'>
                                            <FormControl fullWidth>
                                                <InputLabel>Select Employee</InputLabel>
                                                <Select
                                                    id="employeeFilter"
                                                    value={employeName}
                                                    onChange={handleEmployeeNameChange}
                                                    className='filter-datemenu-select'
                                                    label="Select Employee"
                                                >
                                                    <MenuItem value="">
                                                        <em>All Employee</em>
                                                    </MenuItem>
                                                    {filterEmp && filterEmp?.map((name, index) => (
                                                        <MenuItem key={index} value={name}>
                                                            {capitalizeFirstLetter(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </div>
                                    </div>
                                )}
                            <div className='filter-menu'>
                                <div className='filter-datemenu'>
                                    <FormControl fullWidth>
                                        <InputLabel id="store-label">Select Store</InputLabel>
                                        <Select
                                            labelId='store-label'
                                            id="storeFilter"
                                            value={storeName}
                                            onChange={handleStoreNameChange}
                                            className='filter-datemenu-select'
                                            label="Select Store:"
                                        >
                                            <MenuItem value="">
                                                <em>All Stores</em>
                                            </MenuItem>
                                            {storesList && storesList?.map((name, index) => (
                                                <MenuItem key={index} value={name}>
                                                    {storeNameList[name]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                            </div>
                            <div className='topbuttons'>
                                <Button disabled={isLoading} variant="contained" color="success" onClick={downloadCSV}>
                                    Download
                                    {/* <IconButton sx={{ ml: 0 }} onClick={downloadCSV} > */}
                                    <DownloadIcon />
                                    {/*  </IconButton> */}
                                </Button>
                            </div>
                        </>}
                    </div>
                    {isLoading ? (
                        <Loader />
                    ) : (

                        results?.length === 0 ?
                            <p className="message" >The requested data for {months[selectedMonth?.split('-')[1] - 1]} {selectedMonth?.split('-')[0]} is currently unavailable.</p>
                            :

                            <div className="table">
                                {results.map(([name, detail], index) => (
                                    <div className="table-container" key={index}>
                                        <div className="emp">
                                            <span>{capitalizeFirstLetter(name)}</span>
                                        </div>
                                        <div className="emp-table">
                                            <div className="table-act">
                                                <p>Activations</p>
                                                <table className="professional-table">
                                                    <thead>
                                                        <tr>
                                                            {headers.map((header, index) => (
                                                                <Tooltip
                                                                    key={index}
                                                                    title={isScrolling ? '' : `${header.label}`}
                                                                    arrow
                                                                    placement="top"
                                                                >
                                                                    <th>{header.key}</th>
                                                                </Tooltip>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{detail?.act}</td>
                                                            <td>{detail?.actCount}</td>
                                                            <td>{detail?.act === 0 ? 0 : detail?.actARate?.toFixed(2)}%</td>
                                                            <td>${detail?.actSum?.discount?.toFixed(2)}</td>
                                                            {userDetail?.role === 'admin' ? (
                                                                <td>${detail?.actPm?.toFixed(2)}</td>
                                                            ) : (
                                                                ''
                                                            )}
                                                            <td>{detail?.accActNumber}</td>
                                                            <td>
                                                                {detail?.act === 0 ? 0 : detail?.accActNumRate?.toFixed(2) || 0}
                                                            </td>
                                                            <td>{detail?.actPPP}</td>
                                                            <td>{detail?.act === 0 ? 0 : detail?.actPPPRate?.toFixed(2) || 0}%</td>
                                                            <td>{detail?.actWD}</td>
                                                            <td>{detail?.act === 0 ? 0 : detail?.actWDRate?.toFixed(2)}%</td>
                                                            <td>{detail?.hours?.toFixed(2)}</td>
                                                            <td>{detail?.actperH?.toFixed(2)}</td>
                                                            <td>${detail?.OAplusComm?.toFixed(2)}</td>
                                                            <td style={{ backgroundColor: 'yellow' }}>
                                                                $
                                                                {detail?.OAPlusrevperH && isFinite(detail?.OAPlusrevperH)
                                                                    ? detail?.OAPlusrevperH?.toFixed(2)
                                                                    : 0}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="table-hup">
                                                <p>Hardware Update</p>
                                                <table className="professional-table">
                                                    <thead>
                                                        <tr>
                                                            {headersbot.map((header, index) => (
                                                                <Tooltip
                                                                    key={index}
                                                                    title={isScrolling ? '' : `${header.label}`}
                                                                    arrow
                                                                    placement="top"
                                                                >
                                                                    <th>{header.key}</th>
                                                                </Tooltip>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{detail?.hup}</td>
                                                            <td>{detail?.hupCount}</td>
                                                            <td>{detail?.hup === 0 ? 0 : detail?.hupARate?.toFixed(2)}%</td>
                                                            <td>${detail?.hupSum?.discount?.toFixed(2)}</td>
                                                            {userDetail?.role === 'admin' ? (
                                                                <td>${detail?.hupPm?.toFixed(2)}</td>
                                                            ) : (
                                                                ''
                                                            )}
                                                            <td>{detail?.accHupNumber}</td>
                                                            <td>{detail?.hup === 0 ? 0 : detail?.accHupNumRate?.toFixed(2)}</td>
                                                            <td>{detail?.hupPPP}</td>
                                                            <td>
                                                                {detail?.hup === 0
                                                                    ? 0
                                                                    : detail?.hup === 0
                                                                        ? 0
                                                                        : detail?.hupPPPRate?.toFixed(2)}
                                                                %
                                                            </td>
                                                            <td>{detail?.OAtotal ? detail?.OAtotal : 0}</td>
                                                            {userDetail?.role === 'admin' ? (
                                                                <td>${detail?.OAPM ? detail?.OAPM?.toFixed(2) : 0}</td>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                    )}
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default AttachRate
import React, { useState, useEffect } from 'react';
import './CommissionStructure.scss';
import emCom from '../../assets/employeecommission.png';
import TLCom from '../../assets/TLcommission.png';
import Gallery from 'react-image-gallery';
import { getUser } from '../../helper/helper';
import 'react-image-gallery/styles/css/image-gallery.css';

const CommissionStructure = () => {
  const [apiData, setApiData] = useState('');
  const username = localStorage.getItem('username');

  const image1 = {
    original: emCom,
    thumbnail: emCom,
  };
  const image2 = {
    original: TLCom,
    thumbnail: TLCom,
  };

  useEffect(() => {
    let data = getUser({ username: username });
    data.then((res) => {
      let apiData = res.data;
      setApiData(apiData);
    });
  }, [username]);

  const selectedImage = apiData.role === 'employee' ? image1 : image2;
  return (
    <div className="commStructure">
     

      <div className="commContainer">
      

        <div className="commDash">
          <div className="commission-structure-container">
            <h2 className="commission-structure-title">Commission Structure</h2>

            <Gallery items={[selectedImage]} showNav={true} showPlayButton={false} loading />
            <a
              href={selectedImage.original}
              download="commission_structure_image.jpg"
              className="download-link"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionStructure;

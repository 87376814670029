import React, { useState, useEffect } from 'react';
/* import FileUpload from './FileUpload'; */
import './UploadModal.scss';
import toast, { Toaster } from 'react-hot-toast';
import { submitHours } from '../../helper/helper';
import * as XLSX from 'xlsx';
import { MenuItem, Select } from '@mui/material';

const UploadModal = ({ modalIsOpen, closeModal }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [apidata, setApidata] = useState({});
  const generateMonths = () => {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  };

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 4; i >= 0; i--) {
      // Generate the last 5 years, including the current year
      years.push(currentYear - i);
    }
    return years;
  };

  const years = generateYears();
  const months = generateMonths();
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Update startDate and endDate when selectedMonth or selectedYear changes
    const selectedMonthIndex = months.findIndex((month) => month === selectedMonth);
    const selectedDate = new Date(selectedYear, selectedMonthIndex);
    const lastDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);

    setStartDate(selectedDate.toISOString().split('T')[0]);
    setEndDate(lastDayOfMonth.toISOString().split('T')[0]);
    /* setStartDate(format(startOfMonth(selectedDate), 'yyyy-MM-dd'));
        if (selectedMonth === format(new Date(), 'MMMM') && selectedYear === new Date().getFullYear()) {
            // If current month and year, set endDate to today
            setEndDate(format(new Date(), 'yyyy-MM-dd'));
        } else {
            // Otherwise, set endDate to the last day of the selected month
            setEndDate(format(endOfMonth(selectedDate), 'yyyy-MM-dd'));
        } */
  }, [selectedMonth, selectedYear, months]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  /* const generateMonths = () => {
        const months = [];
        const currentMonth = new Date();
        for (let i = 0; i < 12; i++) { // Generate the last 6 months
            const monthDate = subMonths(currentMonth, i);
            months.push(format(monthDate, 'MMMM yyyy')); // e.g., November 2024
        }
        return months;
    };

    const months = generateMonths(); // Pre-generate months (November, October, etc.)

    useEffect(() => {
        // Update startDate and endDate when selectedMonth changes
        const monthIndex = months.findIndex((month) => month === selectedMonth);
        const selectedMonthDate = subMonths(new Date(), monthIndex);
        setStartDate(format(startOfMonth(selectedMonthDate), 'yyyy-MM-dd'));
        if (selectedMonth === months[0]) {
            // If current month, set endDate to today
            setEndDate(format(new Date(), 'yyyy-MM-dd'));
        } else {
            // Otherwise, set endDate to the last day of the month
            setEndDate(format(endOfMonth(selectedMonthDate), 'yyyy-MM-dd'));
        }
    }, [selectedMonth, months, startDate, endDate]); */

  /*  const handleMonthChange = (event) => {
         setSelectedMonth(event.target.value);
     } */

  if (!modalIsOpen) {
    return null;
  }

  const readHoursFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      // Extracting store names
      const stores = excelData.slice(1);
      fileUploadSucces(stores);
    };
    reader.readAsArrayBuffer(file);
  };

  const fileUploadSucces = async (data) => {
    let mergedData = {};
    let mergedData1 = {};
    data.forEach((item) => {
      let key = item[0];
      let key1 = item[1];
      let value = item[2];

      if (!mergedData[key]) {
        mergedData[key] = 0;
      }
      mergedData[key] += value;

      if (!mergedData1[key1]) {
        mergedData1[key1] = 0;
      }
      mergedData1[key1] += value;
    });
    let resultArray = Object.entries(mergedData).map(([key, sum]) => {
      return [key, sum];
    });
    let resultArray1 = Object.entries(mergedData1).map(([key, sum]) => {
      return [key, sum];
    });
    setApidata({
      resultArray,
      resultArray1,
      startDate,
      endDate,
    });
  };

  const SubmitHoursData = () => {
    if (apidata.resultArray.length > 0) {
      let submitPromise = submitHours({
        storeHours: apidata?.resultArray,
        empHours: apidata?.resultArray1,
        startDate: apidata?.startDate,
        endDate: apidata?.endDate,
      });
      toast
        .promise(submitPromise, {
          loading: 'Uploading...',
          success: <b>Uploaded Successfully...!</b>,
          error: <b>Not uploaded</b>,
        })
        .then(() => {
          closeModal();
        });
    } else {
      toast.error('Please Upload a file before clicking Upload Button.');
    }
  };

  return (
    <div className="eh-modal">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{ duration: 1000 }}
        gutter={4}
      ></Toaster>
      <div className="modal-content">
        {/* <span className="close" onClick={closeModal}>
                    &times;
                </span> */}
        <div className="top">
          <div className="filter-menu-list-empUpload">
            <div className="filter-menu-empUpload">
              <div className="filter-datemenu-empUpload">
                <label htmlFor="month-select-id" id='month-label'>Select Month:</label>
                <Select
                  value={selectedMonth}
                  labelId='month-label'
                  onChange={handleMonthChange}
                  displayEmpty
                  className="filter-datemenu-select-empUpload"
                  inputProps={{
                    id: 'month-select-id',
                  }}
                >
                  {months.map((month, index) => (
                    <MenuItem key={index} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
                <label htmlFor="year-select-id" id="year-label" style={{ marginLeft: '10px' }}>
                  Select Year:
                </label>
                <Select
                  labelId="year-label"
                  value={selectedYear}
                  onChange={handleYearChange}
                  displayEmpty
                  className="filter-datemenu-select-empUpload"
                  inputProps={{
                    id: 'year-select-id', // Ensure this id is applied to the input element
                  }}
                >
                  {years.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          {/* for Home internet upload */}
          <div className="block">
            <div className="top-block">
              <h4>Hours</h4>
              <span>Select one file at a time.</span>
            </div>
            <div className="bottom-block">
              <input onChange={readHoursFile} type="file" />
            </div>
          </div>
        </div>
        <div className="bottom">
          <button onClick={SubmitHoursData} className="sub-btn" type="submit">
            Upload
          </button>
          <button className="sub-btn" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;

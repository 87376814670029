import React, { useState } from 'react';
import './EditUserModal.scss';
import toast, { Toaster } from 'react-hot-toast';
import { editUserListData } from '../../helper/helper';
/* import { useFormik } from 'formik'; */
import Select from 'react-select';
/* import { getValues } from '../../components/ReusableFunctions' */

const EditUserModal = ({ modalIsOpen, closeModal, editFormData, editEmployeeId, onUpdate }) => {
  /* const [userdata, setUserdata] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionEmp, setSelectedOptionEmp] = useState(null);
    const [selectedOptionStatus, setSelectedOptionStatus] = useState(null); */

  const storeNameList = {
    OSHWTIN: 'Lake Vista Plaza',
    LAKESIN: 'Lakeshore & Islington',
    VYAD2IN: 'Center Mall Barton',
    TCPNFIN: 'Town Center Plaza',
    VYACBIN: 'Cedarbrae Mall',
    BARRIIN: 'Barrie',
    VYAREIN: 'Rexdale & Islington',
    DTWICIN: 'Briarfield Shopping Centre',
    DAN01IN: 'Danforth & Woodbine',
    VYOSHIN: 'Big Bay',
    VYAMLIN: 'Markham & Lawrence',
    VYAWLIN: 'Weston & Lawrence',
    DUNBLIN: 'Oriental Centre',
  };
  const valuesStore = editFormData.stores.split(',').map((store) => storeNameList[store]);
  const [formData, setFormData] = useState({
    fullname: editFormData.fullname,
    username: editFormData.username,
    email: editFormData.email,
    phonenumber: editFormData.phonenumber,
    employeeid: editFormData.employeeid,
    dob: editFormData.dob,
    role: editFormData.role,
    stores: valuesStore,
    employmentStatus: editFormData.employmentStatus,
  });

  /* useEffect(() => {
        getRawUserData();

    }, [])

    async function getRawUserData() {
        try {
            let { data } = await getAllUsersData();
            setUserdata(data);

        } catch (error) {
            return error
        }
    } */

  const storesList = [
    'Lake Vista Plaza',
    'Lakeshore & Islington',
    'Center Mall Barton',
    'Town Center Plaza',
    'Cedarbrae Mall',
    'Barrie',
    'Rexdale & Islington',
    'Briarfield Shopping Centre',
    'Danforth & Woodbine',
    'Big Bay',
    'Markham & Lawrence',
    'Weston & Lawrence',
    'Oriental Centre',
  ];

  const statusList = ['Active', 'Inactive'];

  const roleOptions = ['employee', 'manager', 'admin'];

  /* const formik = useFormik({
        initialValues: {
            _id: editEmployeeId,
            fullname: editFormData.fullname,
            username: editFormData.username,
           
            email: editFormData.email,
            role: editFormData.role,
            phonenumber: editFormData.phonenumber,
            employeeid: editFormData.employeeid,
            stores: editFormData.stores,
            dob: editFormData.dob,
            status: editFormData.status,
        },
        onSubmit: async values => {
            const demo = selectedOption.map((value, index) => {
                return Object.values(value)[0]
            })
            const selectedKeys = Object.keys(storeNameList).filter(
                (key) => demo.includes(storeNameList[key])
            );
            values.stores = selectedKeys.join(',')

            values.role = Object.values(...selectedOptionEmp)[0]
            values.status = Object.values(...selectedOptionStatus)[0]
            console.log(values)

            const newUsers = [...userdata]
            const index = userdata.findIndex((user) => user._id === editEmployeeId);
            const editRow = newUsers[index] = values;
            console.log(editRow);
            const editRowPromise = editUserListData({
                _id: editRow._id,
                fullname: editRow.fullname,
                username: editRow.username,
                password: editRow.password,
                email: editRow.email,
                role: editRow.role,
                phonenumber: editRow.phonenumber,
                employeeid: editRow.employeeid,
                stores: editRow.stores,
                dob: editRow.dob,
                status: editRow.status
            })
            toast.promise(editRowPromise, {
                loading: 'Editing...',
                success: <b>Editied Successfully...!</b>,
                error: <b>Could not Edit.</b>
            })
            editRowPromise.then(() => {
                formik.resetForm();
            })
        },
        handleReset: () => {
            formik.resetForm();
            setSelectedOptionEmp(null)
            setSelectedOption(null)
        }
    }) */
  if (!modalIsOpen) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData({
      ...formData,
      [name]:
        selectedOption.length === undefined
          ? selectedOption.value
          : selectedOption.map((value) => value.value),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit form logic here
    formData.stores = formData.stores.map((storeName) => {
      // Find the key by matching the value in storeNameList
      return (
        Object.keys(storeNameList).find((key) => storeNameList[key] === storeName) || storeName
      );
    });

    formData.stores = formData.stores.join(',');
    const editRowPromise = editUserListData({
      _id: editEmployeeId,
      fullname: formData.fullname,
      username: formData.username,
      email: formData.email,
      role: formData.role,
      phonenumber: formData.phonenumber,
      employeeid: formData.employeeid,
      stores: formData.stores,
      dob: formData.dob,
      employmentStatus: formData.employmentStatus,
    });
    toast
      .promise(editRowPromise, {
        loading: 'Editing...',
        success: <b>Editied Successfully...!</b>,
        error: <b>Could not Edit.</b>,
      })
      .then(() => {
        closeModal();
        onUpdate();
      });
  };

  /* const handleEditFormSubmit = (event) => {
        event.preventDefault();
        const editUsers = {
            _id: editEmployeeId,
            fullname: editFormData.fullname,
            username: editFormData.username,
            password: editFormData.password,
            email: editFormData.email,
            role: editFormData.role,
            phonenumber: editFormData.phonenumber,
            employeeid: editFormData.employeeid,
            stores: editFormData.stores,
            dob: editFormData.dob,
            status: editFormData.status,
        }
        const newUsers = [...userdata]
        const index = userdata.findIndex((user) => user._id === editEmployeeId);
        const editRow = newUsers[index] = editUsers;
        console.log(editRow);
        const editRowPromise = editUserListData({
            _id: editRow._id,
            fullname: editRow.fullname,
            username: editRow.username,
            password: editRow.password,
            email: editRow.email,
            role: editRow.role,
            phonenumber: editRow.phonenumber,
            employeeid: editRow.employeeid,
            stores: editRow.stores,
            dob: editRow.dob,
            status: editRow.status
        })
        toast.promise(editRowPromise, {
            loading: 'Editing...',
            success: <b>Editied Successfully...!</b>,
            error: <b>Could not Edit.</b>
        }).then(() => {
             getRawUserData();

        })
        setUserdata(newUsers);
         setEditEmployeeId(null);
    } */

  return (
    <div className="edit-User-modal">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{ duration: 1000 }}
        gutter={4}
      ></Toaster>
      <div className="edit-Usermodal-content">
        <div className="edit-User-top">
          <div className="edit-User-block">
            {/*  <form onSubmit={formik.handleSubmit}>  

                            <div className='forminput'>
                                <label>Full Name:</label>
                                <input {...formik.getFieldProps('fullname')} type='text' placeholder='Enter Full Name' />
                            </div>

                            <div className='forminput'>
                                <label>Username:</label>
                                <input {...formik.getFieldProps('username')} type='text' placeholder='Enter Username' />
                            </div>

                            

                            <div className='forminput'>
                                <label>Email:</label>
                                <input {...formik.getFieldProps('email')} type='text' placeholder='Enter Email' />
                            </div>
                            <div className='forminput'>
                                <label>Phone Number:</label>
                                <input {...formik.getFieldProps('phonenumber')} type='text' placeholder='Enter Phone Number' />
                            </div>

                            <div className='forminput'>
                                <label>Employee ID:</label>
                                <input {...formik.getFieldProps('employeeid')} type='text' placeholder='Enter Employee ID' />
                            </div>
                            <div className='forminput'>
                                <label>Date of Birth:</label>
                                <input {...formik.getFieldProps('dob')} type='text' placeholder='Enter Date of Birth' />
                            </div>
                            <div className='forminput'>
                                <label>Role:</label>
                                <Select
                                    className='select'
                                    isSearchable
                                    defaultValue={{
                                        value: formik.values.role,
                                        label: formik.values.role,
                                    }}
                                   
                                    onChange={setSelectedOptionEmp}
                                    options={roleOptions.map((role) => {
                                        return { value: role, label: role }
                                    })}
                                />
                            </div>
                            <div className='forminput'>
                                <label>Stores:</label>
                                <Select
                                    className='select'
                                    isMulti
                                    isSearchable
                                    defaultValue={{
                                        value: formik.values.stores,
                                        label: formik.values.stores,
                                    }}
                                    
                                    onChange={setSelectedOption}
                                    options={storesList.map((store) => {
                                        return { value: store, label: store }
                                    })}
                                />
                            </div>
                            <div className='forminput'>
                                <label>Status:</label>
                                <Select
                                    className='select'
                                    isSearchable
                                    defaultValue={{
                                        value: formik.values.status,
                                        label: formik.values.status,
                                    }}
                             
                                    onChange={setSelectedOptionStatus}
                                    options={statusList.map((store) => {
                                        return { value: store, label: store }
                                    })}
                                />
                            </div>

                            <div className='button'>
                                <button onSubmit={formik.handleSubmit} type='submit'>Submit</button>
                                <button onClick={formik.handleReset}>Clear</button>
                                <button type="button" onClick={closeModal}>Cancel</button>
                            </div>
                        </form> */}
            <form onSubmit={handleSubmit}>
              <div className="forminput">
                <label>Full Name:</label>
                <input
                  name="fullname"
                  type="text"
                  placeholder="Enter Full Name"
                  value={formData.fullname}
                  onChange={handleChange}
                />
              </div>

              <div className="forminput">
                <label>Username:</label>
                <input
                  name="username"
                  type="text"
                  placeholder="Enter Username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>

              <div className="forminput">
                <label>Email:</label>
                <input
                  name="email"
                  type="text"
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div className="forminput">
                <label>Phone Number:</label>
                <input
                  name="phonenumber"
                  type="text"
                  placeholder="Enter Phone Number"
                  value={formData.phonenumber}
                  onChange={handleChange}
                />
              </div>

              <div className="forminput">
                <label>Employee ID:</label>
                <input
                  name="employeeid"
                  type="text"
                  placeholder="Enter Employee ID"
                  value={formData.employeeid}
                  onChange={handleChange}
                />
              </div>

              <div className="forminput">
                <label>Date of Birth:</label>
                <input
                  name="dob"
                  type="text"
                  placeholder="Enter Date of Birth"
                  value={formData.dob}
                  onChange={handleChange}
                />
              </div>

              <div className="forminput">
                <label>Role:</label>
                <Select
                  className="select"
                  isSearchable
                  defaultValue={{
                    value: formData.role,
                    label: formData.role,
                  }}
                  onChange={(selectedOption) => handleSelectChange(selectedOption, 'role')}
                  options={roleOptions.map((role) => ({
                    value: role,
                    label: role,
                  }))}
                />
              </div>

              <div className="forminput">
                <label>Stores:</label>
                <Select
                  className="select"
                  isMulti
                  isSearchable
                  defaultValue={formData.stores?.map((store) => ({
                    value: store,
                    label: store,
                  }))}
                  onChange={(selectedOption) => handleSelectChange(selectedOption, 'stores')}
                  options={storesList.map((store) => ({
                    value: store,
                    label: store,
                  }))}
                />
              </div>

              <div className="forminput">
                <label>Status:</label>
                <Select
                  className="select"
                  isSearchable
                  defaultValue={{
                    value: formData.employmentStatus,
                    label: formData.employmentStatus,
                  }}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, 'employmentStatus')
                  }
                  options={statusList.map((status) => ({
                    value: status,
                    label: status,
                  }))}
                />
              </div>

              <div className="button">
                <button type="submit">Submit</button>
                <button type="button" onClick={() => setFormData({})}>
                  Clear
                </button>
                <button type="button" onClick={closeModal}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;

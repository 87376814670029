import React, { useState, useEffect } from 'react';
import './HomeInternet.scss';
import { getUser, getHomeInternetData, getAllUsersData, deleteHomeInternetRow } from '../../helper/helper';
import UploadHIModal from './UploadHIModal';
import { Toaster } from 'react-hot-toast';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
//import { CSVLink } from 'react-csv';
import CustomButton from '../../components/ui/Button/Button';

const HomeInternet = () => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
  const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];
  const username = localStorage.getItem('username');
  const [startDate, setStartDate] = useState(firstDayOfMonthString);
  const [endDate, setEndDate] = useState(lastDayOfMonthString);
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [userList, setuserList] = useState([]);
  const [finalHIData, setFinalHIData] = useState([]);
  const [arrIds, setArrIds] = useState([]);

  const apiData = [];

  useEffect(() => {
    async function fetchUser() {
      let { data } = await getUser({ username: username });
      setUserDetail(data);
    }
    fetchUser();
  }, [username]);

  useEffect(() => {
    async function fetchUser() {
      let { data } = await getAllUsersData();
      setuserList(data);
    }
    fetchUser();
  }, [username]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSearch = async () => {
    setIsLoading(true);
    const { data } = await getHomeInternetData({ startDate, endDate });
    if (userDetail?.role === 'manager') {
      let finalArray = [];
      userDetail?.stores?.split(',').forEach((store) => {
        userList.forEach((user) => {
          let exist = user.stores.split(',').some((item) => item === store);
          if (exist) {
            if (!finalArray.includes(user)) {
              finalArray.push(user);
            }
          }
        });
      });
      let storesList = userDetail?.stores?.split(',');

      let filterHI = [];
      storesList.forEach((store) => {
        data.forEach((obj) => {
          const { storeInvoice } = obj;
          if (storeInvoice === store) {
            filterHI.push(obj);
          }
        });
      });
      setFinalHIData(filterHI);
    } else if (userDetail?.role === 'admin') {
      let filterStores = [];
      let storesList = data?.map((store) => store.storeInvoice);
      storesList?.forEach((val) => {
        let isExist = filterStores.some((store) => store === val);
        if (!isExist) filterStores.push(val);
      });
      let filterEmployee = [];
      let employeeList = data?.map((emp) => emp.user);
      employeeList?.forEach((val) => {
        let isExist = filterEmployee.some((employee) => employee === val);
        if (!isExist) filterEmployee.push(val);
      });
      setFinalHIData(data);
    } else if (userDetail?.role === 'employee') {
      let filterHI = [];
      data.forEach((obj) => {
        if (obj.user === userDetail.username) {
          filterHI.push(obj);
        }
      });
      setFinalHIData(filterHI);
    }
  };

  async function deleteHIData(id) {
    if (window.confirm(`Are you sure you want to delete ${id}'s data?`)) {
      id.forEach((id) => {
        let data = deleteHomeInternetRow({ id });
        if (data) {
          alert('data deleted successfully');
        }
      });
    }
  }

  if (isLoading && apiData?.length > 0) {
    setIsLoading(false);
  }

  const rows = finalHIData?.map((row) => ({
    _id: row._id,
    customerName: row.customerName,
    phoneNumber: row.phoneNumber,
    clientNumber: row.clientNumber,
    internetPlanName: row.internetPlanName,
    monthlyCost: row.monthlyCost,
    regularMonthlyPayment: row.regularMonthlyPayment,
    discount: row.discount,
    saleInvoice: row.saleInvoice,
    tenderedDate: row.tenderedDate,
    time: row.time,
    user: row.user,
  }));

  const columns = [
    /* { field: '_id', headerName: 'Id', width: 50 }, */
    { field: 'customerName', headerName: 'Customer Name', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'clientNumber', headerName: 'Client Number', width: 150 },
    { field: 'internetPlanName', headerName: 'Plan Name', width: 150 },
    { field: 'regularMonthlyPayment', headerName: 'Original Cost', width: 150 },
    { field: 'discount', headerName: 'Discount', width: 150 },
    { field: 'monthlyCost', headerName: 'Monthly Cost', width: 150 },
    { field: 'saleInvoice', headerName: 'Sales Invoice', width: 130 },
    { field: 'tenderedDate', headerName: 'Tendered Date', width: 130 },
    { field: 'time', headerName: 'Time', width: 100 },
    { field: 'user', headerName: 'User', width: 300 },
  ];
  return (
    <div className="hi-main">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{ duration: 1000 }}
        gutter={4}
      ></Toaster>


      <div className="hi-container">

        <div className="hi-block-container">
          {/*  <div className='filter-menu-list'> */}
          <div className="filter-menu">
            <div className="filter-datemenu">
              <label htmlFor="startDate">From:</label>
              <input
                type="date"
                id="startDate"
                className="filter-datemenu-input"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label htmlFor="endDate">To:</label>
              <input
                type="date"
                id="endDate"
                className="filter-datemenu-input"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <CustomButton buttonType="search" onClick={handleSearch} />
            {/* <CSVLink data={finalHIData ? finalHIData : ''} className="download-btn">
              <CustomButton buttonType="download" />
            </CSVLink> */}
            <CustomButton buttonType="upload" onClick={openModal} className="upload-btn" />
            {/* <div className="topbuttons">
              <button onClick={() => deleteCisData(arrIds)} className='edit-btn' type='submit'>Delete</button>

            </div> */}

            <UploadHIModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
          </div>

          <div className="bot">
            <div className="tablecontainer">
              <div className="bottomtable" style={{ height: 420, width: '100%' }}>
                <DataGrid
                slotProps={{
                  baseCheckbox: {
                    name: 'hi-table-checkbox',
                  },
                  pagination:{
                    slotProps:{
                      select:{
                        name: 'hi-table-select',
                      },
                    }
                  }
                }}
                  slots={{
                    toolbar: () => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <GridToolbar />
                        <CustomButton
                          buttonType="delete"
                          onClick={() => deleteHIData(arrIds)}
                          disabled={arrIds.length === 0} // Disable if no rows are selected
                        />
                      </div>
                    ),
                  }}
                  getRowId={(row) => row._id}
                  rows={rows ? rows : []}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(ids) => {
                    setArrIds(ids);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeInternet;

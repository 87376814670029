import React, { useEffect, useState } from 'react';
import './BRModal.scss';
import { submitPandLData } from '../../helper/helper';
import toast, { Toaster } from 'react-hot-toast';
import * as XLSX from 'xlsx';

const BRModal = ({ isOpen, onClose, apiDataECR, manualData, dateforModalInput }) => {
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [storeExpenses, setStoreExpenses] = useState({});
  const [revenues, setRevenues] = useState({
    'Act Com': '',
    'HW Margin': '',
    'Acc Sales': '',
    'Top up Margin': '',
    'Bill Pay Margin': '',
    'Total Sales': '',
  });
  const [expenses, setExpenses] = useState({
    Payroll: '',
    'Acc Sales Cost': '',
    'Mngt Pay': '',
    'Rental Cost': '',
    Telephone: '',
    Internet: '',
    Gas: '',
    Hydro: '',
    Alarm: '',
    Ensurity: '',
    Shiftlab: '',
    'RQ Fee': '',
    Statflo: '',
    Moneris: '',
    'Bank Fee': '',
    Marketing: '',
    'Petty Cash': '',
    'Office Supplies': '',
    'Store Insurance': '',
    'QB Fee': '',
    'Ceridian Fee': '',
    'Cyber Security': '',
    Misc: '',
    'Total Expense': '',
  });
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  // Calculate the previous month
  /* const today = new Date(dateforModalInput);
    today.setMonth(today.getMonth() - 1);
    const prevMonth = today.toLocaleString('default', { month: 'long' });
    const prevYear = today.getFullYear(); */

  // Set the initial values for month and year
  useEffect(() => {
    if (dateforModalInput) {
      const data = dateforModalInput?.split(' ');

      setMonth(data[0].toString());
      setYear(data[1].toString());
    }
  }, [dateforModalInput]);

  const storeNameList2 = {
    OSHWTIN: 'Lake Vista',
    LAKESIN: 'Lakeshore Islington',
    VYAD2IN: 'Centre Mall Barton',
    TCPNFIN: 'Town Centre Plaza',
    VYACBIN: 'Cedarbrae Mall',
    BARRIIN: 'Barrie Essa',
    VYAREIN: 'Rexdale Islington',
    DTWICIN: 'Briarfield Plaza',
    DAN01IN: 'Danforth Woodbine',
    VYOSHIN: 'Big Bay',
    VYAMLIN: 'Markham Lawrence',
    VYAWLIN: 'Weston Lawrence',
    DUNBLIN: 'Oriental Centre',
  };
  function cleanString(str) {
    if (str && typeof str === 'string') {
      return str.replace(/^\s*[\d-]*\s*/, '').replace(/-/g, ''); // Remove digits, spaces, and hyphens
    }
    return str; // Return the original string if it's not valid
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      // Extracting store names
      const stores = excelData[0].slice(1);

      const mapValuesToKeys = (array, storeList) => {
        return array.map((value) => {
          for (const key in storeList) {
            if (storeList[key] === value) {
              return key;
            }
          }
          return value; // Return unchanged if no match found
        });
      };

      // Call the mapping function
      const updatedArray = mapValuesToKeys(stores, storeNameList2);

      // Creating an object to store expenses for each store
      const storeExpensesObj = {};

      // Iterating through each row starting from the second row
      for (let i = 1; i < excelData.length; i++) {
        const expenseType = excelData[i][0]; // First column represents expense type
        const expenses = excelData[i].slice(1); // Extracting expenses for each store

        // Populating expenses for each store
        updatedArray.forEach((store, index) => {
          if (!storeExpensesObj[store]) {
            storeExpensesObj[store] = {};
          }
          storeExpensesObj[store][cleanString(expenseType)] = expenses[index];
        });
      }
      setStoreExpenses(storeExpensesObj);
    };

    reader.readAsArrayBuffer(file);
  };

  const storeNameList = {
    OSHWTIN: 'Lake Vista Plaza',
    LAKESIN: 'Lakeshore & Islington',
    VYAD2IN: 'Center Mall Barton',
    TCPNFIN: 'Town Center Plaza',
    VYACBIN: 'Cedarbrae Mall',
    BARRIIN: 'Barrie',
    VYAREIN: 'Rexdale & Islington',
    DTWICIN: 'Briarfield Shopping Centre',
    DAN01IN: 'Danforth & Woodbine',
    VYOSHIN: 'Big Bay',
    VYAMLIN: 'Markham & Lawrence',
    VYAWLIN: 'Weston & Lawrence',
    DUNBLIN: 'Oriental Centre',
  };

  if (!isOpen) {
    return null;
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRevenueChange = (e) => {
    const { name, value } = e.target;
    setRevenues((prevRevenue) => ({ ...prevRevenue, [name]: value }));
  };

  const handleExpenseChange = (e) => {
    const { name, value } = e.target;
    setExpenses((prevExpenses) => ({ ...prevExpenses, [name]: value }));
  };

  const calculateTotalRevenue = () => {
    return Object.values(revenues)
      .reduce((acc, curr) => acc + parseFloat(curr || 0), 0)
      .toFixed(2);
  };

  const calculateTotalExpenses = () => {
    return Object.values(expenses)
      .reduce((acc, curr) => acc + parseFloat(curr || 0), 0)
      .toFixed(2);
  };

  const totalRevenue = calculateTotalRevenue();
  const totalExpenses = calculateTotalExpenses();
  const netProfitLoss = (parseFloat(totalRevenue) - parseFloat(totalExpenses)).toFixed(2);

  const handleStoreChange = (e) => {
    const value = e.target.value;
    e.preventDefault();
    setSelectedStore(e.target.value);
    if (value !== '') {
      const storeData = apiDataECR?.storesCommission[value];

      const { addonCommission, hupCommission, postpaidCommission, prepaidCommission, hupMargin } =
        storeData;
      const actComm =
        parseFloat(postpaidCommission) +
        parseFloat(prepaidCommission) +
        parseFloat(hupCommission) +
        parseFloat(addonCommission);
      const hupMargin1 = parseFloat(hupMargin).toFixed(2);

      const empData = [];
      apiDataECR?.employeesCommission.forEach((emp) => {
        if (emp.storeInvoice === value) {
          empData.push(emp);
        }
      });

      let totalSumEmployeeCommission = 0;

      // Iterate over each employee object
      for (let key in empData) {
        if (empData.hasOwnProperty(key)) {
          // Access the post, pre, addon, and hup properties for each employee
          let post = empData[key].post;
          let pre = empData[key].pre;
          let addon = empData[key].addon;
          let hup = empData[key].hup;

          // Calculate the sum for each employee
          let sum = post + pre + addon + hup;

          // Add three to the total sum
          totalSumEmployeeCommission += sum;
        }
      }

      let isExist = [];
      manualData.forEach((store) => {
        if (store.storeid === value) {
          isExist.push(store);
        }
      });

      /* let accessoriesTotal = 0;
            for (let key in isExist) {
                if (isExist.hasOwnProperty(key)) {
                    let accessories = isExist[key].accessories;

                    accessoriesTotal += parseFloat(accessories)
                }
            } */

      let autoPopulateData = {
        actComm,
        hupMargin1,
        totalSumEmployeeCommission /* accessoriesTotal */,
      };
      let sum = {};
      for (let key in storeExpenses) {
        if (key === value) {
          sum = storeExpenses[key];
        }
      }

      setRevenues((prevRevenue) => ({
        ...prevRevenue,
        'Act Com': autoPopulateData.actComm,
        'HW Margin': autoPopulateData.hupMargin1,
        'Acc Sales': sum['Acc Sales'],
        'Top up Margin': sum['Top up Margin'],
        'Bill Pay Margin': sum['Bill Pay Margin'],
      }));

      setExpenses((prevExpenses) => ({
        ...prevExpenses,
        Payroll: sum.Payroll,
        'Acc Sales Cost': sum['Acc Sales Cost'],
        'Mngt Pay': sum['Mngt Pay'],
        'Rental Cost': sum['Rental Cost'],
        Telephone: sum.Telephone,
        Internet: sum.Internet,
        Gas: sum.Gas,
        Hydro: sum.Hydro,
        Alarm: sum.Alarm,
        Ensurity: sum.Ensurity,
        Shiftlab: sum.Shiftlab,
        'RQ Fee': sum['RQ Fee'],
        Statflo: sum.Statflo,
        Moneris: sum.Moneris,
        'Bank Fee': sum['Bank Fee'],
        Marketing: sum.Marketing,
        'Petty Cash': sum['Petty Cash'],
        'Office Supplies': sum['Office Supplies'],
        'Store Insurance': sum['Store Insurance'],
        'QB Fee': sum['QB Fee'],
        'Ceridian Fee': sum['Ceridian Fee'],
        'Cyber Security': sum['Cyber Security'],
        Misc: sum['Misc'],
        'Total Expense': sum['Total Expense'],
      }));
    } else {
      const storeData = apiDataECR?.storesCommission;
      let totalStoresCom = 0;
      let totalHupMargin = 0;

      for (let key in storeData) {
        if (storeData.hasOwnProperty(key)) {
          let postpaidCommission = storeData[key].postpaidCommission;
          let prepaidCommission = storeData[key].prepaidCommission;
          let hupCommission = storeData[key].hupCommission;
          let addonCommission = storeData[key].addonCommission;
          let hupMargin = storeData[key].hupMargin;

          let sum = postpaidCommission + prepaidCommission + hupCommission + addonCommission;

          totalStoresCom += sum;
          totalHupMargin += hupMargin;
        }
      }

      const empData = apiDataECR?.employeesCommission;
      let totalSumEmployeeCommission = 0;
      for (let key in empData) {
        if (empData.hasOwnProperty(key)) {
          let post = empData[key].post;
          let pre = empData[key].pre;
          let addon = empData[key].addon;
          let hup = empData[key].hup;

          // Calculate the sum for each employee
          let sum = post + pre + addon + hup;

          // Add three to the total sum
          totalSumEmployeeCommission += sum;
        }
      }

      /* let accessoriesTotal = 0;
            for (let key in manualData) {
                if (manualData.hasOwnProperty(key)) {
                    let accessories = manualData[key].accessories;

                    accessoriesTotal += parseFloat(accessories)

                }
            } */

      let autoPopulateData = {
        totalStoresCom,
        totalHupMargin,
        totalSumEmployeeCommission /*  accessoriesTotal */,
      };

      const sum = {};
      for (let key1 in storeExpenses) {
        for (const key in storeExpenses[key1]) {
          if (storeExpenses[key1].hasOwnProperty(key)) {
            if (typeof sum[key] === 'undefined') {
              sum[key] = storeExpenses[key1][key];
            } else {
              sum[key] += storeExpenses[key1][key];
            }
          }
        }
      }

      setRevenues((prevRevenue) => ({
        ...prevRevenue,
        'Act Com': autoPopulateData.totalStoresCom,
        'HW Margin': autoPopulateData.totalHupMargin,
        'Acc Sales': sum['Acc Sales'],
        'Top up Margin': sum['Top up Margin'],
        'Bill Pay Margin': sum['Bill Pay Margin'],
      }));
      setExpenses((prevExpenses) => ({
        ...prevExpenses,
        Payroll: sum.Payroll,
        'Acc Sales Cost': sum['Acc Sales Cost'],
        'Mngt Pay': sum['Mngt Pay'],
        'Rental Cost': sum['Rental Cost'],
        Telephone: sum.Telephone,
        Internet: sum.Internet,
        Gas: sum.Gas,
        Hydro: sum.Hydro,
        Alarm: sum.Alarm,
        Ensurity: sum.Ensurity,
        Shiftlab: sum.Shiftlab,
        'RQ Fee': sum['RQ Fee'],
        Statflo: sum.Statflo,
        Moneris: sum.Moneris,
        'Bank Fee': sum['Bank Fee'],
        Marketing: sum.Marketing,
        'Petty Cash': sum['Petty Cash'],
        'Office Supplies': sum['Office Supplies'],
        'Store Insurance': sum['Store Insurance'],
        'QB Fee': sum['QB Fee'],
        'Ceridian Fee': sum['Ceridian Fee'],
        'Cyber Security': sum['Cyber Security'],
        Misc: sum['Misc'],
        'Total Expense': sum['Total Expense'],
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const allStoreData = {};
    const storeData = apiDataECR?.storesCommission;
    for (let key in storeData) {
      const { addonCommission, hupCommission, postpaidCommission, prepaidCommission, hupMargin } =
        storeData[key];
      const actComm =
        parseFloat(postpaidCommission) +
        parseFloat(prepaidCommission) +
        parseFloat(hupCommission) +
        parseFloat(addonCommission);
      const hupMargin1 = parseFloat(hupMargin.toFixed(2));
      let isExist = [];
      manualData.forEach((store) => {
        if (store.storeid === key) {
          isExist.push(store);
        }
      });

      /* let accessoriesTotal = 0;
            for (let key in isExist) {
                if (isExist.hasOwnProperty(key)) {
                    let accessories = isExist[key].accessories;

                    accessoriesTotal += parseFloat(accessories)
                }
            } */

      let revenue = {
        'Act Com': actComm,
        'HW Margin': hupMargin1,
        'Acc Sales': storeExpenses[key]['Acc Sales'],
        'Top up Margin': storeExpenses[key]['Top up Margin'],
        'Bill Pay Margin': storeExpenses[key]['Bill Pay Margin'],
        /* 'Total Sales': totalRev */
      };
      const totalRevenueNew = () => {
        return Object.values(revenue)
          .reduce((acc, curr) => acc + parseFloat(curr || 0), 0)
          .toFixed(2);
      };
      const totalRev = parseFloat(totalRevenueNew());
      revenue = {
        'Act Com': actComm,
        'HW Margin': hupMargin1,
        'Acc Sales': storeExpenses[key]['Acc Sales'],
        'Top up Margin': storeExpenses[key]['Top up Margin'],
        'Bill Pay Margin': storeExpenses[key]['Bill Pay Margin'],
        'Total Sales': totalRev,
      };

      let expense = {
        Payroll: storeExpenses[key].Payroll,
        'Acc Sales Cost': storeExpenses[key]['Acc Sales Cost'],
        'Mngt Pay': storeExpenses[key]['Mngt Pay'],
        'Rental Cost': storeExpenses[key]['Rental Cost'],
        Telephone: storeExpenses[key].Telephone,
        Internet: storeExpenses[key].Internet,
        Gas: storeExpenses[key].Gas,
        Hydro: storeExpenses[key].Hydro,
        Alarm: storeExpenses[key].Alarm,
        Ensurity: storeExpenses[key].Ensurity,
        Shiftlab: storeExpenses[key].Shiftlab,
        'RQ Fee': storeExpenses[key]['RQ Fee'],
        Statflo: storeExpenses[key].Statflo,
        Moneris: storeExpenses[key].Moneris,
        'Bank Fee': storeExpenses[key]['Bank Fee'],
        Marketing: storeExpenses[key].Marketing,
        'Petty Cash': storeExpenses[key]['Petty Cash'],
        'Office Supplies': storeExpenses[key]['Office Supplies'],
        'Store Insurance': storeExpenses[key]['Store Insurance'],
        'QB Fee': storeExpenses[key]['QB Fee'],
        'Ceridian Fee': storeExpenses[key]['Ceridian Fee'],
        'Cyber Security': storeExpenses[key]['Cyber Security'],
        Misc: storeExpenses[key]['Misc'],
        /* 'Total Expense': totalExpenses */
      };

      const totalExpenseNew = () => {
        return Object.values(expense)
          .reduce((acc, curr) => acc + parseFloat(curr || 0), 0)
          .toFixed(2);
      };
      const totalExp = parseFloat(totalExpenseNew());

      expense = {
        Payroll: storeExpenses[key].Payroll,
        'Acc Sales Cost': storeExpenses[key]['Acc Sales Cost'],
        'Mngt Pay': storeExpenses[key]['Mngt Pay'],
        'Rental Cost': storeExpenses[key]['Rental Cost'],
        Telephone: storeExpenses[key].Telephone,
        Internet: storeExpenses[key].Internet,
        Gas: storeExpenses[key].Gas,
        Hydro: storeExpenses[key].Hydro,
        Alarm: storeExpenses[key].Alarm,
        Ensurity: storeExpenses[key].Ensurity,
        Shiftlab: storeExpenses[key].Shiftlab,
        'RQ Fee': storeExpenses[key]['RQ Fee'],
        Statflo: storeExpenses[key].Statflo,
        Moneris: storeExpenses[key].Moneris,
        'Bank Fee': storeExpenses[key]['Bank Fee'],
        Marketing: storeExpenses[key].Marketing,
        'Petty Cash': storeExpenses[key]['Petty Cash'],
        'Office Supplies': storeExpenses[key]['Office Supplies'],
        'Store Insurance': storeExpenses[key]['Store Insurance'],
        'QB Fee': storeExpenses[key]['QB Fee'],
        'Ceridian Fee': storeExpenses[key]['Ceridian Fee'],
        'Cyber Security': storeExpenses[key]['Cyber Security'],
        Misc: storeExpenses[key]['Misc'],
        'Total Expense': totalExp,
      };
      allStoreData[storeNameList[key]] = { revenue, expense };
    }

    /* const revenue = { ...revenues, 'Total Sales': totalRevenue };
        const expense = { ...expenses, 'Total Expense': totalExpenses } */
    const monthYear = month + ' ' + year;
    const newStoreData = {
      plData: allStoreData,
      monthYear,
    };

    let FilterPromise = submitPandLData({ newStoreData });
    toast.promise(FilterPromise, {
      loading: 'Uploading...',
      success: <b>Uploaded Successfully...!</b>,
      error: <b>Not uploaded</b>,
    });

    setSelectedStore('');
    setRevenues({
      'Act Com': '',
      'HW Margin': '',
      'Acc Sales': '',
      'Top up Margin': '',
      'Bill Pay Margin': '',
      'Total Sales': '',
    });
    setExpenses({
      Payroll: '',
      'Acc Sales Cost': '',
      'Mngt Pay': '',
      'Rental Cost': '',
      Telephone: '',
      Internet: '',
      Gas: '',
      Hydro: '',
      Alarm: '',
      Ensurity: '',
      Shiftlab: '',
      'RQ Fee': '',
      Statflo: '',
      Moneris: '',
      'Bank Fee': '',
      Marketing: '',
      'Petty Cash': '',
      'Office Supplies': '',
      'Store Insurance': '',
      'QB Fee': '',
      'Ceridian Fee': '',
      'Cyber Security': '',
      Misc: '',
      'Total Expense': '',
    });
  };
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <Toaster position="top-center" reverseOrder={false}></Toaster>
        <div className="bottom">
          <div className="add-revenue-and-expenses">
            <form onSubmit={handleSubmit}>
              <div className="store-month-year">
                <label>
                  Select Store:
                  <select value={selectedStore} onChange={handleStoreChange}>
                    <option value="">All Stores</option>
                    {Object.keys(storeNameList).map((key) => (
                      <option key={key} value={key}>
                        {storeNameList[key]}
                      </option>
                    ))}
                  </select>
                </label>
                <label>
                  Month:
                  <input type="text" value={month} onChange={(e) => setMonth(e.target.value)} />
                </label>
                <label>
                  Year:
                  <input type="text" value={year} onChange={(e) => setYear(e.target.value)} />
                </label>
              </div>
              <div className="dataEnryOptions">
                <div className="radioOptions">
                  <label>
                    Manual Entry:
                    <input
                      type="radio"
                      id="manual"
                      name="dataentry"
                      value="option1"
                      checked={selectedOption === 'option1'}
                      onChange={handleOptionChange}
                    />
                  </label>
                  <label>
                    Upload:
                    <input
                      type="radio"
                      id="upload"
                      name="dataentry"
                      value="option2"
                      checked={selectedOption === 'option2'}
                      onChange={handleOptionChange}
                    />
                  </label>
                </div>
                {selectedOption === 'option2' && (
                  <div className="uploadFile">
                    <input type="file" onChange={handleFileUpload} />
                  </div>
                )}
              </div>

              {(selectedOption === 'option1' || Object.keys(storeExpenses).length > 0) && (
                <>
                  <div className="revenue-and-expenses">
                    <div className="revenue">
                      <h3>Revenue</h3>
                      <div className="block">
                        {Object.entries(revenues).map(([key, value]) => {
                          if (key !== 'Total Sales') {
                            return (
                              <div key={key} className="input-row">
                                <label>{key === 'TotalSales' ? 'Total Sales' : key}:</label>
                                <div className="input-with-dollar">
                                  <span>$</span>
                                  <input
                                    type="text"
                                    value={
                                      value
                                        ? parseFloat(value).toLocaleString('en-US', {
                                            maximumFractionDigits: 2,
                                          })
                                        : ''
                                    }
                                    onChange={handleRevenueChange}
                                    name={key}
                                  />
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                    <div className="expenses">
                      <h3>Expenses</h3>
                      <div className="block">
                        {Object.entries(expenses).map(([key, value]) => {
                          if (key !== 'Total Expense') {
                            return (
                              <div key={key} className="input-row">
                                <label>{key === 'TotalExpense' ? 'Total Expense' : key}:</label>
                                <div className="input-with-dollar">
                                  <span>$</span>
                                  <input
                                    type="text"
                                    value={
                                      value
                                        ? parseFloat(value).toLocaleString('en-US', {
                                            maximumFractionDigits: 2,
                                          })
                                        : ''
                                    }
                                    onChange={handleExpenseChange}
                                    name={key}
                                  />
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                    <div className="total-section">
                      <div className="total-row">
                        <label>Total Sales:</label>
                        <div className="total-value">
                          $
                          {totalRevenue
                            ? parseFloat(totalRevenue).toLocaleString('en-US', {
                                maximumFractionDigits: 2,
                              })
                            : ''}
                        </div>
                      </div>
                      <div className="total-row">
                        <label>Total Expense:</label>
                        <div className="total-value">
                          $
                          {totalExpenses
                            ? parseFloat(totalExpenses).toLocaleString('en-US', {
                                maximumFractionDigits: 2,
                              })
                            : ''}
                        </div>
                      </div>
                      <div
                        className={`net-profit-loss1 ${
                          netProfitLoss < 0 ? 'negative' : 'positive'
                        }`}
                      >
                        <label>Net Profit/(Loss):</label>
                        <div className="total-value">
                          $
                          {netProfitLoss
                            ? parseFloat(netProfitLoss).toLocaleString('en-US', {
                                maximumFractionDigits: 2,
                              })
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button">
                    <button type="submit">Submit</button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BRModal;

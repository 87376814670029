import React from 'react'
import './Loader.scss'

function Loader() {
  return (
    <div className="load-4">
    <div className="ring-1"></div>
  </div>

  )
}

export default Loader
